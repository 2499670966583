// Example: finance-utils.ts
export type UserBillableModel = {
    id: string;
    name: string;
    role: string;
    email: string;
    phone: string;
    hours: number;
    rate: number;
    total: number;
};

export async function getBillableUsers(user: any, month: number, year: number): Promise<{ users: UserBillableModel[], stats: { users: number, hours: number, revenue: number } }> {
    // Mocked example logic
    return {
        users: [
            { id: '1', name: 'John Doe', role: 'Developer', email: 'john@example.com', phone: '123456789', hours: 160, rate: 50, total: 8000 },
            { id: '2', name: 'John Doe', role: 'Developer', email: 'john@example.com', phone: '123456789', hours: 160, rate: 50, total: 8000 },
            { id: '2', name: 'John Doe', role: 'Developer', email: 'john@example.com', phone: '123456789', hours: 160, rate: 50, total: 8000 }

        ],
        stats: { users: 1, hours: 160, revenue: 8000 },
    };
}