import { DocumentReference } from 'firebase/firestore';
import _getTrajecten, { createTrajectDocument, getTrajectDocumentById, updateTrajectDocument } from '../datasource/traject-utils';
import { _getUserById, getUserById, UserModel } from './user-utils';
import { getCountAanmeldingenByTraject } from '../datasource/aanmelding-utils';
import { getCountTalentenByTraject } from '../datasource/talent-utils';

export type TrajectModel = {
    duur?: number,
    aantal_rapportages?: number,
    evaluatie_momenten?: number,
    jobcoach_interventies?: number,
    praktijkverklaring?: boolean,
    talentportfolio?: boolean,
    loonwaardemeting?: boolean,
    gemeente?: string,
    naam?: string,
    omschrijving?: string,
    reference?: DocumentReference,
    traject_consulent_reference?: DocumentReference,
    consulent?: {
      naam: string
    },
    aanmeldingenCount?: number,
    talentenCount?: number,
};

export type NewTrajectModel = {
    duur?: number,
    traject_consulent_id?: string,
    aantal_rapportages?: number,
    evaluatie_momenten?: number,
    jobcoach_interventies?: number,
    praktijkverklaring?: boolean,
    talentportfolio?: boolean,
    loonwaardemeting?: boolean,
    gemeente?: string,
    naam?: string,
    omschrijving?: string,
    consulent_reference?: DocumentReference,
    data_eigenaar_ref?: DocumentReference
}

export const getAllTrajecten = async (user: UserModel) => {
  const list: TrajectModel[] = []

  const trajecten = await _getTrajecten(user?.data_eigenaar_ref!)

  for (var traject of trajecten) {
    list.push({
      duur: traject.duur,
      traject_consulent_reference: traject.traject_consulent_reference,
      aantal_rapportages: traject.aantal_rapportages,
      evaluatie_momenten: traject.evaluatie_momenten,
      jobcoach_interventies: traject.jobcoach_interventies,
      praktijkverklaring: traject.praktijkverklaring,
      talentportfolio: traject.talentportfolio,
      loonwaardemeting: traject.loonwaardemeting,
      gemeente: traject.gemeente,
      naam: traject.naam,
      omschrijving: traject.omschrijving,
      reference: traject.reference,

  })
  }

  return list

}

export const getTrajectById = async (id?: string, options?: {consulent?: boolean, includeCount?: boolean}) => {

  if (!id) return Promise.reject("Fout")

  const traject = await getTrajectDocumentById(id)

  if (!traject) return Promise.reject("Geen traject gevonden.")

  let trajectModel: TrajectModel = {
      duur: traject.duur,
      aantal_rapportages: traject.aantal_rapportages,
      evaluatie_momenten: traject.evaluatie_momenten,
      jobcoach_interventies: traject.jobcoach_interventies,
      praktijkverklaring: traject.praktijkverklaring,
      talentportfolio: traject.talentportfolio,
      loonwaardemeting: traject.loonwaardemeting,
      gemeente: traject.gemeente,
      naam: traject.naam,
      omschrijving: traject.omschrijving,
      reference: traject.reference,
  }

  if (options?.consulent) {
    let consulent = await getUserById(traject.traject_consulent_reference)
    trajectModel.consulent = {
        naam: `${consulent?.voornaam} ${consulent?.achternaam}`
    } 
} else {
  trajectModel.traject_consulent_reference = traject.traject_consulent_reference
}

  return trajectModel

}

export const getTrajecten = async (user: UserModel, options?: {includeCount?: boolean}) => {
    const list: TrajectModel[] = []
   
  var trajecten = await _getTrajecten(user?.data_eigenaar_ref!)

  const consulenten: Map<string, UserModel> = new Map()

  for (let traject of trajecten) {
    let consulent
    if (traject.traject_consulent_reference)
    {
      if (consulenten.has(traject.traject_consulent_reference.id)) {
        consulent = consulenten.get(traject.traject_consulent_reference.id)
      } else {
        consulent = await getUserById(traject.traject_consulent_reference)
        consulenten.set(traject.traject_consulent_reference.id, consulent)
      }
    }

    let tempTraject = {
      duur: traject.duur,
      aantal_rapportages: traject.aantal_rapportages,
      evaluatie_momenten: traject.evaluatie_momenten,
      jobcoach_interventies: traject.jobcoach_interventies,
      praktijkverklaring: traject.praktijkverklaring,
      talentportfolio: traject.talentportfolio,
      loonwaardemeting: traject.loonwaardemeting,
      gemeente: traject.gemeente,
      naam: traject.naam,
      omschrijving: traject.omschrijving,
      reference: traject.reference,
      consulent: {
        naam: `${consulent.voornaam} ${consulent.achternaam}`
      }
    } as TrajectModel

    if (options?.includeCount) {
      let aanmeldingCount = await getCountAanmeldingenByTraject(traject.reference, user?.data_eigenaar_ref!)
      let talentCount = await getCountTalentenByTraject(traject.reference, user?.data_eigenaar_ref!)
      tempTraject.aanmeldingenCount = aanmeldingCount
      tempTraject.talentenCount = talentCount
    }

    list.push(tempTraject)
  }

  return list;
}

export const createTraject = (data: NewTrajectModel) => {
  return createTrajectDocument(data)
}

export const updateTraject = async (id: string, consulent_id: string, data: NewTrajectModel) => {

  if (!consulent_id) return null

  const user = await _getUserById(consulent_id)

  if (!user) return null

  data.consulent_reference = user.ref

  return updateTrajectDocument(id, data)
}