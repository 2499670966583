import { useContext, useMemo } from "react"
import { StepperContext } from "../../../../context/StepperContext"
import { useForm, SubmitHandler, Controller } from "react-hook-form"

export default function TalentProfiel({ handleClick }) {

  const { data, setData } = useContext(StepperContext)

  type Inputs = {
    situatieSchets: string,
    werkervaring: string,
    soortUitkering: string,
    vervoer: string,
    taalniveau: string,
    gezondheid: string,
    opleiding: string,
    medicijnen: string,
    ingeburgerd: number,
    allergien: string,
    fysiekeBelasting: string,
    diplomas: string,
    diplomasGewaardeerd: boolean,
    kinderopvang: boolean,
    rijbewijs: boolean,
    ervaringTalent: string,
    ambitieTalent: string,
    kunnenTalent: string,
    voorstel: string,
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: useMemo(() => {

      return {
        situatieSchets: data?.situatieSchets,
        werkervaring: data?.werkervaring,
        soortUitkering: data?.soortUitkering,
        vervoer: data?.vervoer,
        taalniveau: data?.taalniveau,
        gezondheid: data?.gezondheid,
        opleiding: data?.opleiding,
        medicijnen: data?.medicijnen,
        ingeburgerd: (data?.ingeburgerd == true) ? 1 : 0,
        allergien: data?.allergien,
        fysiekeBelasting: data?.fysiekeBelasting,
        diplomas: data?.diplomas,
        diplomasGewaardeerd: data?.diplomasGewaardeerd,
        kinderopvang: data?.kinderopvang,
        rijbewijs: data?.rijbewijs,
        ervaringTalent: data?.ervaringTalent,
        ambitieTalent: data?.ambitieTalent,
        kunnenTalent: data?.kunnenTalent,
        voorstel: data?.voorstel,
      }
    }, [])
  })
  const onSubmit: SubmitHandler<Inputs> = (_data) => {
    setData({
      ...data, situatieSchets: _data.situatieSchets,
      werkervaring: _data.werkervaring,
      soortUitkering: _data.soortUitkering,
      vervoer: _data.vervoer,
      taalniveau: _data.taalniveau,
      gezondheid: _data.gezondheid,
      opleiding: _data.opleiding,
      medicijnen: _data.medicijnen,
      ingeburgerd: (_data.ingeburgerd == 1) ? true : false,
      allergien: _data.allergien,
      fysiekeBelasting: _data.fysiekeBelasting,
      diplomas: _data.diplomas,
      diplomasGewaardeerd: _data.diplomasGewaardeerd,
      kinderopvang: _data.kinderopvang,
      rijbewijs: _data.rijbewijs,
      ervaringTalent: _data.ervaringTalent,
      ambitieTalent: _data.ambitieTalent,
      kunnenTalent: _data.kunnenTalent,
      voorstel: _data.voorstel
    })
    handleClick("next")

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Situatie</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
          </div>

          <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full">
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    Korte situatieschets
                  </label>
                  <div className="mt-2">
                    <textarea
                      {...register("situatieSchets", { required: "Korte situatieschets is verplicht" })}
                      placeholder="Tijdens de intake komt het talent rustig en verzorgd binnen. Hij maakt direct oogcontact en geeft een stevige handdruk. De lichaamstaal is open, hoewel enigszins nerveus, wat begrijpelijk is bij een eerste kennismaking. Het talent communiceert duidelijk en is trots op eerdere werkervaringen, maar geeft ook eerlijk aan dat er nog veel te leren valt."
                      rows={4}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["situatieSchets"] && <span>{errors["situatieSchets"]?.message}</span>}
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    Werkervaring
                  </label>
                  <div className="mt-2">
                    <textarea
                      {...register("werkervaring", { required: "Werkervaring is verplicht" })}
                      placeholder="Werkervaring varieert van geen tot zeer ervaren, afhankelijk van de tijd en sector waarin iemand heeft gewerkt. Dit kan gaan van starters met stage-ervaring tot senior medewerkers of experts met meer dan 10 jaar ervaring in hun vakgebied. "
                      rows={4}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["werkervaring"] && <span>{errors["werkervaring"]?.message}</span>}
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                    Soort uitkering
                  </label>
                  <div className="mt-2">
                    <Controller
                      name='soortUitkering'
                      control={control}
                      rules={{ required: "Soort uitkering is verplicht" }}
                      render={({ field, fieldState: { error } }) => <><select
                        {...field}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option>Selecteer een optie</option>
                        <option>Geen</option>
                        <option>WIA</option>
                        <option>AOW</option>
                        <option>WW</option>
                      </select>
                        <span>{error?.message}</span></>}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                    Vervoer
                  </label>
                  <div className="mt-2">
                    <Controller
                      name='vervoer'
                      control={control}
                      rules={{ required: "Vervoer is verplicht" }}
                      render={({ field, fieldState: { error } }) => <><select
                        {...field}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option>Selecteer een optie</option>
                        <option>Geen</option>
                        <option>Auto</option>
                        <option>Fiets</option>
                        <option>OV</option>
                        <option>Scootmobiel</option>
                      </select>
                        <span>{error?.message}</span></>} />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                    Taalniveau
                  </label>
                  <div className="mt-2">
                    <Controller
                      name='taalniveau'
                      control={control}
                      rules={{ required: "Taalniveau is verplicht" }}
                      render={({ field, fieldState: { error } }) => <><select
                        {...field}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option>Selecteer een optie</option>
                        <option>A1 (Doorbraakniveau)</option>
                        <option>A2 (Basisniveau)</option>
                        <option>B1 (Drempelniveau)</option>
                        <option>B2 (Vantage niveau)</option>
                        <option>C1 (Effectieve operationele vaardigheid)</option>
                        <option>C2 (Beheersingsniveau)</option>
                      </select>
                        <span>{error?.message}</span></>} />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                    Gezondheid
                  </label>
                  <div className="mt-2">
                    <input
                      {...register("gezondheid", { required: "Gezondheid is verplicht" })}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["gezondheid"] && <span>{errors["gezondheid"]?.message}</span>}
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Opleiding
                  </label>
                  <div className="mt-2">
                    <input
                      {...register("opleiding", { required: "Opleiding is verplicht" })}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["opleiding"] && <span>{errors["opleiding"]?.message}</span>}
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Medicijnen
                  </label>
                  <div className="mt-2">
                    <input
                      {...register("medicijnen", { required: "Medicijnen is verplicht" })}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["medicijnen"] && <span>{errors["medicijnen"]?.message}</span>}
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                    Ingeburgerd
                  </label>
                  <div className="mt-2">
                    <Controller
                      name='ingeburgerd'
                      control={control}
                      rules={{ required: "Ingeburgerd is verplicht" }}
                      render={({ field, fieldState: { error } }) => <><select
                        {...field}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option>Selecteer een optie</option>
                        <option value={1}>Ja</option>
                        <option value={0}>Nee</option>
                      </select>
                        <span>{error?.message}</span></>} />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                    Allergien
                  </label>
                  <div className="mt-2">
                    <input
                      {...register("allergien", { required: "Allergien is verplicht" })}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["allergien"] && <span>{errors["allergien"]?.message}</span>}
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    Fysieke belasting
                  </label>
                  <div className="mt-2">
                    <textarea
                      {...register("fysiekeBelasting", { required: "Fysieke belasting is verplicht" })}
                      placeholder="Bijvoorbeeld het regelmatig tillen van pakketten, dozen of materialen die meer dan 20 kilo wegen, zoals in de bouw, logistiek of magazijnwerk."
                      rows={4}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["fysiekeBelasting"] && <span>{errors["fysiekeBelasting"]?.message}</span>}
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    Diploma's
                  </label>
                  <div className="mt-2">
                    <textarea
                      {...register("diplomas", { required: "Diplomas is verplicht" })}
                      placeholder="Bijvoorbeeld het regelmatig tillen van pakketten, dozen of materialen die meer dan 20 kilo wegen, zoals in de bouw, logistiek of magazijnwerk."
                      rows={4}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["diplomas"] && <span>{errors["diplomas"]?.message}</span>}
                  </div>
                </div>


                <div className='sm:col-span-full'>
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    Checklist
                  </label>
                  <fieldset>
                    <legend className="sr-only">Diploma's gewaardeerd?</legend>
                    <div className="space-y-5">
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            {...register("diplomasGewaardeerd")}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label htmlFor="comments" className="font-medium text-gray-900">
                            Diploma's gewaardeerd?
                          </label>
                          <p id="comments-description" className="text-gray-500">
                            Zijn ze nagekeken door het DUO?
                          </p>
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            {...register("kinderopvang")}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label htmlFor="candidates" className="font-medium text-gray-900">
                            Kinderopvang
                          </label>
                          <p id="candidates-description" className="text-gray-500">
                            Is deze al geregeld?
                          </p>
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            {...register("rijbewijs")}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label htmlFor="offers" className="font-medium text-gray-900">
                            Rijbewijs
                          </label>
                          <p id="offers-description" className="text-gray-500">
                            Heeft het talent een rijbewijs?
                          </p>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                </div>




              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Talentprofiel</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
          </div>

          <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="col-span-full">
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    Wat heb je gedaan
                  </label>
                  <div className="mt-2">
                    <textarea
                      {...register("ervaringTalent", { required: "Wat heb je gedaan is verplicht" })}
                      placeholder="Tijdens de intake komt het talent rustig en verzorgd binnen. Hij maakt direct oogcontact en geeft een stevige handdruk. De lichaamstaal is open, hoewel enigszins nerveus, wat begrijpelijk is bij een eerste kennismaking. Het talent communiceert duidelijk en is trots op eerdere werkervaringen, maar geeft ook eerlijk aan dat er nog veel te leren valt."
                      rows={4}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["ervaringTalent"] && <span>{errors["ervaringTalent"]?.message}</span>}
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    Wat wil je graag
                  </label>
                  <div className="mt-2">
                    <textarea
                      {...register("ambitieTalent", { required: "Wat wil je graag is verplicht" })}
                      placeholder="Tijdens de intake komt het talent rustig en verzorgd binnen. Hij maakt direct oogcontact en geeft een stevige handdruk. De lichaamstaal is open, hoewel enigszins nerveus, wat begrijpelijk is bij een eerste kennismaking. Het talent communiceert duidelijk en is trots op eerdere werkervaringen, maar geeft ook eerlijk aan dat er nog veel te leren valt."
                      rows={4}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["ambitieTalent"] && <span>{errors["ambitieTalent"]?.message}</span>}
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    Wat kan je
                  </label>
                  <div className="mt-2">
                    <textarea
                      {...register("kunnenTalent", { required: "Wat kan je is verplicht" })}
                      placeholder="Tijdens de intake komt het talent rustig en verzorgd binnen. Hij maakt direct oogcontact en geeft een stevige handdruk. De lichaamstaal is open, hoewel enigszins nerveus, wat begrijpelijk is bij een eerste kennismaking. Het talent communiceert duidelijk en is trots op eerdere werkervaringen, maar geeft ook eerlijk aan dat er nog veel te leren valt."
                      rows={4}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["kunnenTalent"] && <span>{errors["kunnenTalent"]?.message}</span>}
                  </div>
                </div>

                <div className="col-span-full">
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                    Ons voorstel
                  </label>
                  <div className="mt-2">
                    <textarea
                      {...register("voorstel", { required: "Voorstel is verplicht" })}
                      placeholder="Tijdens de intake komt het talent rustig en verzorgd binnen. Hij maakt direct oogcontact en geeft een stevige handdruk. De lichaamstaal is open, hoewel enigszins nerveus, wat begrijpelijk is bij een eerste kennismaking. Het talent communiceert duidelijk en is trots op eerdere werkervaringen, maar geeft ook eerlijk aan dat er nog veel te leren valt."
                      rows={4}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["voorstel"] && <span>{errors["voorstel"]?.message}</span>}
                  </div>
                </div>


              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button onClick={handleClick} type="button" className="text-sm font-semibold leading-6 text-gray-900">
                Vorige
              </button>
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Volgende
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}