import { addDoc, collection, doc, DocumentReference, getDoc, getDocs, limit, orderBy, query, QueryDocumentSnapshot, SnapshotOptions, where } from "firebase/firestore";
import { db } from "../utils/firebase";
import { NewRapportageModel } from "../domain/rapportage-utils";
import { NewPraktijkverklaringModel } from "../domain/praktijkverklaring-utils";

export type RemotePraktijkverklaring = {
    reference: DocumentReference,
    aanmaak_datum: Date,
    auteur_ref: DocumentReference,
    content: string,
    talent_ref: DocumentReference
};

const praktijkverklaringConverter = {
    toFirestore: (rapportage: RemotePraktijkverklaring) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemotePraktijkverklaring {
        const data = snapshot.data(options)!;
        return {
            reference: snapshot.ref,
            aanmaak_datum: data.aanmaak_datum.toDate(),
            auteur_ref: data.auteur_ref,
            content: data.content,
            talent_ref: data.talent_ref
        }
    }
}

export default async (max: number, dataOwner: DocumentReference) => {
    const q = query(collection(db, "praktijkverklaring"), where("data_eigenaar_ref", "==", dataOwner), orderBy("aanmaak_datum", "desc"), limit(max)).withConverter(praktijkverklaringConverter);

    const querySnapshot = await getDocs(q);
    const list: RemotePraktijkverklaring[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 
}

export const getPraktijkverklaringDocumentById = async (id: string) => {
    const _doc = doc(db, "praktijkverklaring", id).withConverter(praktijkverklaringConverter);
    const docSnap = await getDoc(_doc);

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    }
}

export const createDocument = (data: NewPraktijkverklaringModel) => {
    return addDoc(collection(db, "praktijkverklaring"), {
        aanmaak_datum: new Date(),
        auteur_ref: data.auteur_reference,
        content: data.content,
        talent_ref: data.talent_reference,
        data_eigenaar_ref: data.data_eigenaar
} as {aanmaak_datum: Date, auteur_ref: DocumentReference, content: string, talent_ref: DocumentReference, data_eigenaar_ref: DocumentReference});
}