import {
  BellIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import Layout from '../../../layout';
import Trajectprogress from '../../../components/traject/trajectprogress/Trajectprogress';
import {
  getDocumentenByTalentId,
  getTalentById,
  TalentModel,
} from '../../../domain/talent-utils';
import {
  getLopendTrajectById,
  LopendTrajectModel,
} from '../../../domain/lopend-traject-utils';
import { useEffect, useState } from 'react';
import DefaultImage from '../../../components/defaultimage/defaultimage';
import Acties from '../../../components/modal/acties/Acties';
import Generate from '../../../components/modal/generate/Generate';
import dayjs from 'dayjs';
import { getGenerativeModel } from 'firebase/vertexai-preview';
import {
  EvaluatieModel,
  getEvaluatiesByTalentId,
} from '../../../domain/evaluatie-utils';
import { getIntakeById } from '../../../domain/intake-utils';
import {
  createRapportage,
  getRapportageByTalentRef,
} from '../../../domain/rapportage-utils';
import { vertexAI } from '../../../utils/firebase';
import { useAuth } from '../../../context/AuthContext';
import OpleidenTab from '../../../components/opleiden/App';
import RapportagesTab from '../../../components/rapportages/App';
import TalentTab from '../../../components/talent/App';
import TrajectTab from '../../../components/traject/App';
import { useNavigate, useParams } from 'react-router-dom';
import { createPraktijkverklaring } from '../../../domain/praktijkverklaring-utils';
import BottomSheet from '../../../components/drawer/bottomsheet/BottomSheet';
import Doelen from '../../../components/doelen/Doelen';
import Updateschrijven from '../../../components/updateschrijven/Updateschrijven';
import Modal from '../../../components/modal/edituser/EditUserModal';
import { PencilIcon } from '@heroicons/react/24/solid';

const tabs = [
  { name: 'Talent', href: '#', current: true },
  { name: 'Traject', href: '#', current: false },
  { name: 'Opleiden', href: '/caseload/details', current: false },
  { name: 'Documenten', href: '/talentenhub/rapportage', current: false },
  { name: 'Rooster', href: '/rooster', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {



  const { talentId } = useParams()
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);


  const toggleBottomSheet = () => setIsOpen(!isOpen);
  const toggleBottomSheet2 = () => setIsOpen2(!isOpen2);



  const [selectedPerson, setSelectedPerson] = useState<TalentModel | null>(null);
  const [lopendTraject, setLopendTraject] = useState<LopendTrajectModel | null>(
    null
  );
  const [openNieuw, setOpenNieuw] = useState(false);
  const [loadingRapportage, setLoadingRapportage] = useState(false);
  const [tabContent, setTabContent] = useState(0);
  const [documenten, setDocumenten] = useState<{ naam: string, aanmaak_datum: Date, id: string, soort: string }[]>([]);
  const [evaluaties, setEvaluaties] = useState<EvaluatieModel[]>([]);
  const [isLoading, setIsLoading] = useState(true); // Nieuwe state voor de skeleton loader

  const navigate = useNavigate()

  const { id } = useParams();

  const { user } = useAuth();

  useEffect(() => {
    setIsLoading(true); // Begin met laden
    getTalentById(id, { traject: true }).then((talent) => {
      Promise.all([getEvaluatiesByTalentId(talent.id, user!), getLopendTrajectById(talent.lopend_traject?.reference), getDocumentenByTalentId(user!, talent, talent.id)]).then(([evaluaties, lopendTraject, documenten]) => {
        setSelectedPerson(talent);
        setLopendTraject(lopendTraject);
        setEvaluaties(evaluaties)
        setDocumenten(documenten)
        setIsLoading(false); // Laden voltooid
      });
    });
  }, [id]);

  function generateRapportage() {
    setLoadingRapportage(true)
    getIntakeById(selectedPerson!.intake_id).then((intake) => {
      const prompt = `Schrijf een uitgebreid voortgangsrapport in HTML formatting met tailwindcss op basis van de informatie die voor hand is van minimaal 400 en maximaal 700 woorden voor het Talent in kwestie. Het rapport moet een 360-graden beeld geven van de prestaties van het Talent. Verwerk in de tekst een zelfreflectie-instrument zoals het Dariuz model om de persoonlijke evaluatie van het Talent vast te leggen. Geef mogelijke aanbevelingen voor persoonlijke ontwikkeling, gebaseerd op de verzamelde data. Structureer het rapport als volgt: Begin met een inleiding, refereer hier naar het begin tot nu toe en opvallende punten in de rapportage, maak een uitgeschreven zelfreflectie middels het Dariuz model zonder bullets, maak een uitgeschreven samenvatting van de wervloerfeedback tot nu toe  zonder bullets, schrijf een samenvatting over de mogelijk behaalde competenties en sluit af met een totaal samenvatting en een evetuele conclusie. Zorg dat het doorlopende teksten zijn zonder bullets.`
      const data = "\n Op basis van de volgende informatie: evaluaties: " + JSON.stringify(evaluaties) + " en de intake: " + JSON.stringify(intake) + " en tot slot de persoonsgegevens: " + JSON.stringify(selectedPerson)
      const finalPrompt = prompt + data
      const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });

      model.generateContent(finalPrompt).then((result) => {
        const response = result.response;
        const text = response.text();
        createRapportage({
          auteur_id: user!.ref.id,
          bedrijf_id: lopendTraject!.werkbedrijf!.reference,
          talent_id: selectedPerson!.ref.id,
          traject_id: selectedPerson!.traject!.reference,
          leidinggevende_id: lopendTraject!.werkvloer_begeleider_ref!.id,
          consulent_id: lopendTraject!.consulent!.reference,
          content: text,
          soort: `${selectedPerson?.voornaam}_1e_Rapportage_${dayjs().format("DD-MM-YYYY-HH:mm")}`,
          startDatum: lopendTraject!.begindatum ?? new Date(),
          data_eigenaar: user?.data_eigenaar_ref!
        }).then((rapportage) => {
          navigate(`/talentenhub/rapportages/detail/${rapportage.id}`)
        })
      })
    }).catch(() => {
      const prompt = `Schrijf een uitgebreid voortgangsrapport in HTML formatting met tailwindcss op basis van de informatie die voor hand is van minimaal 400 en maximaal 700 woorden voor het Talent in kwestie. Het rapport moet een 360-graden beeld geven van de prestaties van het Talent. Verwerk in de tekst een zelfreflectie-instrument zoals het Dariuz model om de persoonlijke evaluatie van het Talent vast te leggen. Geef mogelijke aanbevelingen voor persoonlijke ontwikkeling, gebaseerd op de verzamelde data. Structureer het rapport als volgt: Begin met een inleiding, refereer hier naar het begin tot nu toe en opvallende punten in de rapportage, maak een uitgeschreven zelfreflectie middels het Dariuz model zonder bullets, maak een uitgeschreven samenvatting van de wervloerfeedback tot nu toe  zonder bullets, schrijf een samenvatting over de mogelijk behaalde competenties en sluit af met een totaal samenvatting en een evetuele conclusie. Zorg dat het doorlopende teksten zijn zonder bullets.`
      const data = "\n Op basis van de volgende informatie: evaluaties: " + JSON.stringify(evaluaties) + " en de intake: " + JSON.stringify(null) + " en tot slot de persoonsgegevens: " + JSON.stringify(selectedPerson)
      const finalPrompt = prompt + data
      const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });

      model.generateContent(finalPrompt).then((result) => {
        const response = result.response;
        const text = response.text();
        createRapportage({
          auteur_id: user!.ref.id,
          bedrijf_id: lopendTraject!.werkbedrijf!.reference,
          talent_id: selectedPerson!.ref.id,
          traject_id: selectedPerson!.traject!.reference,
          leidinggevende_id: lopendTraject!.werkvloer_begeleider_ref!.id,
          consulent_id: lopendTraject!.consulent!.reference,
          content: text,
          soort: `${selectedPerson?.voornaam}_1e_Rapportage_${dayjs().format("DD-MM-YYYY-HH:mm")}`,
          startDatum: lopendTraject!.begindatum ?? new Date(),
          data_eigenaar: user?.data_eigenaar_ref!
        }).then((rapportage) => {
          navigate(`/talentenhub/rapportages/detail/${rapportage.id}`)
        })
      })
    })
  }

  function generatePraktijkverklaring() {
    setLoadingRapportage(true)
    Promise.all([getRapportageByTalentRef(user!, selectedPerson!), getIntakeById(selectedPerson!.intake_id)]).then(([rapportages, intake]) => {
      var listRapportagesContent: string[] = []

      for (let rapportage of rapportages) {
        listRapportagesContent.push(rapportage.content)
      }

      const prompt = `Schrijf een uitgebreid samenvattend oordeel voor een praktijkverklaring van [naam talent] die de opleiding [opleiding] volgt. Baseer je oordeel op een grondige analyse van de intake, de rapportages en de vereiste competenties voor deze opleiding.

Focus in je oordeel op de volgende aspecten:

Leerdoelen: In hoeverre heeft het talent de specifieke leerdoelen die zijn geformuleerd in het leerplan en de intake behaald? Verwijs naar concrete voorbeelden uit de rapportage om de behaalde leerdoelen te onderbouwen.
Vakinhoudelijke kerncompetenties: Welke van de volgende vakinhoudelijke kerncompetenties heeft het talent ontwikkeld? [Vermeld hier een lijst van de belangrijkste vakinhoudelijke competenties die relevant zijn voor de opleiding, bijvoorbeeld: analysevaardigheden, probleemoplossend vermogen, kennis van relevante software, etc.] Ondersteun je bevindingen met concrete voorbeelden uit de praktijkopdracht en de rapportage.
Overkoepelende competenties: Naast de vakinhoudelijke competenties, zijn er ook overkoepelende competenties zoals communicatie, samenwerking en probleemoplossing van belang. In hoeverre heeft het talent deze competenties ontwikkeld? Gebruik de STARR-methode (Situatie, Taak, Actie, Resultaat) om concrete voorbeelden te beschrijven.
Sterke punten: Wat zijn de meest opvallende sterke punten van het talent? Hoe heeft het talent deze sterke punten ingezet om bij te dragen aan het succes van de praktijkopdracht?
Ontwikkelpunten: Op welke gebieden kan het talent zich nog verder ontwikkelen om nog beter te functioneren in de beroepspraktijk? Gebruik de feedback uit de rapportage om concrete ontwikkelpunten te benoemen.
Zelfreflectie: In hoeverre heeft het talent blijk gegeven van zelfreflectie? Heeft het talent zijn eigen leerproces actief vormgegeven en feedback van begeleiders gebruikt om te groeien?
Toekomstperspectief: Is het talent klaar voor de volgende fase in de opleiding of de beroepspraktijk? Welke aanbevelingen heb je voor de verdere begeleiding van het talent om een succesvolle [beroep] te worden?
Bij het formuleren van je oordeel, maak gebruik van de volgende informatie:

Intake: Analyseer de startkwalificaties, doelen en verwachtingen die in de intake zijn geformuleerd.
Rapportage: Bestudeer de beoordelingen van de begeleiders, de resultaten van opdrachten en de eigen reflecties van het talent.
Vereiste competenties: Gebruik de vereiste competenties voor deze opleiding om te beoordelen in hoeverre het talent aan de gestelde eisen voldoet.`;
      const data = "\n Op basis van de volgende informatie: rapportages: " + JSON.stringify(listRapportagesContent) + " en de intake: " + JSON.stringify(intake) + " en tot slot het kwalificatie dossier: Servicemedewerker gebouwen"

      const finalPrompt = prompt + data
      const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });

      model.generateContent(finalPrompt).then((result) => {
        const response = result.response;
        const text = response.text();
        createPraktijkverklaring({
          auteur_reference: user!.ref,
          talent_reference: selectedPerson!.ref,
          content: text,
          data_eigenaar: user?.data_eigenaar_ref!
        }).then((praktijkverklaring) => {
          navigate(`/talentenhub/praktijkverklaring/detail/${praktijkverklaring.id}`)
        })
      })
      // 25808"

      // const prompt = `Schrijf een uitgebreid voortgangsrapport in HTML formatting met tailwindcss op basis van de informatie die voor hand is van minimaal 400 en maximaal 700 woorden voor het Talent in kwestie. Het rapport moet een 360-graden beeld geven van de prestaties van het Talent. Verwerk in de tekst een zelfreflectie-instrument zoals het Dariuz model om de persoonlijke evaluatie van het Talent vast te leggen. Geef mogelijke aanbevelingen voor persoonlijke ontwikkeling, gebaseerd op de verzamelde data. Structureer het rapport als volgt: Begin met een inleiding, refereer hier naar het begin tot nu toe en opvallende punten in de rapportage, maak een uitgeschreven zelfreflectie middels het Dariuz model zonder bullets, maak een uitgeschreven samenvatting van de wervloerfeedback tot nu toe  zonder bullets, schrijf een samenvatting over de mogelijk behaalde competenties en sluit af met een totaal samenvatting en een evetuele conclusie. Zorg dat het doorlopende teksten zijn zonder bullets.`
      // const data = "\n Op basis van de volgende informatie: evaluaties: " + JSON.stringify(evaluaties) + " en de intake: " + JSON.stringify(intake) + " en tot slot de persoonsgegevens: " + JSON.stringify(selectedPerson)
      // const finalPrompt = prompt + data
      // const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });

      // model.generateContent(finalPrompt).then((result) => {
      //     const response = result.response;
      //     const text = response.text();
      //     createRapportage({
      //         auteur_id: user!.ref.id,
      //         bedrijf_id: lopendTraject!.werkbedrijf!.reference.id,
      //         talent_id: selectedPerson!.ref.id,
      //         traject_id: selectedPerson!.traject!.reference!.id,
      //         leidinggevende_id: lopendTraject!.werkvloer_begeleider_ref!.id,
      //         consulent_id: lopendTraject!.consulent!.ref.id,
      //         content: text,
      //         soort: `${selectedPerson?.voornaam}_1e_Rapportage_${dayjs().format("DD-MM-YYYY-HH:mm")}`,
      //         startDatum: lopendTraject!.begindatum ?? new Date(),
      //         data_eigenaar: user?.data_eigenaar_ref!
      //     }).then((rapportage) => {
      //         navigate(`/talentenhub/rapportages/detail/${rapportage.id}`)
      //     })
      // })
    })

  }

  function getRender(selectedPerson) {
    tabs.forEach((tab) => (tab.current = false));
    tabs[tabContent].current = true;
    switch (tabContent) {
      case 0:
        return TalentTab(selectedPerson, lopendTraject);
      case 1:
        return TrajectTab(evaluaties);
      case 2:
        return OpleidenTab();
      case 3:
        return RapportagesTab(navigate, documenten);
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleSave = () => {
    // Update logic
    console.log();
    setIsModalOpen(false);
  };



  return (
    <>
      <Layout>
        {isLoading ? (
          // Skeleton Loader
          <div className="p-6 pt-12 bg-white shadow rounded-lg">
            {/* Header Skeleton */}
            <div className="flex items-center gap-4 mb-6">
              <div className="h-24 w-24 rounded-full bg-gray-200"></div>
              <div className="flex flex-col flex-1">
                <div className="h-5 w-3/4 bg-gray-200 rounded mb-2"></div>
                <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
              </div>
              <div className="flex gap-4">
                <div className="h-10 w-36 bg-gray-200 rounded"></div>
                <div className="h-10 w-24 bg-gray-200 rounded"></div>
              </div>
            </div>

            {/* Tabs Skeleton */}
            <div className="flex gap-4 mb-4">
              {Array.from({ length: 5 }).map((_, index) => (
                <div
                  key={index}
                  className="h-8 w-24 bg-gray-200 rounded"
                ></div>
              ))}
            </div>

            {/* Content Skeleton */}
            <div className="grid grid-cols-3 gap-6">
              {/* Left Section */}
              <div className="col-span-2">
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="h-5 w-full bg-gray-200 rounded"></div>
                  <div className="h-5 w-full bg-gray-200 rounded"></div>
                </div>
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="h-5 w-full bg-gray-200 rounded"></div>
                  <div className="h-5 w-full bg-gray-200 rounded"></div>
                </div>
                <div className="h-40 bg-gray-200 rounded mb-4"></div>
              </div>

              {/* Right Section */}
              <div>
                <div className="h-5 w-1/2 bg-gray-200 rounded mb-4"></div>
                <div className="space-y-4">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-2"
                    >
                      <div className="h-5 w-5 rounded-full bg-gray-200"></div>
                      <div className="h-4 w-3/4 bg-gray-200 rounded"></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex min-h-full flex-col bg-white ">
            {/* 3 column wrapper */}
            <div className="mx-auto w-full max-w-7xl grow lg:flex">
              {/* Left sidebar & main wrapper */}
              <div className="flex-1 lg:flex">
                <div className="px-0 py-0 sm:px-0  lg:flex-1 ">
                  <div className="mx-auto  bg-slate-50">
                    <div className="pb-6">
                      <img
                        src={selectedPerson?.foto}
                        alt="Background"
                        className="h-24 w-full object-cover sm:h-20 lg:h-28 "
                      />
                      <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-16">
                        <div>
                          <div className="-m-1 flex">
                            <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                              <DefaultImage profiel_foto={selectedPerson?.foto} className={"size-24 shrink-0 sm:size-40 lg:size-48"} />
                            </div>
                          </div>
                        </div>
                        <div className="mt-6 sm:ml-6 sm:flex-1">
                          <div>
                            <div className="flex items-center">
                              <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                {selectedPerson?.voornaam}{' '}
                                {selectedPerson?.achternaam}
                              </h3>
                              <button
                                type="button"
                                onClick={() => setIsModalOpen(true)}
                                className="ml-auto flex items-center justify-center rounded-full bg-gray-200 p-2 text-gray-700 hover:bg-gray-300"
                              >
                                <PencilIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                            <p className="text-sm text-gray-500">
                              {selectedPerson?.plaats}
                            </p>
                          </div>
                          <div className="mt-5 flex flex-wrap items-center justify-start gap-3 sm:flex-nowrap">
                            <button
                              type="button"
                              className="flex-1 sm:flex-none items-center justify-center rounded-md bg-slate-100 px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50"
                            >
                              <a href={`tel:${selectedPerson?.telefoonnummer}`}>
                                <span>Bellen</span>
                              </a>
                            </button>
                            <button
                              type="button"
                              onClick={(e) => setOpenNieuw(true)}
                              className="flex-1 sm:flex-none items-center justify-center rounded-md bg-gradient-to-r from-pink-500 to-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-xl shadow-pink-500/20 hover:bg-indigo-500"
                            >
                              <span>Document Genereren</span>
                              <Acties
                                open={openNieuw}
                                setOpen={setOpenNieuw}
                                onclick={generateRapportage}
                                onclickparktijk={generatePraktijkverklaring}
                              />
                            </button>                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Tabbartalenten /> */}


                  <div className="px-6  bg-slate-50 overflow-auto">
                    <div className="">
                      <div className="">
                        <nav className="-mb-px flex space-x-8 ">
                          {tabs.map((tab, i) => (
                            <a
                              key={tab.name}
                              onClick={(e) => {
                                setTabContent(i);
                              }}
                              aria-current={tab.current ? 'page' : undefined}
                              className={classNames(
                                tab.current
                                  ? 'border-blue-700 text-blue-700 shadow-xl shadow-blue-100'
                                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'whitespace-nowrap border-b-2 sm:px-2 pb-4 sm:text-sm text-xs font-medium'
                              )}
                            >
                              {tab.name}
                            </a>
                          ))}
                        </nav>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    {getRender(selectedPerson)}
                  </div>
                </div>

                <div className="border-gray-200 px-4 py-6 sm:px-6 lg:w-96 lg:pr-8 xl:pr-6 h-screen">
                  <Trajectprogress
                    traject={selectedPerson?.traject}
                    lopendTraject={lopendTraject}
                  />
                </div>
              </div>
            </div>
            {loadingRapportage && <Generate />}
          </div>
        )}
        {/* <Tabbarmobile talentId={talentId} /> */}

        {/* Buttons for Mobile */}
        <div className="fixed bottom-0 pb-24 left-0 right-0 flex gap-2 px-4 py-3 shadow sm:hidden">
          <button
            onClick={toggleBottomSheet2}
            type="button"
            className="flex items-center justify-center w-1/2 rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white hover:bg-blue-600 shadow"
          >
            <BellIcon className="w-5 h-5 mr-2" aria-hidden="true" />
            Schrijf een update
          </button>
          <button
            onClick={toggleBottomSheet}
            type="button"
            className="flex items-center justify-center w-1/2 rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white hover:bg-blue-400 shadow"
          >
            <PlusIcon className="w-5 h-5 mr-2" aria-hidden="true" />
            Taken en Doelen
          </button>
        </div>


        <BottomSheet isOpen={isOpen} toggle={toggleBottomSheet} title="Doelen en Taken">
          <Doelen talentId={id} />
        </BottomSheet>

        <BottomSheet isOpen={isOpen2} toggle={toggleBottomSheet2} title="Update Schrijven">
          <Updateschrijven user={user!} talentId={id} />
        </BottomSheet>

      </Layout>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}

          onSave={handleSave} userData={{
            name: '',
            email: '',
            location: ''
          }} />
      )}
    </>
  );
}