import { Dialog, DialogPanel } from "@headlessui/react";
import { useState } from "react";

export default function TalentInviteModal({ isOpen, onClose, talentEmail }: { isOpen: boolean; onClose: () => void; talentEmail: string; }) {
    const [subject, setSubject] = useState("Bloeiindex uitnodiging voor een intakegesprek");
    const [body, setBody] = useState("Beste [Naam Talent], Wij nodigen je graag uit voor een intakegesprek om jouw traject bij [Naam Organisatie] te bespreken. Tijdens dit gesprek maken we kennis en bespreken we jouw mogelijkheden, doelen en interesses om een passende ontwikkeling te starten.");
    const [time, setTime] = useState("");
    const [date, setDate] = useState("");
    const [location, setLocation] = useState("");
    const [instructions, setInstructions] = useState("");
    const [contactPerson, setContactPerson] = useState("");

    const handleSend = () => {
        const mailtoLink = `mailto:${talentEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
            `: ${body}\n\nMeeting Details:\nTime: ${time}\nDate: ${date}\nLocation: ${location}\nInstructions: ${instructions}\nContact Person: ${contactPerson}`
        )}`;
        window.location.href = mailtoLink;
        onClose(); // Close the modal after sending the email
    };

    return (
        <Dialog open={isOpen} onClose={onClose} className="relative z-50">
            <div className="fixed inset-0 bg-black bg-opacity-30" />
            <div className="fixed inset-0 flex items-center justify-center">
                <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 shadow-xl transition-all">
                    <h2 className="text-lg font-medium text-gray-900">Talent uitnodigen</h2>
                    <div className="mt-4 space-y-4">
                        <div>
                            <label htmlFor="subject" className="block text-sm font-medium text-gray-700">Onderwerp</label>
                            <input
                                id="subject"
                                type="text"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor="body" className="block text-sm font-medium text-gray-700">Emailtekst</label>
                            <textarea
                                id="body"
                                value={body}
                                placeholder="Schrijf hier uw bericht. Bijvoorbeeld: Wij nodigen u graag uit voor een gesprek over..."
                                onChange={(e) => setBody(e.target.value)}
                                rows={8}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            ></textarea>
                        </div>
                        <div>
                            <label htmlFor="time" className="block text-sm font-medium text-gray-700">Tijd</label>
                            <input
                                id="time"
                                type="time"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor="date" className="block text-sm font-medium text-gray-700">Datum</label>
                            <input
                                id="date"
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor="location" className="block text-sm font-medium text-gray-700">Locatie</label>
                            <input
                                id="location"
                                type="text"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor="instructions" className="block text-sm font-medium text-gray-700">Instructies</label>
                            <textarea
                                id="instructions"
                                value={instructions}
                                onChange={(e) => setInstructions(e.target.value)}
                                rows={5}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            ></textarea>
                        </div>
                        <div>
                            <label htmlFor="contactPerson" className="block text-sm font-medium text-gray-700">Contactpersoon</label>
                            <input
                                id="contactPerson"
                                type="text"
                                value={contactPerson}
                                onChange={(e) => setContactPerson(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>
                    <div className="mt-6 flex justify-end space-x-3">
                        <button
                            onClick={onClose}
                            className="rounded-md bg-gray-200 px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-300"
                        >
                            Annuleren
                        </button>
                        <button
                            onClick={handleSend}
                            className="rounded-md bg-blue-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500"
                        >
                            Verzenden
                        </button>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    );
}