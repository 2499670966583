import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { TrajectModel } from '../../domain/traject-utils';
import Logo from '../../logoGemeente.png';

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 10,
        fontFamily: 'Helvetica',
        backgroundColor: '#f7f8fa',
    },
    container: {
        backgroundColor: '#ffffff',
        padding: 20,
        borderRadius: 5,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '2px solid #888',
        paddingBottom: 10,
        marginBottom: 20,
    },
    headerLeft: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    headerRight: {
        textAlign: 'right',
    },

    headerImage: {
        width: 70,
        height: 60,
        marginLeft: 5,
    },

    headerText: {
        flexDirection: 'column',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 5,
    },
    reference: {
        fontSize: 10,
        color: '#555',
        fontStyle: 'italic',
        textAlign: 'right',
    },

    sectionTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#333',
    },
    fieldGroup: {
        marginTop:20,
        marginBottom: 20,
    },
    fieldRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    fieldLabel: {
        flex: 1,
        fontWeight: 'bold',
        color: '#555',
        marginRight: 10,
    },
    fieldValue: {
        flex: 3,
        backgroundColor: '#f9f9f9',
        padding: 8,
        borderRadius: 5,
        border: '1px solid #ddd',
    },
     bigFieldValue: {
        flex: 3,
        backgroundColor: '#f9f9f9',
        padding: 18,
        borderRadius: 5,
        border: '1px solid #ddd',
    },
    infoBox: {
        padding: 15,
        backgroundColor: '#e9ecef',
        borderRadius: 5,
        marginBottom: 20,
    },
    infoText: {
        fontSize: 10,
        color: '#555',
        marginBottom: 5,
    },
    tableCell: {
        flex: 1,
        padding: 32,
        backgroundColor: '#f9f9f9',
        border: '1px solid #ddd',
        borderRadius: 5,
    },
});

interface OntwikkeldoelenProps {
    content?: string | null;
    traject?: TrajectModel | null

}



const OntwikkeldoelenPDFFile = ({ content, traject }: OntwikkeldoelenProps) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <Text style={styles.headerLeft}>Gemeente Den Haag</Text>
                        <View style={styles.headerRight}>
                            <Image
                                style={styles.headerImage}
                                src={Logo}
                            />
                            <Text>Dienst SZW</Text>
                        </View>
                    </View>

                    <Text style={styles.reference}>Referentie:</Text>


                    <View style={styles.fieldGroup}>
                        <Text style={styles.sectionTitle}>Ontwikkeldoelen voorziening: aanpak, voortgang en eind</Text>
                        <Text>Ontwikkelen</Text>
                    </View>


                    <View style={styles.fieldGroup}>
                        <View style={styles.fieldRow}>
                            {/* Dropdown */}
                            <Text style={styles.fieldLabel}>Ontwikkeldoel</Text>
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen match gevonden in TrajectModel (String) */}
                            <Text style={styles.fieldLabel}>Gewenste resultaat</Text>
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen match gevonden in TrajectModel (Date) */}
                            <Text style={styles.fieldLabel}>Aanpak</Text>
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen gelijke match gevonden in TrajectModel (Date) */}
                            <Text style={styles.fieldLabel}>Voortgang 1</Text>
                            <Text style={styles.fieldValue}>{traject?.evaluatie_momenten || "5 December 2024"}</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen gelijke match gevonden in TrajectModel (Date) */}
                            <Text style={styles.fieldLabel}>Voortgang 2</Text>
                            <Text style={styles.fieldValue}>{traject?.evaluatie_momenten || "16 Februari 2024"}</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen gelijke match gevonden in TrajectModel (Date) */}
                            <Text style={styles.fieldLabel}>Eindgesprek</Text>
                            <Text style={styles.fieldValue}>{traject?.evaluatie_momenten || "28 Maart 2024"}</Text>
                        </View>
                    </View>


                    <View style={styles.fieldGroup}>
                        <Text style={styles.sectionTitle}>Optioneel aanvullend ontwikkeldoel</Text>
                        <Text  style={{ marginBottom: 10 }}>Indien aanbieder op de werkvloer extra ontwikkeldoelen signaleert, kunnen deze hier worden opgenomen:</Text>
                        <View style={styles.fieldRow}>
                            {/* Dropdown */}
                            <Text style={styles.fieldLabel}>Ontwikkeldoel</Text>
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen match gevonden in TrajectModel (String) */}
                            <Text style={styles.fieldLabel}>Gewenste resultaat</Text>
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen match gevonden in TrajectModel (Date) */}
                            <Text style={styles.fieldLabel}>Aanpak</Text>
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen gelijke match gevonden in TrajectModel (Date) */}
                            <Text style={styles.fieldLabel}>Voortgang 1</Text>
                            <Text style={styles.fieldValue}>{traject?.evaluatie_momenten || "5 December 2024"}</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen gelijke match gevonden in TrajectModel (Date) */}
                            <Text style={styles.fieldLabel}>Voortgang 2</Text>
                            <Text style={styles.fieldValue}>{traject?.evaluatie_momenten || "16 Februari 2024"}</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen gelijke match gevonden in TrajectModel (Date) */}
                            <Text style={styles.fieldLabel}>Eindgesprek</Text>
                            <Text style={styles.fieldValue}>{traject?.evaluatie_momenten || "28 Maart 2024"}</Text>
                        </View>
                    </View>

                    <View style={styles.fieldGroup}>
                        <Text style={styles.sectionTitle}>Advies bij beëindiging</Text>
                        <Text  style={{ marginBottom: 10 }}>Na het eindgesprek geeft de aanbieder advies over een passend vervolg van het traject en geeft aan welke voorwaarden nodig zijn zodat de deelnemer zich optimaal kan blijven ontwikkelen.
                            (Denk hierbij aan: direct aan het werk, traject verlengen, naar een werk-ontwikkeltraject, baan met ondersteuning.)</Text>
                        <Text style={styles.tableCell}></Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default OntwikkeldoelenPDFFile              