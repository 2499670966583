import { Fragment, useState } from "react";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { FunnelIcon, ChevronDownIcon } from "@heroicons/react/24/solid"; // Import icons

export default function FilterComponent({filters, setFilters}) {
    const [isOpen, setIsOpen] = useState(false);
    const [tempFilters, setTempFilters] = useState({
        exclude: filters.exclude
    });

    const categories = ["Education", "Health", "Business", "Technology"];
    const cities = ["Amsterdam", "Rotterdam", "Utrecht", "The Hague"];
    const years = ["2023", "2024", "2025"];
    const states = ["Active", "Inactive"];

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return (
        <div className="relative">
            {/* Round Filter Icon */}
            <button
                onClick={openModal}
                className="fixed sm:ml-32 sm:mb-12 mb-24 bottom-6 left-1/2 transform -translate-x-1/2 flex items-center justify-center p-3 bg-blue-700 text-white rounded-full shadow-lg hover:bg-blue-700"
            >
                <span className="mr-2 ml-2">Filter Talenten</span> {/* Added text "Filter" before the icon */}
                <FunnelIcon className="h-6 w-6 mr-2" />
            </button>

            {/* Modal */}
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <div className="fixed inset-0 bg-black bg-opacity-50" />
                    <div className="fixed inset-0 flex items-center justify-center p-4">
                        <Dialog.Panel className="bg-white rounded-lg shadow-xl w-full max-w-sm p-6">
                            <Dialog.Title className="text-lg font-semibold text-gray-900">
                                Talentenhub Filters
                            </Dialog.Title>
                            <div className="mt-4 space-y-4">

                                {/* Category Dropdown */}
                                <Listbox value={filters.category} onChange={(value) => setFilters({ ...filters, category: value })}>
                                    <div className="relative">
                                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                                            Traject
                                        </Listbox.Label>
                                        <Listbox.Button className="w-full mt-1 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-left cursor-pointer flex justify-between items-center">
                                            {filters.category || "Select a category"}
                                            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                        </Listbox.Button>
                                        <Listbox.Options className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md z-10">
                                            {categories.map((category, index) => (
                                                <Listbox.Option key={index} value={category} className="cursor-pointer hover:bg-gray-100 px-4 py-2">
                                                    {category}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </Listbox>

                                {/* City Dropdown */}
                                <Listbox value={filters.city} onChange={(value) => setFilters({ ...filters, city: value })}>
                                    <div className="relative">
                                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                                            Gemeente
                                        </Listbox.Label>
                                        <Listbox.Button className="w-full mt-1 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-left cursor-pointer flex justify-between items-center">
                                            {filters.city || "Select a city"}
                                            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                        </Listbox.Button>
                                        <Listbox.Options className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md z-10">
                                            {cities.map((city, index) => (
                                                <Listbox.Option key={index} value={city} className="cursor-pointer hover:bg-gray-100 px-4 py-2">
                                                    {city}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </Listbox>

                                {/* Year Dropdown */}
                                <Listbox value={filters.year} onChange={(value) => setFilters({ ...filters, year: value })}>
                                    <div className="relative">
                                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                                            Jaar
                                        </Listbox.Label>
                                        <Listbox.Button className="w-full mt-1 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-left cursor-pointer flex justify-between items-center">
                                            {filters.year || "Select a year"}
                                            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                        </Listbox.Button>
                                        <Listbox.Options className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md z-10">
                                            {years.map((year, index) => (
                                                <Listbox.Option key={index} value={year} className="cursor-pointer hover:bg-gray-100 px-4 py-2">
                                                    {year}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </Listbox>

                                {/* State Dropdown */}
                                <Listbox value={filters.state} onChange={(value) => setFilters({ ...filters, state: value })}>
                                    <div className="relative">
                                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                                            Status
                                        </Listbox.Label>
                                        <Listbox.Button className="w-full mt-1 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-left cursor-pointer flex justify-between items-center">
                                            {filters.state || "Select a state"}
                                            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                        </Listbox.Button>
                                        <Listbox.Options className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md z-10">
                                            {states.map((state, index) => (
                                                <Listbox.Option key={index} value={state} className="cursor-pointer hover:bg-gray-100 px-4 py-2">
                                                    {state}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </Listbox>

                                {/* Active / Non-Active Toggle Switch */}
                                <div className="flex items-center justify-between mt-6">
                                    <span className="text-sm font-medium text-gray-700">Status</span>
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            defaultChecked={tempFilters.exclude}
                                            onChange={() => setTempFilters({ ...tempFilters, exclude: !tempFilters.exclude })}
                                            className="sr-only peer"
                                        />
                                        <span className="mr-3 text-sm font-medium text-gray-900">
                                            {filters.isActive ? "Lopend" : "Beëindigd"}
                                        </span>
                                        <div className="w-11 h-6 bg-gray-300 rounded-full peer-checked:bg-blue-600 peer-focus:ring-4 peer-focus:ring-blue-300 relative">
                                            <div className="absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition-transform peer-checked:translate-x-5"></div>
                                        </div>
                                        
                                    </label>
                                </div>
                            </div>

                            {/* Actions */}
                            <div className="mt-6 flex justify-between">
                                <button
                                    onClick={closeModal}
                                    className="px-4 py-2 bg-gray-50 rounded-lg text-gray-700 hover:bg-gray-400"
                                >
                                    Annuleren
                                </button>
                                <button
                                    onClick={() => {
                                        setFilters({...filters, exclude: tempFilters.exclude})
                                        console.log("Filters applied:", filters);
                                        closeModal();
                                    }}
                                    className="px-4 py-2 bg-blue-700 text-white rounded-lg hover:bg-blue-600"
                                >
                                    Toepassen
                                </button>
                            </div>
                        </Dialog.Panel>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}