import {
    BuildingOfficeIcon,
    ChatBubbleLeftIcon,
    CheckCircleIcon,
    CreditCardIcon,
    FolderArrowDownIcon,
    HomeModernIcon,
    UserIcon,
    UsersIcon,
  } from '@heroicons/react/20/solid';
  import {
    CheckBadgeIcon,
    ClockIcon,
    HomeIcon,
    UserCircleIcon,
  } from '@heroicons/react/24/outline';
  import { Link, useLocation } from 'react-router-dom';
  
  export default function Example() {
    const location = useLocation();
  
    return (
      <div className="tabbar flex h-16 justify-around border-gray-200 bg-white/70 backdrop-blur shadow-lg sticky bottom-0 px-4 sm:hidden z-10">
        <Link
          className={`flex flex-col items-center justify-center ${
            location.pathname === '/' ? 'text-blue-600' : 'text-gray-950'
          }`}
          to="/"
        >
          <div className="text-center">
            <HomeIcon
              aria-hidden="true"
              className={`h-6 w-6 mx-auto ${
                location.pathname === '/' ? 'text-blue-600' : 'text-gray-500'
              }`}
            />
            <div className="text-xs text-slate-700">Home</div>
          </div>
        </Link>
        <Link
          className={`flex flex-col items-center justify-center ${
            location.pathname === '/talentenhub' ? 'text-blue-600' : 'text-gray-950'
          }`}
          to="/talentenhub"
        >
          <div className="text-center">
            <UserCircleIcon
              aria-hidden="true"
              className={`h-6 w-6 mx-auto ${
                location.pathname === '/talentenhub' ? 'text-blue-600' : 'text-gray-500'
              }`}
            />
            <div className="text-xs text-slate-700">Talentenhub</div>
          </div>
        </Link>
        <Link
          className={`flex flex-col items-center justify-center ${
            location.pathname === '/rooster' ? 'text-blue-600' : 'text-gray-950'
          }`}
          to="/rooster"
        >
          <div className="text-center">
            <ClockIcon
              aria-hidden="true"
              className={`h-6 w-6 mx-auto ${
                location.pathname === '/rooster' ? 'text-blue-600' : 'text-gray-500'
              }`}
            />
            <div className="text-xs text-slate-700">Rooster</div>
          </div>
        </Link>
        <Link
          className={`flex flex-col items-center justify-center ${
            location.pathname === '/bijenkorf' ? 'text-blue-600' : 'text-gray-950'
          }`}
          to="/bijenkorf"
        >
          <div className="text-center">
            <CheckBadgeIcon
              aria-hidden="true"
              className={`h-6 w-6 mx-auto ${
                location.pathname === '/bijenkorf' ? 'text-blue-600' : 'text-gray-500'
              }`}
            />
            <div className="text-xs text-slate-700">Bijenkorf</div>
          </div>
        </Link>
      </div>
    );
  }