import React, { useState } from 'react';
import { TaakManger, Taak } from './taakmanager/App';
import { TaakDetailsForm } from './taakdetails/App';
import Layout from '../../layout';
import { useEffect } from 'react';
import {
    Dialog,
    DialogPanel,
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  } from '@headlessui/react'

  import {
    Bars3Icon,
    CalendarDaysIcon,
    CreditCardIcon,
    EllipsisVerticalIcon,
    FaceFrownIcon,
    FaceSmileIcon,
    FireIcon,
    HandThumbUpIcon,
    HeartIcon,
    MapPinIcon,
    PaperClipIcon,
    UserCircleIcon,
    PlusIcon,
    XMarkIcon as XMarkIconMini,
  } from '@heroicons/react/20/solid'

var taken: Taak[] = []

export default function LoonWaardeMeting() {

    const [selectedLocatie, setSelectedLocatie] = useState('');
    const [selectedContact, setSelectedContact] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [update, setUpdate] = useState(false)


    //Locatie
    const handleOptionSelectL = (location: string) => {
        setSelectedLocatie(location);
        setIsOpen(false);
    };

    //Conatct
    const handleOptionSelectC = (contact: string) => {
        setSelectedContact(contact);
        setIsOpen(false);
    };

    const voegTaakToe = (taak: Taak) => {
        taken.push(taak)
        setUpdate(true)
    };

    const deleteTaak = (ind: number) => {
        let _taken = taken.filter((_, i) => i !== ind)
        taken = _taken
        setUpdate(true)
    }



    useEffect(() => {
        setUpdate(false)
    }, [update])



    return (
        <Layout>
            <div className="space-y-10 divide-y divide-gray-900/10 pt-10 overflow-y-auto max-h-screen px-4">
            <header className="relative isolate pt-16">
              <div aria-hidden="true" className="absolute inset-0 -z-10 overflow-hidden">
                <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
                  <div
                    style={{
                      clipPath:
                        'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                    }}
                    className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
                  />
                </div>
                <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
              </div>

              <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
                <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                  <div className="flex items-center gap-x-6 ">
                    <img
                      alt=""
                      src="https://www.carfo.nl/userfiles/carfo.nl/images/pasfoto27.jpg"
                      className="h-16 w-16 flex-none rounded-full ring-1 ring-gray-900/10 object-cover"
                    />
                    <h1>
                      <div className="text-sm leading-6 text-gray-500">
                        Document: <span className="text-gray-700">Loonwaarde Meting</span><br></br>
                       <span className="text-gray-700">Naam van Canidaat</span>
                      </div>
                      <div className="mt-1 text-base font-semibold leading-6 text-gray-900"></div>
                    </h1>
                  </div>
                  <div className="flex items-center gap-x-4 sm:gap-x-6">

                    {/* <a href="#" className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block">
                      Bewerken
                    </a>
                    <a
                      href="#"
                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Versturen
                    </a> */}

                    <Menu as="div" className="relative sm:hidden">
                      <MenuButton className="-m-3 block p-3">
                        <span className="sr-only">More</span>
                        <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
                      </MenuButton>

                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <MenuItem>
                          <button
                            type="button"
                            className="block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                          >
                            Copy URL
                          </button>
                        </MenuItem>
                        <MenuItem>
                          <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                            Bewerken
                          </a>
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                </div>
              </div>
            </header>


                <div className="max-w-4xl mx-auto px-6 py-8 shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg sm:pb-14 xl:px-16 xl:pb-20 xl:pt-16">
                    <h2 className="text-base font-semibold leading-6 text-gray-900">Loonwaardemeting</h2>
                    <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2"></dl>
                    <p className="mt-1 text-sm leading-6 text-gray-600"> Wat is een loonwaarde onderzoek? Wanneer een medewerker niet volledig kan voldoen aan de eisen van de functie, kan een loonwaarde onderzoek helpen. 
                    Het doel van dit onderzoek is om een helder en objectief advies te geven over een passend salaris bij een aangepaste functie of veranderde werkprestaties.</p>

                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6"></div>

                    <div className="text-base font-semibold leading-7 text-gray-900 w-full sm:w-1/2 p-2 rounded-md mb-2">Bedrijfsgegevens</div>

                    <div className="space-y-4">
                        <label className="block text-sm font-medium text-gray-700">
                            Is de hoofdlocatie van uw bedrijf dezelfde locatie als de werklocatie waar de werknemer werkzaam is?
                        </label>


                        <select
                            value={selectedLocatie}
                            onChange={(e) => handleOptionSelectL(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6"
                        >
                            <option value="" disabled selected>
                                Selecteer een optie
                            </option>
                            <option value="De hoofdlocatie van het bedrijf is hetzelfde als de werklocatie van de werknemer">
                                De hoofdlocatie van het bedrijf is hetzelfde als de werklocatie van de werknemer
                            </option>
                            <option value="De hoofdlocatie van het bedrijf is anders dan de werklocatie van de werknemer">
                                De hoofdlocatie van het bedrijf is anders dan de werklocatie van de werknemer
                            </option>
                        </select>



                        <br></br>
                        <div className="grid grid-cols-2 gap-4">

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Wat is de bedrijfsnaam?
                                </label>
                                <input
                                    type="text"
                                    value="BedrijfNaam"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                    readOnly
                                />
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Wat is het adress?
                                </label>
                                <input
                                    type="text"
                                    value="Adress"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                    readOnly />

                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Wat is de plaats?
                                </label>
                                <input
                                    type="text"
                                    value="Plaats"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                    readOnly />

                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Wie is de contactpersoon?
                                </label>
                                <input
                                    type="text"
                                    value="Contactpersoon"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                    readOnly
                                />


                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Wat is de postcode?
                                </label>
                                <input
                                    type="text"
                                    value="Postcode"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                    readOnly />


                            </div>
                        </div>

                        <br></br>

                        <div className="grid grid-cols-2 gap-4">
                            <div> <label className="block text-sm font-medium text-gray-700 mb-2">
                                Wat is het telefoonnummer van de werkgever?
                            </label>
                                <input type="text" value="Telefoonnummer werkgever" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                    readOnly />

                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Wat is de grootte van het bedrijf / werklocatie (met vermelding van personen of fte's)?
                                </label>
                                <input type="text" value="Grote werklocatie" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                    readOnly />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Wat is het e-mailadres van de werkgever?
                                </label>
                                <input type="text" value="E-mail werkgever" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                    readOnly />

                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Wat is de contractperiode (vast contract, jaar of half jaar) van de werknemer die het loonwaardeonderzoek betreft en wat is de ingansdatum van het contract, of bij verlening van het contract de datum waarop het verlengde contract ingaat?
                                </label>
                                <input type="text" value="Contract periode" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                    readOnly />
                            </div>
                        </div>

                        {/* JIC (Just in case): className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6 mb-2" */}

                        <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6"></div>
                        <div className="text-base font-semibold leading-7 text-gray-900 w-full sm:w-1/2 p-2 rounded-md mb-2">
                            Algemeen en taken werknemer
                        </div>


                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-start">
                            <div className="space-y-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Wat is de functie van de werknemer?
                                </label>
                                <input
                                    type="text"
                                    value="Functie"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                    readOnly
                                />
                                <label className="block text-sm font-medium text-gray-700">
                                    Hoe vaak heeft u contact met uw werknemer?
                                </label>
                                <select
                                    value={selectedContact}
                                    onChange={(e) => handleOptionSelectC(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                >
                                    <option value="" disabled>
                                        Selecteer een optie
                                    </option>
                                    <option value="Elke dag">Elke dag</option>
                                    <option value="3-4 dagen per week">3-4 dagen per week</option>
                                    <option value="2-3 dagen per week">2-3 dagen per week</option>
                                    <option value="1 dag per week">1 dag per week</option>
                                    <option value="Minder dan 1 dag per week">Minder dan 1 dag per week</option>
                                </select>

                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Is uw bedrijf aangesloten bij een cao of heeft een eigen regeling?
                                </label>
                                <select className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2">
                                    <option value="" disabled selected>
                                        Selecteer een optie
                                    </option>
                                    <option value="cao">Ja, CAO</option>
                                    <option value="regeling">Ja, Regeling</option>
                                    <option value="beide">Beide</option>
                                    <option value="Nee">Nee</option>
                                </select>
                            </div>

                            <div className="space-y-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Hoeveel uur werkt de werknemer per week?
                                </label>
                                <input
                                    type="text"
                                    value="Uur per week"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                    readOnly
                                />
                                <label className="block text-sm font-medium text-gray-700">
                                    Is de werknemer voldoende ingewerkt?
                                </label>
                                <select
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                >
                                    <option value="" disabled selected>
                                        Selecteer een optie
                                    </option>
                                    <option value="ja">Ja</option>
                                    <option value="nee">Nee</option>
                                </select>

                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Wat is het aantal voltijdsuren per werkweek in de cao of de regeling die uw bedrijf volgt?
                                </label>
                                <input
                                    type="text"
                                    value="In uur per week"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                    readOnly
                                />
                            </div>
                        </div>


                        {/* !!! */}
                        <div className="space-y-4 mt-4">
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                namelijk:
                            </label>

                            <div className="mt-2">
                                <textarea
                                    rows={4}
                                    placeholder="Type hier"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />

                            </div>
                        </div>



                        <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6"></div>
                        <label className="block text-sm font-medium text-gray-700 mb-2  w-full" >Wat zijn de belangrijkste taken die de werknemer uitvoert (maximaal 5 taken)?</label>
                        <label className="block text-sm font-medium text-gray-700 mb-2 ">Beschrijf per taak de werkzaamheden. Geef aan wat de omvang in tijd is van de taak (groot, gemiddeld of klein) ten opzichte van de functie.</label>
                        <div className="bg-white shadow-md border border-gray-300 rounded-md mb-4 last:mb-0 mb-2 w-full">
                            <TaakManger taken={taken} deleteTaak={deleteTaak} voegTaakToe={voegTaakToe} />

                        </div>

                        <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6"></div>
                        <div className="text-base font-semibold leading-7 text-gray-900 w-full sm:w-1/2 p-2 rounded-md mb-2">Arbeidsproductiviteit</div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Arbeidsproductiviteit geeft aan hoeveel werk iemand doet in een bepaalde tijd. Indien de taken worden uitgevoerd door een reguliere werknemer, wat zijn dan de eisen voor de arbeidsproductiviteit?
                        </label>

                        <span className="block text-sm font-medium text-gray-700 mb-2">
                            Bijvoorbeeld: <br></br>

                            * Hoelang mag een reguliere werknemer over het werk doen? <br></br>
                            * Wat is de productie per tijdseenheid?

                        </span>

                        <span className="block text-sm font-medium text-gray-700 mb-2">
                            Opmerkingen: <br></br>

                            * Indien binnen uw bedrijf geen reguliere werknemer met die functie aanwezig is, probeer dan een inschatting te maken van de arbeidsproductiviteitseisen zoals die regulier zouden gelden. <br></br>
                            * Mensen die werken via de Participatiewet, Wajong of WSW zijn geen reguliere werknemers?

                        </span>

                        <TaakDetailsForm taken={taken}></TaakDetailsForm>


                        <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6"></div>
                        <div className="text-base font-semibold leading-7 text-gray-900 w-full sm:w-1/2 p-2 rounded-md mb-2">Kwaliteit</div>

                        <span className="block text-sm font-medium text-gray-700 mb-2">
                            Stel dat de functie wordt uitgevoerd door een reguliere werknemer, wat zijn dan de eisen voor de kwaliteit? Als fouten tijdens het werk door de werknemer zelf hersteld worden zal dit tot uitdrukking komen bij tempo.
                            Als er fouten resulteren in een toename van het gebruik van grondstoffen, meer afgekeurd product bij kwaliteitscontrole in een ander werkproces of meer klachten en reparaties na aflevering bij de klant, dan komt dat hier tot uitdrukking.
                        </span>
                        <TaakDetailsForm taken={taken}></TaakDetailsForm>



                        <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6"></div>
                        <div className="text-base font-semibold leading-7 text-gray-900 w-full sm:w-1/2 p-2 rounded-md mb-2">Normfunctie en CAO</div>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Is er een functie in uw organisatie die lijkt op die van de werknemer?
                                </label>
                                <span className="block text-sm font-medium text-gray-700 mb-2">
                                    De functie moet worden uitgevoerd door een regulier betaalde werknemer.
                                    Mensen die werken via de Participatiewet, Wajong of WSW zijn geen regulier betaalde werknemers.
                                </span>
                                <select
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2">
                                    <option value="" disabled selected>
                                        Selecteer een optie
                                    </option>
                                    <option value="ja">
                                        Ja
                                    </option>
                                    <option value="nee">
                                        Nee
                                    </option>
                                </select>

                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Wat is de naam van deze functie?
                                </label>
                                <input type="text" value="NaamFunctie" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2"
                                    readOnly />

                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Is het takenpakket van de regulier betaalde werknemer exact hetzelfde als die van de werknemer?
                                </label>
                                <select
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6 mb-2">
                                    <option value="" disabled selected>
                                        Selecteer een optie
                                    </option>
                                    <option value="ja">
                                        Ja
                                    </option>
                                    <option value="nee">
                                        Nee
                                    </option>
                                </select>

                            </div>
                        </div>


                        <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6"></div>
                        <div className="text-base font-semibold leading-7 text-gray-900 w-full sm:w-1/2 p-2 rounded-md mb-2">Aanvullingen / opmerkingen</div>

                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            Heeft u aanvullende opmerkingen?
                        </label>
                        <textarea
                            rows={4}
                            placeholder="Opmerking"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />

                        <br></br>

                        <button type="submit" className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
                            Verstuur
                        </button>

                    </div>
                </div>
            </div>
            {/* <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                    <div className="sm:col-span-3"> */}

            {/* </div>


            </div> */}


        </Layout >
    )
}

