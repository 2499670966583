import { DocumentReference } from "firebase/firestore"
import { getLopendeTrajectenByWerkvloerbegeleider, getTotalCountOfTalentsByWerkvloerbegeleider } from "../datasource/lopend-traject-utils"
import { getTalentDocumentById } from "../datasource/talent-utils"
import { TalentModel } from "./talent-utils"
import getAllWerkvloerbegeleider, { getWerkvloerbegeleiderById as _getWerkvloerbegeleiderById } from "../datasource/werkvloerbegeleider-utils"
import { UserModel } from "./user-utils"
import { getBedrijfDocumentByReference } from "../datasource/bedrijf-utils"

export type WerkvloerbegeleiderModel = {
    reference: DocumentReference,
    achternaam: string,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    telefoon_nummer: string,
    voornaam: string,
    talenten?: TalentModel[],
    werkbedrijf?: {
        locatie: string
    }
}

const getWerkvloerbegeleiders = async (user: UserModel) => {
    const werkvloerbegeleiders = await getAllWerkvloerbegeleider(50, user?.data_eigenaar_ref!)

    const list: WerkvloerbegeleiderModel[] = []

    werkvloerbegeleiders.forEach((werkvloerbegeleider) => {
        list.push({
            reference: werkvloerbegeleider.reference,
            achternaam: werkvloerbegeleider.achternaam,
            email: werkvloerbegeleider.email,
            gebruikers_rol: werkvloerbegeleider.gebruikers_rol,
            geslacht: werkvloerbegeleider.geslacht,
            profiel_foto: werkvloerbegeleider.profiel_foto,
            telefoon_nummer: werkvloerbegeleider.telefoon_nummer,
            voornaam: werkvloerbegeleider.voornaam,
        })
    })

    return list

}

export default getWerkvloerbegeleiders

export const getWerkvloerbegeleiderById = async (id: string, user: UserModel, options?: { werkbedrijf: boolean, talenten: boolean }) => {
    const werkvloerbegeleider = await _getWerkvloerbegeleiderById(id)

    if (!werkvloerbegeleider) {
        return Promise.reject("Geen werkbegeleider gevonden.")
    }

    let model: WerkvloerbegeleiderModel = {
        reference: werkvloerbegeleider.reference,
        achternaam: werkvloerbegeleider.achternaam,
        email: werkvloerbegeleider.email,
        gebruikers_rol: werkvloerbegeleider.gebruikers_rol,
        geslacht: werkvloerbegeleider.geslacht,
        profiel_foto: werkvloerbegeleider.profiel_foto,
        telefoon_nummer: werkvloerbegeleider.telefoon_nummer,
        voornaam: werkvloerbegeleider.voornaam,
    }

    if (options?.werkbedrijf) {
        const werkbedrijf = await getBedrijfDocumentByReference(werkvloerbegeleider.werkbedrijf_reference.id)
        model.werkbedrijf = {
            locatie: `${werkbedrijf?.plaats}`
        }
    }

    if (options?.talenten) {
        const lopendeTrajecten = await getLopendeTrajectenByWerkvloerbegeleider(werkvloerbegeleider.reference, user?.data_eigenaar_ref!)

        let talenten: TalentModel[] = []

        for (const lopendTraject of lopendeTrajecten) {
            if (lopendTraject.talent_ref) {
                let talent = await getTalentDocumentById(lopendTraject.talent_ref)

                if (talent)
                    talenten.push({
                        id: talent.reference.id,
                        ref: talent.reference,
                        gemeente: talent.talent?.gemeente,
                        huisnummer: talent.talent?.huisnummer,
                        nationaliteit: talent.talent?.nationaliteit,
                        plaats: talent.talent?.plaats,
                        postcode: talent.talent?.postcode,
                        straat: talent.talent?.straat,
                        telefoonnummer: talent.telefoon_nummer,
                        voornaam: talent.voornaam,
                        achternaam: talent.achternaam,
                        volledige_naam: talent.voornaam + " " + talent.achternaam,
                        foto: talent.profiel_foto,
                        email: talent.email,
                        rol: talent.gebruikers_rol,

                    })
            }
        }

        model.talenten = talenten
    }

    return model

}

export const getTotalTalentsByWerkvloerbegeleiders = async (werkvloerbegeleiders: WerkvloerbegeleiderModel[], user: UserModel) => {
    const total = await getTotalCountOfTalentsByWerkvloerbegeleider(werkvloerbegeleiders.map((werkvloerbegeleider) => { return werkvloerbegeleider.reference }), user?.data_eigenaar_ref!)
    return total
}