import {getApps, initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'
import {getStorage} from 'firebase/storage'
import { getFunctions } from "firebase/functions";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
}

var app

if (getApps().length === 0)
    app = initializeApp(firebaseConfig)

export const db = getFirestore()
export const auth = getAuth()
export const storage = getStorage()
export const vertexAI = getVertexAI()
export const functions = getFunctions(app, "europe-west4")

  