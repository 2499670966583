import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';


export interface Taak {
    naam: string;
    grootte: string;
    beschrijving: string;
}

interface TaakManger {
    voegTaakToe: (task: Taak) => void;
    deleteTaak: (ind: number) => void;
    taken: Taak[];
}

export const TaakManger: React.FC<TaakManger> = ({ voegTaakToe, deleteTaak, taken }) => {
    const [naam, setNaam] = useState('');
    const [grootte, setGrootte] = useState('');
    const [beschrijving, setBeschrijving] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [bijwerken, setBijwerken] = useState<number | null>(null);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();


        if (taken.length >= 5) {
            alert('Maximaal 5 taken zijn toegestaan');
            return;
        }

        const nieuweTaak = { naam, grootte, beschrijving };

        if (bijwerken !== null) {
            const updateTaak = [...taken];
            updateTaak[bijwerken] = nieuweTaak;
        } else {
            voegTaakToe(nieuweTaak);
        }


        setNaam('');
        setGrootte('');
        setBeschrijving('');
        setShowForm(false);
        setBijwerken(null);
    };


    const handleCancel = () => {
        setNaam('');
        setGrootte('');
        setBeschrijving('');
        setShowForm(false);
        setBijwerken(null);
    };


    const handleDelete = (index: number) => {
        deleteTaak(index)
    };

    const handleEdit = (index: number) => {
        const taakOmTeVeranderen = taken[index];
        setNaam(taakOmTeVeranderen.naam);
        setGrootte(taakOmTeVeranderen.grootte);
        setBeschrijving(taakOmTeVeranderen.beschrijving);
        setBijwerken(index);
        setShowForm(true);
    };


    return (
        <div className="overflow-x-auto">
            {!showForm && (
                <table className="min-w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100 border-b border-gray-300">
                            <th className="p-2 text-left border-r border-gray-300">Naam</th>
                            <th className="p-2 text-left border-r border-gray-300">Grootte</th>
                            <th className="p-2 text-left">Beschrijving van de taak</th>
                            <th className="p-2"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {taken.map((taak, index) => (
                            <tr key={index} className="border-b border-gray-300">
                                <td className="p-2">{taak.naam}</td>
                                <td className="p-2">{taak.grootte}</td>
                                <td className="p-2">{taak.beschrijving}</td>
                                <td className="p-2 flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:space-x-2">
                                    <button
                                        onClick={() => handleEdit(index)}
                                        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 text-sm"
                                    >
                                        Bewerken
                                    </button>
                                    <button
                                        onClick={() => handleDelete(index)}
                                        className="bg-red-500 text-white p-2 rounded hover:bg-red-600 text-sm"
                                    >
                                        Verwijderen
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={4} className="p-2 text-center">
                                <button
                                    type="button"
                                    onClick={() => setShowForm(true)}
                                    className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 flex items-center justify-center space-x-2"
                                >
                                    <PlusIcon aria-hidden="true" className="h-6 w-6 text-gray-400" />
                                    <span>Taak toevoegen</span>
                                </button>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            )}

            {showForm && (
                <form
                    onSubmit={handleSubmit}
                    className="mb-4 p-4 border border-gray-300 rounded-md shadow sm:w-full sm:max-w-md mx-auto"
                >
                    <div className="mb-2">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Naam</label>
                        <input
                            type="text"
                            value={naam}
                            onChange={(e) => setNaam(e.target.value)}
                            placeholder="Vul de naam van de taak in"
                            className="w-full border border-gray-300 p-2 rounded"
                            required
                        />
                    </div>
                    <div className="mb-2">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Grootte</label>
                        <select
                            value={grootte}
                            onChange={(e) => setGrootte(e.target.value)}
                            className="w-full border border-gray-300 p-2 rounded"
                            required
                        >
                            <option value="">Kies de omvang van de taak</option>
                            <option value="klein">Klein</option>
                            <option value="gemiddeld">Gemiddeld</option>
                            <option value="groot">Groot</option>
                        </select>
                    </div>
                    <div className="mb-2">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Beschrijving</label>
                        <textarea
                            value={beschrijving}
                            onChange={(e) => setBeschrijving(e.target.value)}
                            placeholder="Beschrijving van de taak"
                            className="w-full border border-gray-300 p-2 rounded"
                            required
                        />
                    </div>
                    <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
                        <button type="submit" className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
                            Opslaan
                        </button>
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="bg-gray-500 text-white p-2 rounded hover:bg-gray-600"
                        >
                            Annuleren
                        </button>
                    </div>
                </form>
            )}
        </div>

    );
};

export default TaakManger;
