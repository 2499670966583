import { Navigate, redirect, useLocation } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react"
import Tabbar from "././components/tabbar/main/Tabbar";
import Sidebar from "./components/sidebar/Sidebar";
import { useState } from "react";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/outline";
import ReactGA from "react-ga4";

ReactGA.initialize("G-4VDSFNWWLT");

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  // Bepaal dynamisch de titel
  const getPageTitle = () => {
    switch (location.pathname) {
      case "/":
        return "Dashboard";
      case "/rooster":
        return "Rooster";
      case "/talentenhub":
        return "Talentenhub";
      case "/bijenkorf":
        return "Bijenkorf";
      default:
        return "Bloeiindex"; // Default titel
    }
  };

  return (
    <>
      <div className="overflow-auto h-screen">
        <Analytics />
        {/* <Navbar /> */}
        <Sidebar />

        {/* Hidden till mobile */}
        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-slate-50 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            onClick={() => setSidebarOpen(true)}
            className="-m-2.5 p-2.5 text-blue-700 lg:hidden"
          >
            <span className="sr-only">Open sidebar</span>
            <MagnifyingGlassCircleIcon aria-hidden="true" className="size-8" />
          </button>
          {/* Dynamische titel */}
          <h1 className="flex-1 text-base text-center font-semibold leading-7 text-gray-900">
            {getPageTitle()}
          </h1>
          <a href="/beheer">
            <span className="sr-only">Your profile</span>
            <img
              alt=""
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              className="size-8 rounded-full bg-indigo-700"
            />
          </a>
        </div>


        <main className="lg:pl-72 h-[calc(100vh-65px)] pb-6">{children}</main>


        <Tabbar />
      </div>
    </>
  )
}

export default Layout