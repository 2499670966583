import { ChangeEvent, createContext, useContext, useEffect, useRef, useState } from "react";
import Layout from "../../../layout";
import { readData } from "../../../utils/excel-importer";
import { NewUserModel } from "../../../domain/user-utils";
import { useAuth } from "../../../context/AuthContext";
import TrajectSelection from "../../../components/dropdown/traject/App";
import { getAllTrajecten, NewTrajectModel, TrajectModel } from "../../../domain/traject-utils";
import { BedrijfSelection } from "../../../components/dropdown/bedrijf/App";
import { BedrijfModel, getAllBedrijven, LicentienemerModel, NewBedrijfModel } from "../../../domain/bedrijf-utils";
import dayjs from "dayjs";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { createNewLicentienemerWithPredefinedData, NewLicentienemerModel } from "../../../domain/licentienemer-utils";

const TALENT_TABLE_HEADERS = [
    "Voornaam",
    "Achternaam",
    "Email",
    "Gebruikers Rol",
    "Geslacht",
    "Profiel Foto",
    "Telefoon Nummer",
    "Gemeente",
    "Huisnummer",
    "Nationaliteit",
    "Plaats",
    "Postcode",
    "Straat",
    "Geboorte Datum"
]

const LICENTIENEMER_TABLE_HEADERS = [
    "Naam",
    "Email",
    "Logo",
    "Straat",
    "Nummer",
    "Postcode",
    "Plaats",
    "Telefoonnummer",
    "Contact Persoon",
    "Titel1",
    "Beschrijving1",
    "Titel2",
    "Beschrijving2",
    "Feature1",
    "Feature description1",
    "Feature2",
    "Feature description2",
    "Feature3",
    "Feature description3"
]

const USER_TABLE_HEADERS = [
    "Voornaam",
    "Achternaam",
    "Email",
    "Geslacht",
    "Profiel Foto",
    "Telefoon Nummer",
]

const SMALL_USER_TABLE_HEADERS = [
    "Email"
]

const WERKBEDRIJF_TABLE_HEADERS = [
    "Naam",
    "Logo",
    "Straat",
    "Nummer",
    "Postcode",
    "Plaats",
    "Locatie",
    "Werkvloerbegeleider"
]

const TRAJECT_TABLE_HEADERS = [
    "Naam",
    "Aantal rapportages",
    "Consulent",
    "Duur",
    "Evaluatie momenten",
    "Gemeente",
    "Jobcoach interventies",
    "Loonwaardemeting",
    "Praktijkverklaring",
    "Talentportfolio",
    "Omschrijving"
]

type User = {
    voornaam: string,
    achternaam: string,
    email: string,
    geslacht: "man" | "vrouw" | "anders",
    profiel_foto: string,
    telefoon_nummer: string
}

export type ImportObject = {
    licentienemer?: {
        naam: string,
        email: string,
        logo: string,
        straat: string,
        nummer: string,
        postcode: string,
        plaats: string,
        telefoonnummer: string,
        contact_persoon: User,
        titel1: string,
        beschrijving1: string,
        titel2: string,
        beschrijving2: string,
        feature1: string,
        feature_description1: string,
        feature2: string,
        feature_description2: string,
        feature3: string,
        feature_description3: string
    },
    werkvloerbegeleiders?: User[],
    consulenten?: User[],
    werkbedrijven?: {
        locatie: string,
        naam: string,
        logo: string,
        straat: string,
        nummer: string,
        plaats: string,
        postcode: string,
        werkvloerbegeleider: {email: string}
    }[],
    trajecten?: {
        naam: string,
        aantal_rapportages: number,
        consulent: {email: string},
        duur: number,
        evaluatie_momenten: number,
        gemeente: string,
        jobcoach_interventies: number,
        loonwaardemeting: boolean,
        praktijkverklaring: boolean,
        talentportfolio: boolean,
        omschrijving: string
    }[],
    talenten?: NewUserModel[]
}

interface ImportContext {
  newImport: ImportObject,
  setImport: (model: ImportObject) => void
}

const ImportContext = createContext<ImportContext>({
    newImport: {},
    setImport: () => {}
});

export default function Importer() {

    const { user } = useAuth()

    const trajectRefs = useRef<HTMLInputElement[]>([])
    const bedrijfRefs = useRef<HTMLInputElement[]>([])

    const [newUsers, setNewUsers] = useState<NewUserModel[]>([])
    const [trajecten, setTrajecten] = useState<TrajectModel[]>([])
    const [bedrijven, setBedrijven] = useState<BedrijfModel[]>([])

    const [newImportObject, setNewImportObjects] = useState<ImportObject>({})

    useEffect(() => {
        if (user) {
            getAllTrajecten(user).then((trajecten) => setTrajecten(trajecten))
            getAllBedrijven(user).then((bedrijven) => setBedrijven(bedrijven))
        }
    }, [])

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const reader = new FileReader()

            reader.onload = (e) => {
                setNewUsers(readData(e.target?.result))
                setNewImportObjects({...newImportObject, talenten: readData(e.target?.result)})
            }

            reader.readAsArrayBuffer(e.target.files[0])
        }
    };

    const setBeginDatum = (val, i) => {
        newUsers![i].lopend_traject = { ...newUsers![i].lopend_traject, start_datum: dayjs(val).toDate() }
        setNewImportObjects({...newImportObject, talenten: newUsers})
    }

    const setValue = (key, innerKey, val, i) => {
        newUsers![i][key] = { ...newUsers![i][key], [innerKey]: val }
        setNewImportObjects({...newImportObject, talenten: newUsers})
    }

    const save = () => {

        // newUsers.forEach((user, i) => {
        //     if (user.talent) {
        //         let traject = trajecten.find((t) => t.reference?.id == trajectRefs.current[i]!.value)
        //         newUsers[i].traject = {
        //             reference: traject?.reference!,
        //             consulent_reference: traject?.traject_consulent_reference!
        //         }
        //         let bedrijf = bedrijven.find((b) => b.reference.id == bedrijfRefs.current[i]!.value)
        //         newUsers[i].bedrijf = {
        //             reference: bedrijf?.reference!
        //         }
        //     }
        // })

        createNewLicentienemerWithPredefinedData(newImportObject).then(() => {})
        // console.log(newUsers)
        // if (user && newUsers)
        //     addUsers(user, newUsers)
    }

    useEffect(() => {
        console.log(newImportObject)
    }, [newImportObject])

    // als talent tonen extra selecteer velden traject / startdatum 

    return <Layout>
    <ImportContext.Provider
      value={{ newImport: newImportObject, setImport: setNewImportObjects }}
    >
      <div className="space-y-6"> {/* Grouping sections with consistent spacing */}
        <section className="p-4 bg-gray-100 rounded-lg shadow-sm">
          <h2 className="text-lg font-semibold text-gray-700">Licentienemer</h2>
          <Licentienemer />
        </section>
        
        <section className="p-4 bg-gray-100 rounded-lg shadow-sm">
          <h2 className="text-lg font-semibold text-gray-700">Consulenten</h2>
          <User userGroup="consulenten" />
        </section>
        
        <section className="p-4 bg-gray-100 rounded-lg shadow-sm">
          <h2 className="text-lg font-semibold text-gray-700">Werkvloerbegeleiders</h2>
          <User userGroup="werkvloerbegeleiders" />
        </section>
        
        <section className="p-4 bg-gray-100 rounded-lg shadow-sm">
          <h2 className="text-lg font-semibold text-gray-700">Traject</h2>
          <Traject />
        </section>
        
        <section className="p-4 bg-gray-100 rounded-lg shadow-sm">
          <h2 className="text-lg font-semibold text-gray-700">Werkbedrijf</h2>
          <Werkbedrijf />
        </section>
      </div>
    </ImportContext.Provider>
  
    <input
      type="file"
      onChange={handleFileChange}
      className="mt-4 w-full px-4 py-2 border rounded-md shadow-sm"
    />
  
    {newUsers && (
      <div className="mt-6">
        <table className="table-auto w-full border-collapse border border-gray-300">
          <thead className="bg-gray-100">
            <tr>
              {TALENT_TABLE_HEADERS.map((header) => (
                <th
                  key={header}
                  className="px-4 py-2 border border-gray-300 text-left text-sm font-medium text-gray-600"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {newUsers.map((user, i) => (
              <tr key={i} className="hover:bg-gray-50">
                <td className="px-4 py-2 border border-gray-300">{user.voornaam}</td>
                <td className="px-4 py-2 border border-gray-300">{user.achternaam}</td>
                <td className="px-4 py-2 border border-gray-300">{user.email}</td>
                <td className="px-4 py-2 border border-gray-300">{user.gebruikers_rol}</td>
                <td className="px-4 py-2 border border-gray-300">{user.geslacht}</td>
                <td className="px-4 py-2 border border-gray-300">
                  <img
                    src={user.profiel_foto}
                    alt="Profiel Foto"
                    className="h-10 w-10 object-cover rounded-full"
                  />
                </td>
                <td className="px-4 py-2 border border-gray-300">{user.telefoon_nummer}</td>
                <td className="px-4 py-2 border border-gray-300">{user.talent?.gemeente}</td>
                <td className="px-4 py-2 border border-gray-300">{user.talent?.huisnummer}</td>
                <td className="px-4 py-2 border border-gray-300">{user.talent?.nationaliteit}</td>
                <td className="px-4 py-2 border border-gray-300">{user.talent?.plaats}</td>
                <td className="px-4 py-2 border border-gray-300">{user.talent?.postcode}</td>
                <td className="px-4 py-2 border border-gray-300">{user.talent?.straat}</td>
                <td className="px-4 py-2 border border-gray-300">
                  {user.talent?.geboorte_datum.toLocaleDateString()}
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  <select
                    onChange={(e) => setValue("traject", "naam", e.target.value, i)}
                    className="border rounded p-1 text-sm"
                  >
                    <option value="" disabled selected>
                      Selecteer een traject
                    </option>
                    {newImportObject.trajecten?.map((traject) => (
                      <option key={traject.naam} value={traject.naam}>
                        {traject.naam}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  <input
                    type="date"
                    className="border rounded p-1 text-sm"
                    onChange={(e) => setBeginDatum(e.target.value, i)}
                  />
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  <input
                    type="text"
                    placeholder="Werkdagen"
                    className="border rounded p-1 text-sm"
                    onChange={(e) => setValue("lopend_traject", "werkdagen", e.target.value, i)}
                  />
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  <select
                    onChange={(e) => setValue("lopend_traject", "status", e.target.value, i)}
                    className="border rounded p-1 text-sm"
                  >
                    <option value="" disabled selected>
                      Select status
                    </option>
                    <option value="Niet gestart">Niet gestart</option>
                    <option value="Lopend">Lopend</option>
                    <option value="Beëindigd">Beëindigd</option>
                  </select>
                </td>
                <td className="px-4 py-2 border border-gray-300">
                  <select
                    onChange={(e) => setValue("bedrijf", "naam", e.target.value, i)}
                    className="border rounded p-1 text-sm"
                  >
                    <option value="" disabled selected>
                      Selecteer een bedrijf
                    </option>
                    {newImportObject.werkbedrijven?.map((bedrijf) => (
                      <option key={bedrijf.naam} value={bedrijf.naam}>
                        {bedrijf.naam}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          onClick={save}
          className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded"
        >
          Save all
        </button>
      </div>
    )}
  </Layout>
}

function Licentienemer() {

    const {newImport, setImport} = useContext(ImportContext)

    type Inputs = {
        naam: string,
        email: string,
        logo: string,
        straat: string,
        nummer: string,
        postcode: string,
        plaats: string,
        telefoonnummer: string,
        contact_persoon: User,
        titel1: string,
        beschrijving1: string,
        titel2: string,
        beschrijving2: string,
        feature1: string,
        feature_description1: string,
        feature2: string,
        feature_description2: string,
        feature3: string,
        feature_description3: string
    }

    const {
        register,
        handleSubmit,
    } = useForm<Inputs>()
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        setImport({...newImport, licentienemer: data})
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                LICENTIENEMER_TABLE_HEADERS.map((val) =>
                    <>
                        {val.replace(" ", "_").toLowerCase() == "contact_persoon" ? USER_TABLE_HEADERS.map((val1) => <>{`${val} ${val1}`} <input {...register(`${val.replace(" ", "_").toLowerCase()}.${val1.replace(" ", "_").toLowerCase()}` as any, {required: true})}/><br/></>) : <>{val} <input {...register(val.replace(" ", "_").toLowerCase() as any, {required: true})}/></>} <br/>
                    </>
                )
            }
            <button type="submit">Submit</button>
        </form>
    )
}

function User({userGroup}) {

    const {newImport, setImport} = useContext(ImportContext)

    type Inputs = {
        users: {
            voornaam: string,
            achternaam: string,
            email: string,
            geslacht: string,
            profiel_foto: string,
            telefoon_nummer: string,
        }[]
    }

    const {
        register,
        handleSubmit,
        control
    } = useForm<Inputs>()
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        setImport({...newImport, [userGroup]: data.users})
    }

    const { fields, append, remove } = useFieldArray({
        name: "users",
        control
        });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                fields.map((field, i) => {
                    return <>
                    {USER_TABLE_HEADERS.map((val) =>
                        <>
                            <>{val} <input {...register(`users.${i}.${val.replace(" ", "_").toLowerCase()}` as any, {required: true})}/></><br/>
                        </>
                    )}
                    <button onClick={() => remove(i)}>Delete row</button><br/>
                    </>
                    
                })
            }
            <button onClick={() => append({voornaam: "", achternaam: "", email: "", geslacht: "", profiel_foto: "", telefoon_nummer: ""})}>Add row</button><br/>
            <button type="submit">Submit</button>
        </form>
    )
}

function Traject() {

    const {newImport, setImport} = useContext(ImportContext)

    type Inputs = {
        trajecten: {
            naam: string,
            aantal_rapportages: number,
            consulent: {email: string},
            duur: number,
            evaluatie_momenten: number,
            gemeente: string,
            jobcoach_interventies: number,
            loonwaardemeting: boolean,
            praktijkverklaring: boolean,
            talentportfolio: boolean,
            omschrijving: string
        }[]
    }

    const {
        register,
        handleSubmit,
        control
    } = useForm<Inputs>()
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        setImport({...newImport, trajecten: data.trajecten})
    }

    const { fields, append, remove } = useFieldArray({
        name: "trajecten",
        control
        });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                fields.map((field, i) => {
                    return <>
                    {TRAJECT_TABLE_HEADERS.map((val) =>
                        <>
        {val.replace(" ", "_").toLowerCase() == "consulent" ? SMALL_USER_TABLE_HEADERS.map((val1) => <>{`${val} ${val1}`} <input {...register(`trajecten.${i}.${val.replace(" ", "_").toLowerCase()}.${val1.replace(" ", "_").toLowerCase()}` as any, {required: true})}/><br/></>) : <>{val} <input {...register(`trajecten.${i}.${val}`.replace(" ", "_").toLowerCase() as any, (val.replace(" ", "_").toLowerCase() == "loonwaardemeting" || val.replace(" ", "_").toLowerCase() == "praktijkverklaring" || val.replace(" ", "_").toLowerCase() == "talentportfolio") ? {setValueAs: (v) => (v == "true") } : (val.replace(" ", "_").toLowerCase() == "aantal_rapportages" || val.replace(" ", "_").toLowerCase() == "evaluatie_momenten" || val.replace(" ", "_").toLowerCase() == "jobcoach_interventies" || val.replace(" ", "_").toLowerCase() == "duur") ? {required: true, valueAsNumber: true}: {required: true})}/></>} <br/>
        </>
                    )}
                    <button onClick={() => remove(i)}>Delete row</button><br/>
                    </>
                    
                })
            }
            <button onClick={() => append({omschrijving: "", aantal_rapportages: 0, consulent: {email: ""}, duur: 0, evaluatie_momenten: 0, gemeente: "", jobcoach_interventies: 0, loonwaardemeting: false, naam: "", praktijkverklaring: false, talentportfolio: false})}>Add row</button><br/>
            <button type="submit">Submit</button>
        </form>
    )
}

function Werkbedrijf() {

    const {newImport, setImport} = useContext(ImportContext)

    type Inputs = {
        werkbedrijven: {
            locatie: string,
            naam: string,
            logo: string,
            straat: string,
            nummer: string,
            plaats: string,
            postcode: string,
            werkvloerbegeleider: {email: string}
        }[]
    }

    const {
        register,
        handleSubmit,
        control
    } = useForm<Inputs>()
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        setImport({...newImport, werkbedrijven: data.werkbedrijven})
    }

    const { fields, append, remove } = useFieldArray({
        name: "werkbedrijven",
        control
        });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                fields.map((field, i) => {
                    return <>
                    {WERKBEDRIJF_TABLE_HEADERS.map((val) =>
                        <>
        {val.replace(" ", "_").toLowerCase() == "werkvloerbegeleider" ? SMALL_USER_TABLE_HEADERS.map((val1) => <>{`${val} ${val1}`} <input {...register(`werkbedrijven.${i}.${val.replace(" ", "_").toLowerCase()}.${val1.replace(" ", "_").toLowerCase()}` as any, {required: true})}/><br/></>) : <>{val} <input {...register(`werkbedrijven.${i}.${val}`.replace(" ", "_").toLowerCase() as any, {required: true})}/></>} <br/>
        </>
                    )}
                    <button onClick={() => remove(i)}>Delete row</button><br/>
                    </>
                    
                })
            }
            <button onClick={() => append({locatie: "", logo: "", naam: "", nummer: "", plaats: "", postcode: "", straat: "", werkvloerbegeleider: {email: ""}})}>Add row</button><br/>
            <button type="submit">Submit</button>
        </form>
    )
}