import { ChatBubbleLeftEllipsisIcon, PencilIcon, CheckIcon } from '@heroicons/react/24/outline';
import Layout from '../layoutmobile';
import Tabbarmobile from '../../../components/tabbar/folder/Tabbarmobile';
import Breadcrumbs from '../../../components/breadcrumb/breadcrumbs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createEvaluatie, EvaluatieModel, getEvaluatiesByTalentId, NewEvaluatieModel } from '../../../domain/evaluatie-utils';
import { useAuth } from '../../../context/AuthContext';
import { getTalentById, TalentModel } from '../../../domain/talent-utils';

interface Update {
  id: number;
  content: string;
  date: string;
  rating?: string;
  moodLevel?: string;
  isEditing?: boolean;
  author: {
    name: string;
    profilePicture: string;
  };
}

const moodEmojiMap = [
  '😡', // 1
  '😟', // 2
  '😔', // 3
  '😞', // 4
  '😐', // 5
  '🙂', // 6
  '😊', // 7
  '😃', // 8
  '😁', // 9
  '😍', // 10
];

export default function Example() {

  const { talentId } = useParams()

  const { user } = useAuth()

  const [updates, setUpdates] = useState<Update[]>([]);
  const [evaluaties, setEvaluaties] = useState<EvaluatieModel[]>([]);
  const [newUpdate, setNewUpdate] = useState<string>('');
  const [rating, setRating] = useState<number>(10);
  const [moodLevel, setMoodLevel] = useState<number>(5);

  const [talent, setTalent] = useState<TalentModel>()

  const handleUpdateSubmit = () => {
    if (!newUpdate.trim()) return;

    const newEntry: NewEvaluatieModel = {
      content: newUpdate,
      auteurId: user!.ref.id,
      talentId: talentId!,
      cijfer: rating,
      gevoel: moodLevel
    };

    createEvaluatie(user!, newEntry).then((res) => {
      window.location.reload()
    })
  };

  const handleEditToggle = (id: number) => {
    setUpdates((prev) =>
      prev.map((update) =>
        update.id === id ? { ...update, isEditing: !update.isEditing } : update
      )
    );
  };

  const handleContentChange = (id: number, newContent: string) => {
    setUpdates((prev) =>
      prev.map((update) =>
        update.id === id ? { ...update, content: newContent } : update
      )
    );
  };

  useEffect(() => {
    getEvaluatiesByTalentId(talentId ?? "", user!).then((evaluaties) => setEvaluaties(evaluaties))
    getTalentById(talentId).then((talent) => {
      setTalent(talent)
    });
  }, [])

  return (
    <>
      <Layout>
        <Breadcrumbs personName={talent?.volledige_naam ?? ""} />
        <div className="min-h-full pb-36">
          {/* Inputveld voor nieuwe update */}
          <div className="bg-sky-400 rounded-lg p-6 mt-6 mx-4 shadow-2xl">
            <h2 className="text-lg font-semibold text-white">Nieuwe Update</h2>
            <textarea
              value={newUpdate}
              onChange={(e) => setNewUpdate(e.target.value)}
              rows={5}
              placeholder="Schrijf een update over het Talent"
              className="block w-full bg-sky-400 rounded-md border-sky-300 mt-2 text-white sm:text-sm placeholder-white/50"
            />
            <div className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2">
              {/* Slider voor cijfer */}
              <div>
                <label htmlFor="rating" className="block text-sm font-medium text-white">
                  Cijfer voor vandaag
                </label>
                <div className="flex items-center space-x-4 mt-2">
                  <input
                    id="rating"
                    type="range"
                    min="1"
                    max="10"
                    value={rating}
                    onChange={(e) => setRating(Number(e.target.value))}
                    className="w-full accent-sky-600"
                  />
                  <span className="w-8 text-center text-2xl font-semibold text-white">
                    {rating}
                  </span>
                </div>
              </div>

              {/* Slider voor gevoel */}
              <div>
                <label htmlFor="mood" className="block text-sm font-medium text-white">
                  Hoe voelt het Talent zich?
                </label>
                <div className="flex items-center space-x-4 mt-2">
                  <input
                    id="mood"
                    type="range"
                    min="1"
                    max="10"
                    value={moodLevel}
                    onChange={(e) => setMoodLevel(Number(e.target.value))}
                    className="w-full accent-sky-600"
                  />
                  <span className="w-8 text-center text-4xl font-semibold text-white">
                    {moodEmojiMap[moodLevel - 1]}
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <button
                onClick={handleUpdateSubmit}
                className="w-full inline-flex justify-center items-center rounded-md border border-white px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Opslaan
              </button>
            </div>
          </div>

          {/* Tijdlijn met updates */}
          <div className="mt-8 px-6">
            <div className="px-4 sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Traject updates</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Alle updates van de begeleiders</p>
            </div>
            <ul role="list" className="-mb-8 mt-6">
              {evaluaties.map((evaluatie, id) => (
                <li key={id}>
                  <div className="relative pb-8">
                    {id !== evaluaties.length - 1 ? (
                      <span
                        aria-hidden="true"
                        className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
                      />
                    ) : null}
                    <div className="relative flex items-start space-x-3">
                      <div className="relative">
                        <img
                          alt=""
                          src={evaluatie.auteur.profiel_foto}
                          className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                        />
                        <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                          <ChatBubbleLeftEllipsisIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                          />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1 bg-slate-50 p-4 rounded-lg relative">
                        <div className="absolute top-2 right-2">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => handleEditToggle(id)}
                          >
                            {/* {update.isEditing ? (
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            ) : (
                              <PencilIcon className="h-5 w-5" aria-hidden="true" />
                            )} */}
                            {/* <span className="sr-only">{update.isEditing ? 'Opslaan' : 'Bewerk'}</span> */}
                          </button>
                        </div>
                        <div>
                          <div className="text-sm">
                            <a href="#" className="font-medium text-gray-900">
                              {evaluatie.auteur.naam}
                            </a>
                          </div>
                          <p className="mt-0.5 text-sm text-gray-500">Geschreven op {evaluatie.aanmaak_datum.toLocaleDateString()}</p>
                        </div>
                        <div className="mt-2 text-sm text-gray-700">
                          {/* {update.isEditing ? (
                            <textarea
                              className="block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                              value={update.content}
                              onChange={(e) =>
                                handleContentChange(update.id, e.target.value)
                              }
                            />
                          ) : (
                            <p>{update.content}</p>
                          )} */}
                          <p>{evaluatie.content}</p>
                        </div>
                        {/* <div className="mt-2 flex items-center justify-between text-sm text-gray-500">
                          <p>
                            <strong>Cijfer:</strong> {update.rating}
                          </p>
                          <p>
                            <strong>Gevoel:</strong> {update.moodLevel}
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Tabbarmobile talentId={talentId} />
      </Layout>
    </>
  );
}