import { read, utils } from "xlsx";
import { NewUserModel } from '../domain/user-utils'
import dayjs from "dayjs";

export const readData = (data: any) => {
    const wb = read(data, {type: 'array'})
    const ws = wb.Sheets[wb.SheetNames[0]]
    const excelData = utils.sheet_to_json(ws, {defval: undefined})
    return convertToUserModel(excelData)
}

function convertToUserModel(data: any[]) {
    const list: NewUserModel[] = []
    for (let user of data) {
        list.push({
            voornaam: user.voornaam,
            achternaam: user.achternaam,
            email: user.email,
            gebruikers_rol: "talent",
            geslacht: user.geslacht,
            telefoon_nummer: user.telefoon_nummer.toString(),
            profiel_foto: user.profiel_foto,
            talent: {
                geboorte_datum: dayjs(user.geboorte_datum).toDate(),
                gemeente: user.gemeente,
                huisnummer: user.huisnummer,
                nationaliteit: user.nationaliteit,
                plaats: user.plaats,
                postcode: user.postcode,
                straat: user.straat
            }
        })
    }

    return list
}