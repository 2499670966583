import { Listbox, ListboxButton, ListboxOptions, ListboxOption, Menu, MenuButton, MenuItems, MenuItem, Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { ChevronUpDownIcon, CheckIcon, HashtagIcon, EllipsisVerticalIcon, CalendarIcon, DocumentDuplicateIcon, HomeIcon, PlusCircleIcon, UsersIcon, UserGroupIcon, ChevronDownIcon, BookOpenIcon, BellAlertIcon, ChevronRightIcon, AcademicCapIcon, CheckBadgeIcon, ClockIcon, NewspaperIcon, PencilSquareIcon, LockClosedIcon } from "@heroicons/react/20/solid";
import { LockOpenIcon, MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/outline";
import { query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { searchByTerm, UserModel } from "../../domain/user-utils";
import { useAuth } from "../../context/AuthContext";
import DefaultImage from "../defaultimage/defaultimage";

const actions = [
    {
        title: 'Nieuwe aanmelding',
        href: '/talentenhub/aanmeldingformulier',
        icon: ClockIcon,
        iconForeground: 'text-teal-700',
        iconBackground: 'bg-teal-50',
        desc: 'Registreer een nieuw talent en leg basisinformatie vast voor een persoonlijk begeleidingstraject.',
    },
    {
        title: 'Intake starten',
        href: '/talentenhub/intakeformulier',
        icon: NewspaperIcon,
        iconForeground: 'text-yellow-700',
        iconBackground: 'bg-yellow-50',
        desc: 'Start het intakeproces om doelen, uitdagingen en ambities te bespreken.',
    },
    {
        title: 'Nieuwe rapportage',
        href: '/talentenhub/rapportageformulier',
        icon: CheckBadgeIcon,
        iconForeground: 'text-purple-700',
        iconBackground: 'bg-purple-50',
        desc: 'Creëer een voortgangsrapport voor evaluatie en het delen van updates.',
    },
    {
        title: 'Afspraak plannen (binnenkort)',
        href: '#',
        icon: UsersIcon,
        iconForeground: 'text-sky-700',
        iconBackground: 'bg-sky-50',
        desc: 'Plan en beheer afspraken met het talent voor begeleiding en evaluatie.',
    },

    {
        title: 'Update schrijven (binnenkort)',
        href: '#',
        icon: PencilSquareIcon,
        iconForeground: 'text-rose-700',
        iconBackground: 'bg-rose-50',
        desc: 'Voeg regelmatige updates toe over de voortgang en motivatie van het talent.',
    },
    {
        title: 'Opleiding activeren (binnenkort)',
        href: '#',
        icon: AcademicCapIcon,
        iconForeground: 'text-indigo-700',
        iconBackground: 'bg-indigo-50',
        desc: 'Activeer en monitor trainingsmodules en leermaterialen voor het talent.',
    },
]



const people = [
    {
        id: 1,
        name: 'Dude Goed',
        avatar:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzGVuDI_9ZiMTDG3evK5KOFSRViBbA3WkGMQ&s',
    },
    
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Example() {
    const { user, logOut } = useAuth();
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [selected, setSelected] = useState(people[0])
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenGenerate, setModalOpenGenerate] = useState(false);
    const [newTeamName, setNewTeamName] = useState('');
    const [teams, setTeams] = useState([
        { id: 1, name: 'Updates', href: '/team', initial: 'U', current: false },
        { id: 2, name: 'Signalen', href: '/signalen', initial: 'S', current: false },
        { id: 3, name: 'Talenten', href: '/hometalenten', initial: 'T', current: false },
        { id: 4, name: 'Notities', href: '/notities', initial: 'N', current: false },

    ]);
    const navigate = useNavigate();
    function handleClick(id) {
        navigate("/talentenhub/talentdetails/" + id);
        setModalOpen(false)
    }
    const [recent, setRecent] = useState<UserModel[]>([])
    const [users, setUsers] = useState<UserModel[]>([])
    const [query, setQuery] = useState('')
    const location = useLocation();

    const navigation = [
        {
            title: '',
            items: [
                { name: 'Dashboard', href: '/', icon: HomeIcon, current: true },
                { name: 'Vandaag', href: '/vandaag', icon: BookOpenIcon, current: true },
                { name: 'Signalen', href: '/signalen', icon: BellAlertIcon, current: true },
            ],
        },
        {
            title: 'Talenten',
            items: [
                {
                    name: 'Talentenhub',
                    href: '/talentenhub',
                    icon: UsersIcon,
                    current: false,
                    subItems: [
                        { name: 'Overzicht', href: '/talentenhub' },
                        { name: 'Aanmeldingen', href: '/talentenhub/aanmeldingen' },
                        { name: 'Intakes', href: '/talentenhub/intakes' },
                        { name: 'Rapportages', href: '/talentenhub/rapportages' },
                        { name: 'Praktijkverklaringen', href: '/talentenhub/praktijkverklaringen' },
                        { name: 'Caseload', href: '/talentenhub/caseload' },
                    ],
                },
                { name: 'Rooster', href: '/rooster', icon: CalendarIcon, current: false },
                {
                    name: 'Bijenkorf',
                    href: '/bijenkorf',
                    icon: UserGroupIcon,
                    current: false,
                    subItems: [
                        { name: 'Alle Talenten', href: '/bijenkorf' },
                        { name: 'Collegas', href: '/bijenkorf/collegas' },
                        { name: 'Bedrijven', href: '/bijenkorf/bedrijven' },
                        { name: 'Vacatures', href: '/bijenkorf/vacatures'}
    
                    ],
                },
    
            ],
        },
        {
            title: 'Administratie',
            items: [
                {
                    name: 'Trajecten', href: '/trajecten', icon: DocumentDuplicateIcon, current: false,
                    subItems: [
                        { name: 'Overzicht', href: '/trajecten' },
                        { name: 'Casemanagers', href: '/trajecten/casemanagers' },
                        { name: 'Financieel', href: '/trajecten/financieel' },
                    ],
                },
                { 
                    name: 'Nieuw', 
                    href: '#', 
                    icon: PlusCircleIcon, 
                    current: false, 
                    onClick: () => setModalOpenGenerate(true), 
                  },
    
            ],
        },
    ];

    useEffect(() => {
        if (query) {
            searchByTerm(user!, query).then((response) => {
                setUsers(response)
                setRecent(response)
            })
        }
    }, [query])

    return (
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-orange-50 px-6">
                <div className="flex h-16 shrink-0 items-center justify-between">

                    <a href="/" className="flex">
                        <img
                            alt="Bloeiindex"
                            src="/BI-blue@4x.png"
                            className="h-4 w-auto"
                        />
                    </a>
                     {/* Hourglass Icon */}
                    <div className="flex items-center space-x-2">
                    <button
                            onClick={() => (logOut().then(()=>navigate("/")))}
                            className="text-blue-700 hover:text-blue-700 focus:outline-none flex items-center"
                        >
                            <LockOpenIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                        <button
                            onClick={() => setModalOpen(true)} // Open the actionbar modal
                            className="text-blue-700 hover:text-blue-700 focus:outline-none flex items-center"
                        >
                            <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        <button
                            onClick={() => setModalOpenGenerate(true)} // Open the actionbar modal
                            className="text-blue-700 hover:text-blue-700 focus:outline-none"
                        >
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </div>

                <Listbox value={selected} onChange={setSelected}>
                    <div className="relative ">
                        <ListboxButton className="grid w-full cursor-default grid-cols-1 rounded-md border text-left text-gray-900 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">
                            <span className="col-start-1 row-start-1 flex items-center gap-3 ">
                                <img alt="" src={selected.avatar} className="size-5 shrink-0 rounded-full" />
                                <span className="block truncate">{selected.name}</span>
                            </span>
                            <ChevronUpDownIcon
                                aria-hidden="true"
                                className="row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4"
                            />
                        </ListboxButton>

                        <ListboxOptions
                            transition
                            className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                        >
                            {people.map((person) => (
                                <ListboxOption
                                    key={person.id}
                                    value={person}
                                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-blue-700 data-[focus]:text-white data-[focus]:outline-none"
                                >
                                    <div className="flex items-center">
                                        <img alt="" src={person.avatar} className="size-5 shrink-0 rounded-full" />
                                        <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                                            {person.name}
                                        </span>
                                    </div>

                                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-[&:not([data-selected])]:hidden group-data-[focus]:text-white">
                                        <CheckIcon aria-hidden="true" className="size-5" />
                                    </span>
                                </ListboxOption>
                            ))}
                        </ListboxOptions>
                    </div>
                </Listbox>

                <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7 pb-16">
                        {navigation.map((category) => (
                            <li key={category.title}>
                                <h3 className="text-xs font-semibold text-slate-500">
                                    {category.title}
                                </h3>
                                <ul role="list" className="-mx-2 space-y-0">
                                    {category.items.map((item) => (
                                        <li key={item.name}>
                                            <div>
                                                <a
                                                    href={item.href}
                                                    onClick={item.onClick ? item.onClick : null}
                                                    className={classNames(
                                                        location.pathname === item.href
                                                            ? 'bg-orange-100 text-blue-700'
                                                            : 'hover:bg-orange-100 hover:text-blue-700',
                                                        'group flex items-center gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-slate-700',
                                                    )}
                                                >
                                                    <item.icon
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            location.pathname === item.href
                                                                ? 'text-blue-700'
                                                                : 'text-slate-700 group-hover:text-blue-700',
                                                            'size-6 shrink-0',
                                                        )}
                                                    />
                                                    {item.name}
                                                    {item.subItems && item.subItems.length > 0 && (
                                                        <ChevronDownIcon
                                                            aria-hidden="true"
                                                            className="size-5 ml-auto text-gray-400 group-hover:text-blue-700"
                                                        />
                                                    )}
                                                </a>
                                                {location.pathname.startsWith(item.href) && item.subItems && item.subItems.length > 0 && (
                                                    <ul role="list" className="ml-4 mt-2 space-y-1">
                                                        {item.subItems.map((subItem) => (
                                                            <li key={subItem.name}>
                                                                <a
                                                                    href={subItem.href}
                                                                    className={classNames(
                                                                        location.pathname === subItem.href
                                                                            ? 'bg-orange-100 text-blue-700'
                                                                            : 'hover:bg-orange-100 hover:text-blue-700',
                                                                        'block rounded-md p-2 text-sm/6 font-medium text-slate-700',
                                                                    )}
                                                                >
                                                                    {subItem.name}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                        {/* <li>
                            <div className="flex items-center gap-x-2 text-xs/6 font-semibold text-slate-700">
                                <HashtagIcon aria-hidden="true" className="size-5 text-blue-700" /> 
                                Kringloop Winkel
                                <Menu as="div" className="relative inline-block text-left ml-auto">
                                    <div>
                                        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                            <span className="sr-only">Open options</span>
                                            <EllipsisVerticalIcon aria-hidden="true" className="size-5" />
                                        </MenuButton>
                                    </div>

                                    <MenuItems
                                        transition
                                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                    >
                                        <div className="py-1">
                                            <MenuItem>
                                                <a
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                >
                                                    Account settings
                                                </a>
                                            </MenuItem>
                                            <MenuItem>
                                                <a
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                >
                                                    Support
                                                </a>
                                            </MenuItem>
                                            <MenuItem>
                                                <a
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                >
                                                    License
                                                </a>
                                            </MenuItem>
                                            <form action="#" method="POST">
                                                <MenuItem>
                                                    <button
                                                        type="submit"
                                                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                    >
                                                        Sign out
                                                    </button>
                                                </MenuItem>
                                            </form>
                                        </div>
                                    </MenuItems>
                                </Menu>
                            </div>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                                {teams.map((team) => (
                                    <li key={team.name}>
                                        <a
                                            href={team.href}
                                            className={classNames(
                                                location.pathname === team.href
                                                    ? 'bg-orange-100 text-blue-700'
                                                    : 'hover:bg-orange-100 hover:text-blue-700 text-slate-700',
                                                'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold',
                                            )}
                                        >
                                            <span className="flex size-6 shrink-0 items-center justify-center rounded-lg bg-blue-700 text-[0.625rem] font-medium text-white">
                                                {team.initial}
                                            </span>
                                            <span className="truncate">{team.name}</span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                            <div className="mt-4">
                                <button
                                    type="button"
                                    onClick={() => setModalOpen(true)}
                                    className="inline-flex items-center text-sm font-medium text-slate-400 rounded-md hover:text-blue-700"
                                >
                                    <PlusCircleIcon className="size-5 text-slate-400 mr-2" aria-hidden="true" />
                                    Toevoegen
                                </button>
                            </div>

                        </li> */}
                        {/* <li>
                            <div className="flex items-center gap-x-2 text-xs/6 font-semibold text-slate-700">
                                <HashtagIcon aria-hidden="true" className="size-5 text-blue-700" />
                                Fietsenwacht
                                <Menu as="div" className="relative inline-block text-left ml-auto">
                                    <div>
                                        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                            <span className="sr-only">Open options</span>
                                            <EllipsisVerticalIcon aria-hidden="true" className="size-5" />
                                        </MenuButton>
                                    </div>

                                    <MenuItems
                                        transition
                                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                    >
                                        <div className="py-1">
                                            <MenuItem>
                                                <a
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                >
                                                    Account settings
                                                </a>
                                            </MenuItem>
                                            <MenuItem>
                                                <a
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                >
                                                    Support
                                                </a>
                                            </MenuItem>
                                            <MenuItem>
                                                <a
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                >
                                                    License
                                                </a>
                                            </MenuItem>
                                            <form action="#" method="POST">
                                                <MenuItem>
                                                    <button
                                                        type="submit"
                                                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                    >
                                                        Sign out
                                                    </button>
                                                </MenuItem>
                                            </form>
                                        </div>
                                    </MenuItems>
                                </Menu>
                            </div>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                                {teams.map((team) => (
                                    <li key={team.name}>
                                        <a
                                            href={team.href}
                                            className={classNames(
                                                location.pathname === team.href
                                                    ? 'bg-orange-100 text-blue-700'
                                                    : 'hover:bg-orange-100 hover:text-blue-700 text-slate-700',
                                                'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold',
                                            )}
                                        >
                                            <span className="flex size-6 shrink-0 items-center justify-center rounded-lg bg-blue-700 text-[0.625rem] font-medium text-white">
                                                {team.initial}
                                            </span>
                                            <span className="truncate">{team.name}</span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                            <div className="mt-4">
                                <button
                                    type="button"
                                    onClick={() => setModalOpen(true)}
                                    className="inline-flex items-center text-sm font-medium text-slate-400 rounded-md hover:text-blue-700"
                                >
                                    <PlusCircleIcon className="size-5 text-slate-400 mr-2" aria-hidden="true" />
                                    Toevoegen
                                </button>
                            </div>
                        </li> */}
                        {/* <li>
                            <div className="flex items-center gap-x-2 text-xs/6 font-semibold text-slate-700">
                                <HashtagIcon aria-hidden="true" className="size-5 text-blue-700" />
                                Bouw en Techniek
                                <Menu as="div" className="relative inline-block text-left ml-auto">
                                    <div>
                                        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                            <span className="sr-only">Open options</span>
                                            <EllipsisVerticalIcon aria-hidden="true" className="size-5" />
                                        </MenuButton>
                                    </div>

                                    <MenuItems
                                        transition
                                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                    >
                                        <div className="py-1">
                                            <MenuItem>
                                                <a
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                >
                                                    Account settings
                                                </a>
                                            </MenuItem>
                                            <MenuItem>
                                                <a
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                >
                                                    Support
                                                </a>
                                            </MenuItem>
                                            <MenuItem>
                                                <a
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                >
                                                    License
                                                </a>
                                            </MenuItem>
                                            <form action="#" method="POST">
                                                <MenuItem>
                                                    <button
                                                        type="submit"
                                                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                    >
                                                        Sign out
                                                    </button>
                                                </MenuItem>
                                            </form>
                                        </div>
                                    </MenuItems>
                                </Menu>
                            </div>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                                {teams.map((team) => (
                                    <li key={team.name}>
                                        <a
                                            href={team.href}
                                            className={classNames(
                                                location.pathname === team.href
                                                    ? 'bg-orange-100 text-blue-700'
                                                    : 'hover:bg-orange-100 hover:text-blue-700 text-slate-700',
                                                'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold',
                                            )}
                                        >
                                            <span className="flex size-6 shrink-0 items-center justify-center rounded-lg bg-blue-700 text-[0.625rem] font-medium text-white">
                                                {team.initial}
                                            </span>
                                            <span className="truncate">{team.name}</span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                            <div className="mt-4">
                                <button
                                    type="button"
                                    onClick={() => setModalOpen(true)}
                                    className="inline-flex items-center text-sm font-medium text-slate-400 rounded-md hover:text-blue-700"
                                >
                                    <PlusCircleIcon className="size-5 text-slate-400 mr-2" aria-hidden="true" />
                                    Toevoegen
                                </button>
                            </div>
                        </li> */}
                        {/* <li>
                            <div className="flex items-center gap-x-2 text-xs/6 font-semibold text-slate-700">
                                <HashtagIcon aria-hidden="true" className="size-5 text-blue-700" />
                                Bouw en Techniek
                                <Menu as="div" className="relative inline-block text-left ml-auto">
                                    <div>
                                        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                            <span className="sr-only">Open options</span>
                                            <EllipsisVerticalIcon aria-hidden="true" className="size-5" />
                                        </MenuButton>
                                    </div>

                                    <MenuItems
                                        transition
                                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                    >
                                        <div className="py-1">
                                            <MenuItem>
                                                <a
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                >
                                                    Account settings
                                                </a>
                                            </MenuItem>
                                            <MenuItem>
                                                <a
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                >
                                                    Support
                                                </a>
                                            </MenuItem>
                                            <MenuItem>
                                                <a
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                >
                                                    License
                                                </a>
                                            </MenuItem>
                                            <form action="#" method="POST">
                                                <MenuItem>
                                                    <button
                                                        type="submit"
                                                        className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                                    >
                                                        Sign out
                                                    </button>
                                                </MenuItem>
                                            </form>
                                        </div>
                                    </MenuItems>
                                </Menu>
                            </div>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                                {teams.map((team) => (
                                    <li key={team.name}>
                                        <a
                                            href={team.href}
                                            className={classNames(
                                                location.pathname === team.href
                                                    ? 'bg-orange-100 text-blue-700'
                                                    : 'hover:bg-orange-100 hover:text-blue-700 text-slate-700',
                                                'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold',
                                            )}
                                        >
                                            <span className="flex size-6 shrink-0 items-center justify-center rounded-lg bg-blue-700 text-[0.625rem] font-medium text-white">
                                                {team.initial}
                                            </span>
                                            <span className="truncate">{team.name}</span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                            <div className="mt-4">
                                <button
                                    type="button"
                                    onClick={() => setModalOpen(true)}
                                    className="inline-flex items-center text-sm font-medium text-slate-400 rounded-md hover:text-blue-700"
                                >
                                    <PlusCircleIcon className="size-5 text-slate-400 mr-2" aria-hidden="true" />
                                    Toevoegen
                                </button>
                            </div>

                        </li> */}

                        <li className="-mx-6 mt-auto absolute bottom-0 bg-orange-50 border-t border-orange-100 ">
                            <a
                                href="/beheer"
                                className="flex items-center gap-x-4 px-6 py-3 text-sm/6 font-semibold text-slate-700 hover:text-blue-700 hover:bg-orange-100"
                            >
                                <img
                                    alt=""
                                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    className="size-8 rounded-full bg-indigo-700"
                                />
                                <span className="sr-only">Your profile</span>
                                <div className="text-gray-900">{user?.voornaam} {user?.achternaam}</div>
                            </a>
                        </li>

                    </ul>
                </nav>
            </div>
            {modalOpen &&
                <Dialog
                    className="relative z-10"
                    open={modalOpen}
                    onClose={() => {
                        setModalOpen(false)
                        setQuery('')
                    }}
                >
                    <DialogBackdrop
                        transition
                        className="backdrop-blur-sm fixed inset-0 bg-bg-orange-50 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                    />

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20 ">
                        <DialogPanel
                            transition
                            className="mx-auto rounded-lg max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                            <Combobox
                                onChange={(person) => {
                                    if (person) {
                                        window.location = person.url
                                    }
                                }}
                            >
                                {({ activeOption }) => (
                                    <>
                                        <div className="relative">
                                            <MagnifyingGlassIcon
                                                className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            <ComboboxInput
                                                autoFocus
                                                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                                placeholder="Zoeken in de Bloeiindex..."
                                                onChange={(event) => setQuery(event.target.value)}
                                                onBlur={() => setQuery('')}
                                            />
                                        </div>

                                        {(query === '' || users.length > 0) && (
                                            <ComboboxOptions as="div" static hold className="flex transform-gpu divide-x divide-gray-100">
                                                <div
                                                    className={classNames(
                                                        'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                                                        activeOption && 'sm:h-96',
                                                    )}
                                                >
                                                    {/* {query === '' && (
                                                        <h2 className="mb-4 mt-2 text-xs font-semibold text-gray-500">Recentelijk gezocht</h2>
                                                    )} */}
                                                    <div className="-mx-2 text-sm text-gray-700">
                                                        {(query === '' ? recent : users).map((person) => (
                                                            <ComboboxOption
                                                                as="div"
                                                                key={person.ref.id}
                                                                value={person}
                                                                className="group flex cursor-default select-none items-center rounded-md p-2 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                                            >
                                                                <DefaultImage profiel_foto={person.profiel_foto} className="h-6 w-6 flex-none rounded-full" />
                                                                <span className="ml-3 flex-auto truncate">{person.voornaam} {person.achternaam}</span>
                                                                <ChevronRightIcon
                                                                    className="ml-3 hidden h-5 w-5 flex-none text-gray-400 group-data-[focus]:block"
                                                                    aria-hidden="true"
                                                                />
                                                            </ComboboxOption>
                                                        ))}
                                                    </div>
                                                </div>

                                                {activeOption && (<>
                                                    <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                                                        <div className="flex-none p-6 text-center">
                                                            <DefaultImage profiel_foto={activeOption.profiel_foto} className="mx-auto h-16 w-16 rounded-full" />
                                                            <h2 className="mt-3 font-semibold text-gray-900">{activeOption.voornaam} {activeOption.achternaam}</h2>
                                                            <p className="text-sm leading-6 text-gray-500">{activeOption.gebruikers_rol}, {activeOption.geslacht}</p>
                                                        </div>
                                                        <div className="flex flex-auto flex-col justify-between p-6">
                                                            <dl className="grid grid-cols-1 gap-x-3 gap-y-0 text-sm text-gray-700">
                                                                <dt className="col-end-1 font-semibold text-gray-900">Telefoon</dt>
                                                                <dd className="col-start-1 text-gray-900">{activeOption.telefoon_nummer}</dd>                                                                
                                                                <dt className="col-end-1 font-semibold text-gray-900">Email</dt>
                                                                <dd className="col-start-1 text-gray-900">
                                                                    <a href={`mailto:${activeOption.email}`} className="text-indigo-600 underline">
                                                                        {activeOption.email}
                                                                    </a>
                                                                </dd>
                                                            </dl>
                                                            <button
                                                                type="button"
                                                                onClick={(e) => handleClick(activeOption.ref.id)}
                                                                className="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                            >
                                                                Bekijk Talent
                                                            </button>
                                                        </div>
                                                    </div></>
                                                )}
                                            </ComboboxOptions>
                                        )}

                                        {query !== '' && users.length === 0 && (
                                            <div className="px-6 py-14 text-center text-sm sm:px-14">
                                                <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                                                <p className="mt-4 font-semibold text-gray-900">No people found</p>
                                                <p className="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Combobox>
                        </DialogPanel>
                    </div>
                </Dialog>}
            {modalOpenGenerate && <Dialog as='div' open={modalOpenGenerate} onClose={setModalOpenGenerate} className="relative z-10 ">
                <DialogBackdrop
                    transition
                    className="backdrop-blur-sm fixed inset-0 bg-orange-50 bg-opacity-75 transition-opacity backdrop-blur-md transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto " >
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >


                            <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
                                {actions.map((action, actionIdx) => (
                                    <div
                                        key={action.title}
                                        className={classNames(
                                            actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                                            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                                            actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                                            actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                                            'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                                        )}
                                    >
                                        <div>
                                            <span
                                                className={classNames(
                                                    action.iconBackground,
                                                    action.iconForeground,
                                                    'inline-flex rounded-lg p-3 ring-4 ring-white',
                                                )}
                                            >
                                                <action.icon aria-hidden="true" className="h-6 w-6" />
                                            </span>
                                        </div>
                                        <div className="mt-8">
                                            <h3 className="text-base font-semibold leading-6 text-gray-900">
                                                <a href={action.href} className="focus:outline-none">
                                                    {/* Extend touch target to entire panel */}
                                                    <span aria-hidden="true" className="absolute inset-0" />
                                                    {action.title}
                                                </a>
                                            </h3>
                                            <p className="mt-2 text-sm text-gray-500">
                                                {action.desc}
                                            </p>
                                        </div>
                                        <span
                                            aria-hidden="true"
                                            className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                                        >
                                            <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6">
                                                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                            </svg>
                                        </span>
                                    </div>
                                ))}
                            </div>



                            <div className="mt-5 sm:mt-6">
                                <button
                                    type="button"
                                    onClick={() => setModalOpenGenerate(false)}
                                    className="inline-flex w-full justify-center rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Terug naar de Bloeiindex
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>}
        </div>
    )
}
