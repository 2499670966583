'use client'

import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { Bars4Icon, CalendarIcon, CheckIcon, ClockIcon, PhotoIcon, TableCellsIcon, ViewColumnsIcon } from '@heroicons/react/24/outline'
import { NewspaperIcon } from '@heroicons/react/20/solid'

const items = [
    {
        title: 'Nieuwe Rapportage',
        description: 'Genereer snel een uitgebreide rapportage op basis van dagelijkse updates en voortgang.',
        icon: Bars4Icon,
        background: 'bg-sky-400',
    },
    {
        title: 'Loonwaardemeting',
        description: 'Houd de prestaties en inzet van talenten overzichtelijk bij in een loonwaarderapport.',
        icon: CalendarIcon,
        background: 'bg-yellow-500',
    },
    {
        title: 'Afspraak plannen',
        description: 'Plan gemakkelijk afspraken en blijf op de hoogte van belangrijke ontmoetingen.',
        icon: PhotoIcon,
        background: 'bg-green-500',
    },
    {
        title: 'Update schrijven',
        description: 'Voeg dagelijkse voortgangsnotities toe om het ontwikkeltraject bij te houden.',
        icon: ViewColumnsIcon,
        background: 'bg-blue-500',
    },
    {
        title: 'Opleiding toevoegen',
        description: 'Beheer trainingen en opleidingen die talenten ondersteunen in hun groei.',
        icon: TableCellsIcon,
        background: 'bg-indigo-500',
    },
    {
        title: 'Praktijkverklaring aanvragen',
        description: 'Start het proces om een officiële praktijkverklaring aan te vragen voor talenten.',
        icon: ClockIcon,
        background: 'bg-purple-500',
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Example({open, setOpen, onclick, onclickparktijk}) {

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-10 ">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-orange-100 bg-opacity-25 backdrop-blur-md transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="mx-auto fixed inset-0 z-10 w-screen max-w-3xl">
                <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                <NewspaperIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                            </div>
                            <div className="mt-3 sm:mt-5">
                                <DialogTitle as="h3" className="text-base font-semibold text-gray-900  text-center">
                                    Nieuw document genereren
                                </DialogTitle>
                                <div className="mt-2">
                                    <div>
                                        <ul role="list" className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2">
                                            {items.map((item, itemIdx) => (
                                                <li key={itemIdx} className="flow-root">
                                                    <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
                                                        <div
                                                            className={classNames(
                                                                item.background,
                                                                'flex h-16 w-16 shrink-0 items-center justify-center rounded-lg',
                                                            )}
                                                        >
                                                            <item.icon aria-hidden="true" className="h-6 w-6 text-white" />
                                                        </div>
                                                        <div>
                                                            <h3 className="text-sm font-medium text-gray-900">
                                                                <a onClick={(item.title == "Praktijkverklaring aanvragen" ? onclickparktijk : onclick)} href="#" className="focus:outline-none">
                                                                    <span aria-hidden="true" className="absolute inset-0" />
                                                                    <span>{item.title}</span>
                                                                    <span aria-hidden="true"> &rarr;</span>
                                                                </a>
                                                            </h3>
                                                            <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>                                 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                            <button
                                type="button"
                                onClick={() => setOpen(false)}
                                className="inline-flex w-full justify-center rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Terug naar de Bloeiindex
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}
