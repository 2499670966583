import { DocumentReference } from "firebase/firestore"
import getAllEvaluatieDocuments, { getEvaluatiesByTalentId as _getEvaluatiesByTalentId, createEvaluatieDocument, getEvaluatieDocumentByReference, getEvaluatieDocumentsByUserId } from "../datasource/evaluatie-utils"
import { getUserById, UserModel } from "./user-utils"
import { getTalentDocumentById, RemoteTalent } from "../datasource/talent-utils"
import { getUserDocumentById, RemoteUser } from "../datasource/user-utils"

export type EvaluatieModel = {
  aanmaak_datum: Date,
  auteur: {
    naam: string,
    profiel_foto?: string
  },
  talent?: {
    naam: string
  }
  content: string,
  cijfer?: number,
  gevoel?: number
}

export type NewEvaluatieModel = {
  auteurId: string,
  talentId: string,
  content: string,
  cijfer: number,
  gevoel: number
}

export const getAllEvaluaties = async (user: UserModel, max = 20) => {
  const list: EvaluatieModel[] = []

  const evaluaties = await getAllEvaluatieDocuments(user?.data_eigenaar_ref!, max)

  const users: Map<string, UserModel> = new Map()

  for (let evaluatie of evaluaties) {
    list.push({
      aanmaak_datum: evaluatie.aanmaak_datum,
      auteur: {
        naam: `${evaluatie.auteur.voornaam} ${evaluatie.auteur.achternaam}`,
        profiel_foto: evaluatie.auteur.profiel_foto ?? undefined
      },
      content: evaluatie.omschrijving,
      gevoel: evaluatie.gevoel,
      cijfer: evaluatie.cijfer
    })
  }

  return list

}

export const getAllEvaluatiesByUserId = async (user: UserModel, max = 20) => {
  const list: EvaluatieModel[] = []

  const evaluaties = await getEvaluatieDocumentsByUserId(user.ref, user?.data_eigenaar_ref!, max)

  const users: Map<string, UserModel> = new Map()

  for (let evaluatie of evaluaties) {
    list.push({
      aanmaak_datum: evaluatie.aanmaak_datum,
      auteur: {
        naam: `${evaluatie.auteur.voornaam} ${evaluatie.auteur.achternaam}`,
        profiel_foto: evaluatie.auteur.profiel_foto ?? undefined
      },
      talent: {
        naam: `${evaluatie.talent.voornaam} ${evaluatie.talent.achternaam}`,
      },
      content: evaluatie.omschrijving,
      gevoel: evaluatie.gevoel,
      cijfer: evaluatie.cijfer
    })
  }

  return list

}

export const getEvaluatiesByTalentId = async (id: string, user: UserModel) => {
  const list: EvaluatieModel[] = []

  const talent = await getTalentDocumentById(id)

  if (!talent)
    return Promise.reject("Geen talent gevonden.")

  var evaluaties = await _getEvaluatiesByTalentId(talent.reference, user?.data_eigenaar_ref!)

  for (let evaluatie of evaluaties) {
    list.push({
      aanmaak_datum: evaluatie.aanmaak_datum,
      auteur: {
        naam: `${evaluatie.auteur.voornaam} ${evaluatie.auteur.achternaam}`,
        profiel_foto: evaluatie.auteur.profiel_foto ?? undefined
      },
      content: evaluatie.omschrijving,
      gevoel: evaluatie.gevoel,
      cijfer: evaluatie.cijfer
    })
  }

  return list;
}

export const getEvaluatieWithAdditionalInfo = async (id: string, user: UserModel) => {
  const list: EvaluatieModel[] = []

  const talent = await getTalentDocumentById(id)

  if (!talent)
    return Promise.reject("Geen talent gevonden.")

  const evaluaties = await _getEvaluatiesByTalentId(talent.reference, user?.data_eigenaar_ref!)

  for (let evaluatie of evaluaties) {
    list.push({
      aanmaak_datum: evaluatie.aanmaak_datum,
      auteur: {
        naam: `${evaluatie.auteur.voornaam} ${evaluatie.auteur.achternaam}`,
        profiel_foto: evaluatie.auteur.profiel_foto ?? undefined
      },
      content: evaluatie.omschrijving
    })
  }

  return list;
}

const getEvaluatieByReference = async (reference: DocumentReference, talent: RemoteTalent, auteur: RemoteUser) => {
   const evaluatie = await getEvaluatieDocumentByReference(reference)

  if (!evaluatie)return Promise.reject("Geen evaluatie gevonden.")

  return {
    aanmaak_datum: evaluatie.aanmaak_datum,
    auteur: {
      naam: `${auteur?.voornaam} ${auteur?.achternaam}`,
      profiel_foto: auteur?.profiel_foto
    },
    talent: {
      naam: `${talent?.voornaam} ${talent?.achternaam}`,
    },
    content: evaluatie.omschrijving,
    gevoel: evaluatie.gevoel,
    cijfer: evaluatie.cijfer
  } as EvaluatieModel

}

export const createEvaluatie = async (user: UserModel, data: NewEvaluatieModel) => {
  const talent = await getTalentDocumentById(data.talentId)

  if (!talent) return Promise.reject("Geen talent gevonden.")

  const auteur = await getUserDocumentById(data.auteurId)

  if (!auteur) return Promise.reject("Geen auteur gevonden.")

  const evaluatie = await createEvaluatieDocument({
    auteurReference: auteur.ref, 
    talentReference: talent.reference, 
    content: data.content, 
    dataOwner: user.data_eigenaar_ref!, 
    cijfer: data.cijfer, 
    gevoel: data.gevoel,
    auteur: {
      achternaam: auteur.achternaam,
      email: auteur.email,
      profiel_foto: auteur.profiel_foto ?? null,
      reference: auteur.ref.id,
      voornaam: auteur.voornaam
    },
    talent: {
      achternaam: talent.achternaam,
      email: talent.email,
      profiel_foto: talent.profiel_foto ?? null,
      reference: talent.reference.id,
      voornaam: talent.voornaam
    },
  })

  if (!evaluatie) return Promise.reject("Geen update aangemaakt.")

  return getEvaluatieByReference(evaluatie, talent, auteur)
}