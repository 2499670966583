import React, { useRef, useEffect } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';

interface BottomSheetProps {
  isOpen: boolean;
  toggle: () => void;
  title?: string;
  children: React.ReactNode;
}

const BottomSheet: React.FC<BottomSheetProps> = ({ isOpen, toggle, title = 'Details', children }) => {
  const sheetRef = useRef<HTMLDivElement>(null);

  const [{ y }, api] = useSpring(() => ({
    y: 100, // Initially hidden
    config: { tension: 300, friction: 30, clamp: true }, // Adjusted for smoother motion
  }));
  
  useEffect(() => {
    api.start({ y: isOpen ? 0 : 100, immediate: false });
  }, [isOpen, api]);

  useEffect(() => {
    api.start({ y: isOpen ? 0 : 100 });
  }, [isOpen, api]);

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (sheetRef.current && !sheetRef.current.contains(e.target as Node)) {
      toggle();
    }
  };



  const bind = useDrag(
    ({ last, movement: [, my], velocity: [vx, vy], direction: [, dy], cancel }) => {
      const absVelocity = Math.sqrt(vx * vx + vy * vy); // Calculate the absolute velocity
  
      if (my > 0) {
        api.start({ y: my, immediate: false }); // Smooth dragging
      }
  
      // If the gesture is complete and swiped downward with enough velocity, close the sheet
      if (last) {
        if (my > 150 || (absVelocity > 0.5 && dy > 0)) {
          api.start({ y: 100, immediate: false }); // Add a bit of spring for snapping
          toggle();
        } else {
          api.start({ y: 0, immediate: false }); // Snap back smoothly
        }
      }
    },
    { bounds: { top: 0 }, rubberband: true } // Prevent dragging upward, slight overscroll effect
  );

  return (
    <>
      {/* Backdrop */}
      <div
        onClick={handleBackdropClick}
        className={`fixed inset-0 z-40 bg-black transition-opacity duration-300 ${
          isOpen ? 'bg-opacity-50 visible' : 'bg-opacity-0 invisible'
        }`}
        aria-hidden={!isOpen}
      />

      {/* Drawer */}
      <animated.div
        ref={sheetRef}
        {...bind()}
        style={{
          transform: y.to((value) => `translateY(${value}%)`),
        }}
        className="h-[90vh] fixed bottom-0 inset-x-0 z-50 bg-white rounded-t-lg shadow-lg transition-transform touch-pan-y"
        role="dialog"
        aria-modal="true"
      >
        <div className="p-4 border-b">
          <div className="w-16 h-1 bg-gray-300 rounded-full mx-auto"></div>
          <h2 className="mt-2 text-center text-lg font-semibold">{title}</h2>
          
        </div>
        <div className="p-4 h-full overflow-auto">{children}</div>
      </animated.div>
    </>
  );
};

export default BottomSheet;