import { PlusSmallIcon } from '@heroicons/react/20/solid';
import { Link, useLocation } from 'react-router-dom';

export default function Example() {
  const location = useLocation();

  return (
    <div>
      <div className="mt-6 flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8 py- border-b border-gray-200 pb-5 overflow-x-scroll">
        <h1 className="text-base font-semibold leading-7 text-gray-900 hidden md:block">Bijenkorf</h1>
        <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7 ">
          <Link
            to="/bijenkorf"
            className={`${
              location.pathname === '/bijenkorf' ? 'text-blue-700' : 'text-gray-700'
            } hover:text-blue-700`}
          >
            Alle Talenten
          </Link>
          <Link
            to="/bijenkorf/collegas"
            className={`${
              location.pathname === '/bijenkorf/collegas' ? 'text-blue-700' : 'text-gray-700'
            } hover:text-blue-700`}
          >
            Collega's
          </Link>
          <Link
            to="/bijenkorf/bedrijven"
            className={`${
              location.pathname === '/bijenkorf/bedrijven' ? 'text-blue-700' : 'text-gray-700'
            } hover:text-blue-700`}
          >
            Bedrijven
          </Link>
          <Link
            to="/bijenkorf/vacatures"
            className={`${
              location.pathname === '/bijenkorf/vacatures' ? 'text-blue-700' : 'text-gray-700'
            } hover:text-blue-700`}
          >
            Vacatures
          </Link>
        </div>
      </div>
    </div>
  );
}