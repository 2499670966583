import { collection, query, getDocs, where, limit, DocumentReference, getDoc, doc, and, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { db } from "../utils/firebase";
export type RemoteWerkvloerbegeleider = {
    reference: DocumentReference,
    achternaam: string,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    telefoon_nummer: string,
    voornaam: string,
    werkbedrijf_reference: DocumentReference
};

const werkvloerbegeleiderConverter = {
    toFirestore: (user: RemoteWerkvloerbegeleider) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteWerkvloerbegeleider {
            const data = snapshot.data(options)!;
            return {
                reference: snapshot.ref,
                achternaam: data.achternaam,
                email: data.email,
                gebruikers_rol: data.gebruikers_rol,
                geslacht: data.geslacht,
                profiel_foto: data.profiel_foto,
                telefoon_nummer: data.telefoon_nummer,
                voornaam: data.voornaam,
                werkbedrijf_reference: data.werkbedrijf_reference
            }
        }
  }

export default async (max: number, dataOwner: DocumentReference) => {

    const q = query(collection(db, "user"), and(where("data_eigenaar_ref", "==", dataOwner), where("gebruikers_rol", "==", "werkvloerbegeleider")), limit(max)).withConverter(werkvloerbegeleiderConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteWerkvloerbegeleider[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 
}

export const getWerkvloerbegeleiderById = async (id: DocumentReference | string ) => {

    if(!id ){
        return null;
    }
    const docSnap =  (typeof id === "string" ) ?await getDoc( doc(db, "user", id.toString()).withConverter(werkvloerbegeleiderConverter)):await getDoc((id as DocumentReference).withConverter(werkvloerbegeleiderConverter));

    /* = await getDoc(id); */
    
    if (docSnap.exists()) {
       return docSnap.data()
      } else {
        return null; 
      }
}
