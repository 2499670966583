import { DocumentReference } from "firebase/firestore";
import { getUserById, UserModel } from "./user-utils";
import { Activity, getActivityLogDocumentsByDocumentReference } from "../datasource/activity-log";

export type ActivityLogModel = {
    activiteit: string,
    user: {
        naam: string,
        profiel_foto?: string
    },
    datum: Date
}

export const getActivityLogByDocumentReference = async (document: DocumentReference) => {
    const list = await getActivityLogDocumentsByDocumentReference(document)

    let activiteiten: ActivityLogModel[] = []

    for (let activity of list) {

        let user = await getUserById(activity.user_ref)

        activiteiten.push({
            activiteit: convertEnumToString(activity.activiteit),
            datum: activity.datum!,
            user: {
                naam: `${user?.voornaam} ${user?.achternaam}`,
                profiel_foto: user?.profiel_foto
            }
        })
    }

    return activiteiten
}

function convertEnumToString(value: Activity) {
    switch (value) {
        case Activity.DOCUMENT_CREATE:
            return "Document aangemaakt"
        case Activity.DOCUMENT_DELETE:
            return "Document verwijderd"
        case Activity.DOCUMENT_DOWNLOAD:
            return "Document gedownload"
        case Activity.DOCUMENT_EDIT:
            return "Document aangepast"
        case Activity.DOCUMENT_PRINT:
            return "Document geprint"
        case Activity.DOCUMENT_VIEW:
            return "Document bekeken"
    }
}