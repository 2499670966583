import { ArrowDownIcon, EnvelopeIcon, PhoneIcon, ViewfinderCircleIcon } from '@heroicons/react/20/solid'
import { DocumentTextIcon } from '@heroicons/react/24/solid'
import { PDFDownloadLink } from '@react-pdf/renderer'
import RapportagePDFFile from '../../pdf/RapportagePDF'
import { Link } from 'react-router-dom'
import { RapportageModel } from '../../../domain/rapportage-utils'

export default function Example({rapportages}: {rapportages: RapportageModel[]}) {
  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1">
      {rapportages.map((rapportage) => (
        <li key={rapportage.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-slate-50 ">
          <div className="flex w-full items-center justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <Link to={`/talentenhub/rapportages/detail/${rapportage.id}`}>
                  <h3 className="truncate text-sm font-medium text-gray-900">{rapportage.talent?.naam}</h3>
                </Link>
                <span className="inline-flex shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  {rapportage.aanmaakdatum.toLocaleDateString()}
                </span>
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">{rapportage.soort}</p>
            </div>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <div className='relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900'>
                  <ArrowDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  <PDFDownloadLink document={<RapportagePDFFile content={rapportage?.content} onderwerp={rapportage?.soort} datum={rapportage?.aanmaakdatum.toLocaleDateString()}/>} fileName={rapportage?.soort}>
                  
                  Download
                  </PDFDownloadLink></div>
              </div>
              <div className="-ml-px flex w-0 flex-1">
              <Link
                  to={{
                    pathname:`/talentenhub/rapportages/detail/${rapportage.id}`
                  }}
                  className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                >
                  <DocumentTextIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  Bekijken
                </Link>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}
