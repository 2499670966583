import { DocumentReference, writeBatch } from "firebase/firestore"
import { createLicentienemerDocument, getAllLicentienemerDocuments, getLicentienemerDocumentById, getLicentienemerDocumentByName, RemoteLicentienemer } from "../datasource/licentienemer-utils"
import { getUserById } from "./user-utils"
import { ImportObject } from "../pages/beheer/import/Importer"
import { db } from "../utils/firebase"
import { addUserDocument } from "../datasource/user-utils"
import { createTrajectDocument, createTrajectDocumentWithBatch } from "../datasource/traject-utils"
import { createBedrijfDocument } from "../datasource/bedrijf-utils"
import { addTalentDocument, NewRemoteTalent, updateTalentDocument } from "../datasource/talent-utils"
import { addLopendTrajectDocument } from "../datasource/lopend-traject-utils"
import dayjs from "dayjs"

export type LicentienemerModel = {
    reference: DocumentReference,
    naam: string,
    url_naam: string,
    logo: string,
    titel1: string,
    beschrijving1: string,
    titel2: string,
    beschrijving2: string,
    feature1: string,
    feature_description1: string,
    feature2: string,
    feature_description2: string,
    feature3: string,
    feature_description3: string,
    straat: string,
    nummer: string,
    postcode: string,
    telefoonnummer: string,
    email: string,
    plaats: string,
    contact_persoon?: {
        naam: string
    },
    contact_persoon_id?: string
}

export type NewLicentienemerModel = {
    naam: string,
    logo: string,
    titel1: string,
    beschrijving1: string,
    titel2: string,
    beschrijving2: string,
    feature1: string,
    feature_description1: string,
    feature2: string,
    feature_description2: string,
    feature3: string,
    feature_description3: string,
    straat: string,
    nummer: string,
    postcode: string,
    telefoonnummer: string,
    email: string,
    plaats: string,
    url_naam?: string,
    contact_persoon?: {naam: string, email: string}
}

function convertRemoteToModel(remote: RemoteLicentienemer) {
    return {
        reference: remote.reference,
        naam: remote.naam,
        url_naam: remote.url_naam,
        logo: remote.logo,
        titel1: remote.titel1,
        beschrijving1: remote.beschrijving1,
        titel2: remote.titel2,
        beschrijving2: remote.beschrijving2,
        feature1: remote.feature1,
        feature_description1: remote.feature_description1,
        feature2: remote.feature2,
        feature_description2: remote.feature_description2,
        feature3: remote.feature3,
        feature_description3: remote.feature_description3,
        straat: remote.straat,
        nummer: remote.nummer,
        postcode: remote.postcode,
        telefoonnummer: remote.telefoonnummer,
        email: remote.email,
        plaats: remote.plaats
    } as LicentienemerModel
}

export const getAllLicentienemers = async (options?: {contact_persoon: true}) => {

    const list: LicentienemerModel[] = []
    const licentienemers = await getAllLicentienemerDocuments()

    for (const licentienemer of licentienemers) {
        let model = convertRemoteToModel(licentienemer)

        if (options?.contact_persoon) {
            let contactPersoon = await getUserById(licentienemer.contact_persoon)
            model.contact_persoon = {
                naam: `${contactPersoon?.voornaam} ${contactPersoon?.achternaam}`
            }
        }

        list.push(model)
    }

    return list
}

export const getLicentienemerById = async (id?: string) => {

    if(!id) return Promise.reject("Fout.")

    const licentienemer = await getLicentienemerDocumentById(id)

    if(!licentienemer) return Promise.reject("Geen licentienemer gevonden.")

    return convertRemoteToModel(licentienemer)

}

export const getLicentienemerByName = async (name?: string) => {

    if(!name) return Promise.reject("Fout.")

    const licentienemer = await getLicentienemerDocumentByName(name)

    if(!licentienemer) return Promise.reject("Geen licentienemer gevonden.")

    return convertRemoteToModel(licentienemer)

}

export const createNewLicentienemerWithPredefinedData = async (importObject: ImportObject) => {
    if (!importObject.consulenten || !importObject.licentienemer || !importObject.trajecten || !importObject.talenten ||
        !importObject.werkbedrijven || !importObject.werkvloerbegeleiders
    ) return Promise.reject("Fatal error")

    const batch = writeBatch(db)

    const licentienemerRef = createLicentienemerDocument({
        beschrijving1: importObject.licentienemer.beschrijving1,
        beschrijving2: importObject.licentienemer.beschrijving2,
        titel1: importObject.licentienemer.titel1,
        titel2: importObject.licentienemer.titel2,
        email: importObject.licentienemer.email,
        feature1: importObject.licentienemer.feature1,
        feature2: importObject.licentienemer.feature2,
        feature3: importObject.licentienemer.feature3,
        feature_description1: importObject.licentienemer.feature_description1,
        feature_description2: importObject.licentienemer.feature_description2,
        feature_description3: importObject.licentienemer.feature_description3,
        logo: importObject.licentienemer.logo,
        naam: importObject.licentienemer.naam,
        nummer: importObject.licentienemer.nummer,
        plaats: importObject.licentienemer.plaats,
        postcode: importObject.licentienemer.postcode,
        straat: importObject.licentienemer.straat,
        telefoonnummer: importObject.licentienemer.telefoonnummer,
    }, batch)

    if (!licentienemerRef)return Promise.reject("Error")

    const licentienemerUser = addUserDocument({
        achternaam: importObject.licentienemer.contact_persoon.achternaam, 
        email: importObject.licentienemer.email, 
        gebruikers_rol: "licentienemer", 
        geslacht: importObject.licentienemer.contact_persoon.geslacht,
        telefoon_nummer: importObject.licentienemer.contact_persoon.telefoon_nummer,
        voornaam: importObject.licentienemer.contact_persoon.voornaam,
        profiel_foto: importObject.licentienemer.contact_persoon.profiel_foto,
        data_eigenaar_ref: licentienemerRef
    }, batch)

    batch.update(licentienemerRef, {contact_persoon: licentienemerUser})

    const consulentenRefs: Map<string, DocumentReference> = new Map()

    for (let consulent of importObject.consulenten) {
        consulentenRefs.set(consulent.email.toLowerCase(), addUserDocument({
            achternaam: consulent.achternaam, 
            email: consulent.email, 
            gebruikers_rol: "consulent", 
            geslacht: consulent.geslacht,
            telefoon_nummer: consulent.telefoon_nummer,
            voornaam: consulent.voornaam,
            profiel_foto: consulent.profiel_foto, 
            data_eigenaar_ref: licentienemerRef
        }, batch))
    }

    const werkbegeleidersRefs: Map<string, DocumentReference> = new Map()

    for (let werkbegeleider of importObject.werkvloerbegeleiders) {
        werkbegeleidersRefs.set(werkbegeleider.email.toLowerCase(), addUserDocument({
            achternaam: werkbegeleider.achternaam, 
            email: werkbegeleider.email, 
            gebruikers_rol: "werkvloerbegeleider", 
            geslacht: werkbegeleider.geslacht,
            telefoon_nummer: werkbegeleider.telefoon_nummer,
            voornaam: werkbegeleider.voornaam,
            profiel_foto: werkbegeleider.profiel_foto, 
            data_eigenaar_ref: licentienemerRef
        }, batch))
    }

    const trajectenRefs: Map<string, {reference: DocumentReference, consulent_reference: DocumentReference}> = new Map()

    for (let traject of importObject.trajecten) {
        trajectenRefs.set(traject.naam!.toLowerCase(), {reference: createTrajectDocumentWithBatch({
            aantal_rapportages: traject.aantal_rapportages,
            consulent_reference: consulentenRefs.get(traject.consulent.email.toLowerCase()),
            duur: traject.duur,
            evaluatie_momenten: traject.evaluatie_momenten,
            gemeente: traject.gemeente,
            jobcoach_interventies: traject.jobcoach_interventies,
            loonwaardemeting: traject.loonwaardemeting,
            naam: traject.naam,
            omschrijving: traject.omschrijving,
            praktijkverklaring: traject.praktijkverklaring,
            talentportfolio: traject.talentportfolio,
            data_eigenaar_ref: licentienemerRef
        }, batch), consulent_reference: consulentenRefs.get(traject.consulent.email.toLowerCase())!})
    }

    const bedrijvenRefs: Map<string, {reference: DocumentReference, werkbedrijf_reference: DocumentReference}> = new Map()

    for (let bedrijf of importObject.werkbedrijven) {
        bedrijvenRefs.set(bedrijf.naam!.toLowerCase(), {reference: createBedrijfDocument({
            locatie: bedrijf.locatie,
            logo: bedrijf.logo,
            naam: bedrijf.naam,
            nummer: bedrijf.nummer,
            plaats: bedrijf.plaats,
            postcode: bedrijf.postcode,
            straat: bedrijf.straat,
            begeleider: werkbegeleidersRefs.get(bedrijf.werkvloerbegeleider.email.toLowerCase()),
            data_eigenaar_ref: licentienemerRef
        }, batch), werkbedrijf_reference: werkbegeleidersRefs.get(bedrijf.werkvloerbegeleider.email.toLowerCase())!})
    }

    for (let talent of importObject.talenten) {

        const talentObj: NewRemoteTalent = {
            achternaam: `${talent?.achternaam}`,
            actief: true,
            email: `${talent?.email}`,
            gebruikers_rol: "talent",
            geslacht: talent.geslacht,
            telefoon_nummer: talent.telefoon_nummer,
            voornaam: `${talent?.voornaam}`,
            talent: {
                geboorte_datum: talent.talent!.geboorte_datum,
                gemeente: talent.talent!.gemeente,
                huisnummer: talent.talent!.huisnummer,
                nationaliteit: talent.talent!.nationaliteit,
                plaats: talent.talent!.plaats,
                postcode: talent.talent!.postcode,
                straat: talent.talent!.straat,
                traject_ref: trajectenRefs.get(talent.traject!.naam.toLowerCase())!.reference,
                uitstroom: false
            },
            data_eigenaar_ref: licentienemerRef
        }

        if (talent.profiel_foto) {
            talentObj.profiel_foto = talent.profiel_foto
        }

        const talentReference = addTalentDocument(talentObj, batch)

        const lopendTrajectRef = addLopendTrajectDocument({
            begindatum: dayjs(`${talent.lopend_traject?.start_datum??0}`).toDate(),
            status: talent.lopend_traject!.status!,
            werkdagen: talent.lopend_traject!.werkdagen!,
            talent_ref: talentReference,
            traject_ref: trajectenRefs.get(talent.traject!.naam.toLowerCase())!.reference,
            consulent_ref: trajectenRefs.get(talent.traject!.naam.toLowerCase())!.consulent_reference,
            werkbedrijf_ref: bedrijvenRefs.get(talent.bedrijf!.naam.toLowerCase())!.reference,
            werkvloer_begeleider_ref: bedrijvenRefs.get(talent.bedrijf!.naam.toLowerCase())!.werkbedrijf_reference,
            data_eigenaar_ref: licentienemerRef
        }, batch)

        batch.update(talentReference, {"talent.lopendtraject_ref": lopendTrajectRef})

    }

    return batch.commit()

}