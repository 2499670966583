import { collection, query, getDocs, where, limit, DocumentReference, getCountFromServer, orderBy, getDoc, QueryDocumentSnapshot, SnapshotOptions, and, doc, WriteBatch, QueryConstraint } from "firebase/firestore";
import { db } from "../utils/firebase";
import { Traject, User, Werkbedrijf } from "./interfaces/collection";



export type RemoteLopendTraject = {
    reference: DocumentReference,    
    begindatum?: Date,
    consulent_ref: DocumentReference,
    status: string,
    talent_ref: DocumentReference,
    traject_ref: DocumentReference,
    verwachte_einddatum: Date,
    werkbedrijf_ref: DocumentReference,
    werkdagen: string,
    consulent: User,
    talent: User,
    traject: Traject,
    werkbedrijf: Werkbedrijf,
    werkvloerbegeleider: User,
    werkvloer_begeleider_ref: DocumentReference
};

type NewRemoteLopendTraject = {
    begindatum: Date,
    consulent_ref: DocumentReference,
    status: string,
    talent_ref: DocumentReference,
    traject_ref: DocumentReference,
    werkbedrijf_ref?: DocumentReference,
    werkdagen: string,
    werkvloer_begeleider_ref?: DocumentReference,
    data_eigenaar_ref: DocumentReference
};

const lopendTrajectConverter = {
    toFirestore: (lopendTraject: RemoteLopendTraject) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteLopendTraject {
        const data = snapshot.data(options)!;
        return {
            reference: snapshot.ref,
            begindatum: (data.begindatum) ? data.begindatum.toDate() : data.begindatum,
            consulent_ref: data.consulent_ref,
            status: data.status,
            talent_ref: data.talent_ref,
            traject_ref: data.traject_ref,
            verwachte_einddatum: data.verwachte_einddatum,
            werkbedrijf_ref: data.werkbedrijf_ref,
            werkdagen: data.werkdagen,
            werkvloer_begeleider_ref: data.werkvloer_begeleider_ref,
            consulent: data.consulent,
            talent: data.talent,
            traject: data.traject,
            werkbedrijf: data.werkbedrijf,
            werkvloerbegeleider: data.werkvloerbegeleider
        }
    }
}

export const getLopendeTrajectenDocuments = async (max: number, dataOwner: DocumentReference, queryConstraints?: QueryConstraint[]) => {

    let constraints: QueryConstraint[] = [where("data_eigenaar_ref", "==", dataOwner)]

    if (queryConstraints) {
        for (let query of queryConstraints) {
            constraints.push(query)
        }
    }

    let q = query(collection(db, "lopend_traject"), ...constraints, limit(max)).withConverter(lopendTrajectConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteLopendTraject[] = []
    querySnapshot.forEach((doc) => {
        return list.push(doc.data());
    });

    return list;
}

export const getActieveLopendeTrajectenDocuments = async (dataOwner: DocumentReference) => {
    return getLopendeTrajectenDocuments(9999, dataOwner, [where("status", "!=", "Beëindigd")])
}

export const getLopendeTrajectenByWerkvloerbegeleider = async (werkvloer_begeleider_ref: DocumentReference, dataOwner: DocumentReference) => {
    return getLopendeTrajectenDocuments(9999, dataOwner, [where("werkvloer_begeleider_ref", "==", werkvloer_begeleider_ref)])
}

export const getLopendTrajectDocumentByReference = async (reference: DocumentReference) => {

    const docSnap = await getDoc(reference);

    if (!docSnap.exists()) return null

    return {
        reference: docSnap.ref,
        begindatum: (docSnap.data().begindatum) ? docSnap.data().begindatum.toDate() : undefined,
        consulent_ref: docSnap.data().consulent_ref,
        status: docSnap.data().status,
        talent_ref: docSnap.data().talent_ref,
        traject_ref: docSnap.data().traject_ref,
        verwachte_einddatum: docSnap.data().verwachte_einddatum,
        werkbedrijf_ref: docSnap.data().werkbedrijf_ref,
        werkdagen: docSnap.data().werkdagen,
        werkvloer_begeleider_ref: docSnap.data().werkvloer_begeleider_ref,
    } as RemoteLopendTraject

}

export const getLopendTrajectDocumentById = async (id: string) => {

    const reference = doc(db, "lopend_traject", id).withConverter(lopendTrajectConverter);
    const docSnap = await getDoc(reference);

    if (!docSnap.exists()) return null

    return docSnap.data()

}

export const getTotalCountOfTalentsByWerkvloerbegeleider = async (werkvloerBegeleiderRefs: DocumentReference[], dataOwner: DocumentReference) => {
    let total = 0
    for (const werkvloerBegeleiderRef of werkvloerBegeleiderRefs) {
        const q = query(collection(db, "lopend_traject"), and(where("data_eigenaar_ref", "==", dataOwner), where("werkvloer_begeleider_ref", "==", werkvloerBegeleiderRef)), orderBy("talent_ref"))
        const snapShot = await getCountFromServer(q)
        total += snapShot.data().count
    }
    return total
}

export const addLopendTrajectDocument = (data: NewRemoteLopendTraject, batch: WriteBatch) => {
    const reference = doc(collection(db, 'lopend_traject'))

        batch.set(reference, data)

        return reference
}