import { PlusSmallIcon } from '@heroicons/react/20/solid'
import { Link, useLocation } from 'react-router-dom'
// import '../../../index.css';


export default function Example() {
  const locatie = useLocation();

  return (
    <div>
      <div className="mt-6 flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8 py- border-b border-gray-200 pb-5 overflow-x-scroll">
        <h1 className="text-base font-semibold leading-7 text-gray-900 hidden md:block">Trajecten</h1>
        <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7 ">
          <a href="/trajecten" className={locatie.pathname === '/trajecten' ? 'text-blue-700' : 'text-gray-700 hover:text-blue-700'}>
            Overzicht
          </a>
          <a href="/trajecten/casemanagers" className={locatie.pathname === '/trajecten/casemanagers' ? 'text-blue-700' : 'text-gray-700 hover:text-blue-700'}>
            Casemanagers
          </a>
          <a href="/trajecten/financieel" className={locatie.pathname === '/trajecten/financieel' ? 'text-blue-700' : 'text-gray-700 hover:text-blue-700'}>
            Financieel
          </a>
        </div>
      </div>
    </div>
  )
}
