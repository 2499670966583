import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { AanmeldingModel } from '../../domain/aanmelding-utils';
import Logo from '../../logoGemeente.png';

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 10,
        fontFamily: 'Helvetica',
        backgroundColor: '#f7f8fa',
    },
    container: {
        backgroundColor: '#ffffff',
        padding: 20,
        borderRadius: 5,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '2px solid #888',
        paddingBottom: 10,
        marginBottom: 20,
    },
    headerLeft: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    headerRight: {
        textAlign: 'right',
    },

    headerImage: {
        width: 70,
        height: 60,
        marginLeft: 5,
    },

    headerText: {
        flexDirection: 'column',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 5,
    },
    reference: {
        fontSize: 10,
        color: '#555',
        fontStyle: 'italic',
        textAlign: 'right',
    },

    sectionTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#333',
    },
    fieldGroup: {
        marginBottom: 20,
    },
    fieldRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    fieldLabel: {
        flex: 1,
        fontWeight: 'bold',
        color: '#555',
        marginRight: 10,
    },
    fieldValue: {
        flex: 3,
        backgroundColor: '#f9f9f9',
        padding: 8,
        borderRadius: 5,
        border: '1px solid #ddd',
    },
    bigFieldValue: {
        flex: 3,
        backgroundColor: '#f9f9f9',
        padding: 18,
        borderRadius: 5,
        border: '1px solid #ddd',
    },
    infoBox: {
        padding: 15,
        backgroundColor: '#e9ecef',
        borderRadius: 5,
        marginBottom: 20,
    },
    infoText: {
        fontSize: 10,
        color: '#555',
        marginBottom: 5,
    },
});

interface AanmeldProps {
    content?: string | null;
    aanmelding?: AanmeldingModel | null

}

const AanmeldPDFFile = ({ content, aanmelding }: AanmeldProps) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <Text style={styles.headerLeft}>Gemeente Den Haag</Text>
                        <View style={styles.headerRight}>
                            <Image
                                style={styles.headerImage}
                                src={Logo}
                            />
                            <Text>Dienst SZW</Text>
                        </View>
                    </View>

                    <Text style={styles.reference}>Referentie:</Text>


                    <View style={styles.fieldGroup}>
                        <Text style={styles.sectionTitle}>Aanmeldformulier</Text>
                    </View>


                    <View style={styles.fieldGroup}>
                        <Text style={styles.sectionTitle}>Contactgegevens gemeente</Text>
                        <View style={styles.fieldRow}>
                            <Text style={styles.fieldLabel}>Naam:</Text>
                            <Text style={styles.fieldValue}>{aanmelding?.gemeente || "N/A"}</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen match gevonden of bestaat niet in AameldingModel (String) */}
                            <Text style={styles.fieldLabel}>Functie:</Text>
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen match gevonden of bestaat niet in AameldingModel (Number) */}
                            <Text style={styles.fieldLabel}>Telefoon:</Text>
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen match gevonden of bestaat niet in AameldingModel (String) */}
                            <Text style={styles.fieldLabel}>Email:</Text>
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                    </View>


                    <View style={styles.fieldGroup}>
                        <Text style={styles.sectionTitle}>Contactgegevens kandidaat</Text>
                        <View style={styles.fieldRow}>
                            <Text style={styles.fieldLabel}>Naam kandidaat:</Text>
                            <Text style={styles.fieldValue}>{aanmelding?.volledige_naam || "N/A"}</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            {/* Geen match gevonden of bestaat niet in AameldingModel (Number) */}
                            <Text style={styles.fieldLabel}>Administratienummer:</Text>
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            <Text style={styles.fieldLabel}>Telefoon:</Text>
                            <Text style={styles.fieldValue}>{aanmelding?.telefoon || "N/A"}</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            <Text style={styles.fieldLabel}>Email kandidaat:</Text>
                            <Text style={styles.fieldValue}>{aanmelding?.email || "N/A"}</Text>
                        </View>
                    </View>


                    <View style={styles.fieldGroup}  wrap={false}>
                        <Text style={styles.sectionTitle}>Ontwikkeldoelen voor de voorziening</Text>
                        <View style={styles.fieldRow}>
                            {/* Geen match gevonden of bestaat niet in AameldingModel (String) */}
                            <Text style={styles.fieldLabel}>Datum aanmelding</Text>
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            <Text style={styles.fieldLabel}>Doel van deelname [ontwikkeldoel #1]</Text>
                            {/* Dropdown */}
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            <Text style={styles.fieldLabel}>Gewenste situatie [ontwikkeldoel #1]</Text>
                            {/* Geen match gevonden of bestaat niet in AameldingModel (String) */}
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            <Text style={styles.fieldLabel}>Waarmee moet kandidaat in zijn of haar persoonlijke situatie rekening houden?</Text>
                            {/* Geen match gevonden of bestaat niet in AameldingModel (String) */}
                            <Text style={styles.bigFieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            <Text style={styles.fieldLabel}>Zijn er dagdelen waarin de kandidaat niet beschikbaar is?</Text>
                            {/* Geen match gevonden of bestaat niet in AameldingModel (String) */}
                            <Text style={styles.bigFieldValue}>N/A</Text>
                        </View>
                        <View style={styles.fieldRow}>
                            <Text style={styles.fieldLabel}>Extra toelichting</Text>
                            {/* Geen match gevonden of bestaat niet in AameldingModel (String) */}
                            <Text style={styles.fieldValue}>N/A</Text>
                        </View>
                    </View>

                </View>
            </Page>
        </Document>
    );
}

export default AanmeldPDFFile