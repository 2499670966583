import Bijenkorf from '../../../components/subnav/bijenkorf/bijenkorf'
import Layout from '../../../layout'
import { useState } from 'react'

export default function Vacatures() {
  // Sample data for vacancies
  const [vacatures, setVacatures] = useState([
    {
      id: 1,
      titel: 'Werkbegeleider',
      locatie: 'Den Haag',
      startdatum: '01-02-2025',
    },
    {
      id: 2,
      titel: 'Casemanager',
      locatie: 'Rotterdam',
      startdatum: '15-02-2025',
    },
    {
      id: 3,
      titel: 'Administratief Medewerker',
      locatie: 'Amsterdam',
      startdatum: '01-03-2025',
    },
    {
      id: 4,
      titel: 'Projectleider',
      locatie: 'Utrecht',
      startdatum: '10-03-2025',
    },
  ])

  return (
    <Layout>
            <Bijenkorf />
      <div className="bg-gray-50 min-h-screen p-6">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">Openstaande Vacatures</h1>

          <div className="bg-white shadow-sm rounded-lg p-6">
            <table className="w-full border-collapse">
              <thead>
                <tr className="text-left bg-gray-100">
                  <th className="p-4 font-semibold">Functie</th>
                  <th className="p-4 font-semibold">Locatie</th>
                  <th className="p-4 font-semibold">Startdatum</th>
                  <th className="p-4 font-semibold">Acties</th>
                </tr>
              </thead>
              <tbody>
                {vacatures.map((vacature) => (
                  <tr key={vacature.id} className="border-t">
                    <td className="p-4">{vacature.titel}</td>
                    <td className="p-4">{vacature.locatie}</td>
                    <td className="p-4">{vacature.startdatum}</td>
                    <td className="p-4 space-x-3">
                      <a
                        href={`/vacatures/${vacature.id}`}
                        className="text-indigo-600 hover:text-indigo-500 font-medium"
                      >
                        Bekijken
                      </a>
                      <a
                        href={`/vacatures/bewerken/${vacature.id}`}
                        className="text-blue-600 hover:text-blue-500 font-medium"
                      >
                        Bewerken
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  )
}