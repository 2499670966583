
'use client'

import { useState } from 'react'
import {

  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react'
import {
  Bars3Icon,
  CalendarDaysIcon,
  CreditCardIcon,
  EllipsisVerticalIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  MapPinIcon,
  PaperClipIcon,
  UserCircleIcon,
  XMarkIcon as XMarkIconMini,
} from '@heroicons/react/20/solid'
import { BellIcon, XMarkIcon as XMarkIconOutline } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import Layout from '../../../layout'
import { RapportageModel } from '../../../domain/rapportage-utils'
import { get } from 'http'

const invoice = {
  subTotal: '$8,800.00',
  tax: '$1,760.00',
  total: '$10,560.00',
  items: [
    {
      id: 1,
      title: 'Logo redesign',
      description: 'In de afgelopen zes maanden heeft Gabriël een duidelijke positieve ontwikkeling doorgemaakt, zowel op het gebied van zijn taken als stallingbeheerder als in zijn persoonlijke groei, met name in zijn beheersing van de Nederlandse taal.',
      hours: '20.0',
      rate: '$100.00',
      price: '$2,000.00',
    },
    
  ],
}
const activity = [
  { id: 1, type: 'created', person: { name: 'Chelsea Hagon' }, date: '7d ago', dateTime: '2023-01-23T10:32' },
  { id: 2, type: 'edited', person: { name: 'Chelsea Hagon' }, date: '6d ago', dateTime: '2023-01-23T11:03' },
  { id: 3, type: 'sent', person: { name: 'Chelsea Hagon' }, date: '6d ago', dateTime: '2023-01-23T11:24' },
  {
    id: 4,
    type: 'commented',
    person: {
      name: 'Chelsea Hagon',
      imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    comment: 'Called client, they reassured me the invoice would be paid by the 25th.',
    date: '3d ago',
    dateTime: '2023-01-23T15:56',
  },
  { id: 5, type: 'viewed', person: { name: 'Alex Curren' }, date: '2d ago', dateTime: '2023-01-24T09:12' },
  { id: 6, type: 'paid', person: { name: 'Alex Curren' }, date: '1d ago', dateTime: '2023-01-24T09:20' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Rapportage() {
  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  // const [selected, setSelected] = useState(moods[5])
  const [rapportage, setRapportageted] = useState<RapportageModel[]>([])

  return (
    <>
      <Layout>
        <main>
          <header className="relative isolate pt-16">
            <div aria-hidden="true" className="absolute inset-0 -z-10 overflow-hidden">
              <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
                <div
                  style={{
                    clipPath:
                      'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                  }}
                  className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
                />
              </div>
              <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
            </div>

            <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
              <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                <div className="flex items-center gap-x-6 ">
                  <img
                    alt=""
                    src="https://www.carfo.nl/userfiles/carfo.nl/images/pasfoto27.jpg"
                    className="h-16 w-16 flex-none rounded-full ring-1 ring-gray-900/10 object-cover"
                  />
                  <h1>
                    <div className="text-sm leading-6 text-gray-500">
                      Document: <span className="text-gray-700">1e Rapportage</span>
                    </div>
                    <div className="mt-1 text-base font-semibold leading-6 text-gray-900">[Talent fullname]</div>
                  </h1>
                </div>
                <div className="flex items-center gap-x-4 sm:gap-x-6">
                  
                  <a href="#" className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block">
                    Bewerken
                  </a>
                  <a
                    href="#"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Versturen
                  </a>

                  <Menu as="div" className="relative sm:hidden">
                    <MenuButton className="-m-3 block p-3">
                      <span className="sr-only">More</span>
                      <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
                    </MenuButton>

                    <MenuItems
                      transition
                      className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                    >
                      <MenuItem>
                        <button
                          type="button"
                          className="block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                        >
                          Copy URL
                        </button>
                      </MenuItem>
                      <MenuItem>
                        <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                          Bewerken
                        </a>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                </div>
              </div>
            </div>
          </header>

          <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {/* Invoice summary */}
              <div className="lg:col-start-3 lg:row-end-1">
                <h2 className="sr-only">Summary</h2>
                <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                  <dl className="flex flex-wrap">
                    <div className="flex-auto pl-6 pt-6">
                      <dt className="text-sm font-semibold leading-6 text-gray-900">[Gemeente]</dt>
                      <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">[traject]</dd>
                    </div>
                    <div className="flex-none self-end px-6 pt-4">
                      <dt className="sr-only">Status</dt>
                      <dd className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                        [traject status]
                      </dd>
                    </div>
                    <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                      <dt className="flex-none">
                        <span className="sr-only">Client</span>
                        <UserCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm font-medium leading-6 text-gray-900">[auteur]</dd>
                    </div>
                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Due date</span>
                        <CalendarDaysIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">
                        <time dateTime="2023-01-31">[aanmaakdatum]</time>
                      </dd>
                    </div>
                    <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                      <dt className="flex-none">
                        <span className="sr-only">Status</span>
                        <MapPinIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                      </dt>
                      <dd className="text-sm leading-6 text-gray-500">[werklocatie]</dd>
                    </div>
                  </dl>
                  <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                    <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                      Document afdrukken <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                </div>
              </div>

              {/* Invoice */}
              <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
                <h2 className="text-base font-semibold leading-6 text-gray-900">[rapportage soort]</h2>
                <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                  <div className="sm:pr-4">
                    <dt className="inline text-gray-500">Gemaakt op</dt>{' '}
                    <dd className="inline text-gray-700">
                      <time dateTime="2023-23-01">[aanmaakdatum]</time>
                    </dd>
                  </div>
                  <div className="mt-2 sm:mt-0 sm:pl-4">
                    <dt className="inline text-gray-500">Startdatum</dt>{' '}
                    <dd className="inline text-gray-700">
                      <time dateTime="2023-31-01">[startdatum]</time>
                    </dd>
                  </div>
                  <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                    <dt className="font-semibold text-gray-900">[licientienemer]</dt>
                    <dd className="mt-2 text-gray-500">
                      <span className="font-medium text-gray-900">Auteur [auteur]</span>
                      <br />
                      Werkbedrijf [werkbedrijf]
                      <br />
                      Leidinggevende [leidinggevende]
                    </dd>
                  </div>
                  <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                    <dt className="font-semibold text-gray-900">[Gemeente]</dt>
                    <dd className="mt-2 text-gray-500">
                      <span className="font-medium text-gray-900">Consulent [consulent]</span>
                      <br />
                      Traject [traject]
                      <br />
                    </dd>
                  </div>
                </dl>
                <table className="mt-10 w-full whitespace-nowrap text-left text-sm leading-6">
                  <colgroup>
                    <col className="w-full" />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead className="border-b border-gray-200 text-gray-900">
                    <tr>
                      <th scope="col" className="px-0 py-3 font-semibold">
                        Verslag
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody className='text-sm leading-6 text-gray-500 text-wrap'>
                  <p>In de afgelopen zes maanden heeft Gabriël een duidelijke positieve ontwikkeling doorgemaakt, zowel op het gebied van zijn taken als stallingbeheerder als in zijn persoonlijke groei, met name in zijn beheersing van de Nederlandse taal.
                  </p>
                  </tbody>
                </table>
              </div>

              <div className="lg:col-start-3">
                {/* Activity feed */}
                <h2 className="text-sm font-semibold leading-6 text-gray-900">Activity</h2>
                <ul role="list" className="mt-6 space-y-6">
                  {activity.map((activityItem, activityItemIdx) => (
                    <li key={activityItem.id} className="relative flex gap-x-4">
                      <div
                        className={classNames(
                          activityItemIdx === activity.length - 1 ? 'h-6' : '-bottom-6',
                          'absolute left-0 top-0 flex w-6 justify-center',
                        )}
                      >
                        <div className="w-px bg-gray-200" />
                      </div>
                      {activityItem.type === 'commented' ? (
                        <>
                          <img
                            alt=""
                            src={activityItem.person.imageUrl}
                            className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                          />
                          <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                            <div className="flex justify-between gap-x-4">
                              <div className="py-0.5 text-xs leading-5 text-gray-500">
                                <span className="font-medium text-gray-900">{activityItem.person.name}</span> commented
                              </div>
                              <time
                                dateTime={activityItem.dateTime}
                                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                              >
                                {activityItem.date}
                              </time>
                            </div>
                            <p className="text-sm leading-6 text-gray-500">{activityItem.comment}</p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                            {activityItem.type === 'paid' ? (
                              <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-indigo-600" />
                            ) : (
                              <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                            )}
                          </div>
                          <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                            <span className="font-medium text-gray-900">{activityItem.person.name}</span>{' '}
                            {activityItem.type} the invoice.
                          </p>
                          <time
                            dateTime={activityItem.dateTime}
                            className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                          >
                            {activityItem.date}
                          </time>
                        </>
                      )}
                    </li>
                  ))}
                </ul>

                {/* New comment form */}
                <div className="mt-6 flex gap-x-3">
                  <img
                    alt=""
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    className="h-6 w-6 flex-none rounded-full bg-gray-50"
                  />
                  <form action="#" className="relative flex-auto">
                    <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                      <label htmlFor="comment" className="sr-only">
                        Add your comment
                      </label>
                      <textarea
                        id="comment"
                        name="comment"
                        rows={2}
                        placeholder="Add your comment..."
                        className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        defaultValue={''}
                      />
                    </div>

                    <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                      <div className="flex items-center space-x-5">
                        <div className="flex items-center">
                          <button
                            type="button"
                            className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                          >
                            <PaperClipIcon aria-hidden="true" className="h-5 w-5" />
                            <span className="sr-only">Attach a file</span>
                          </button>
                        </div>
                        <div className="flex items-center">
                          {/* <Listbox value={selected} onChange={setSelected}>
                          <Label className="sr-only">Your mood</Label>
                          <div className="relative">
                            <ListboxButton className="relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                              <span className="flex items-center justify-center">
                                {selected.value === null ? (
                                  <span>
                                    <FaceSmileIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
                                    <span className="sr-only">Add your mood</span>
                                  </span>
                                ) : (
                                  <span>
                                    <span
                                      className={classNames(
                                        selected.bgColor,
                                        'flex h-8 w-8 items-center justify-center rounded-full',
                                      )}
                                    >
                                      <selected.icon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-white" />
                                    </span>
                                    <span className="sr-only">{selected.name}</span>
                                  </span>
                                )}
                              </span>
                            </ListboxButton>

                            <ListboxOptions
                              transition
                              className="absolute z-10 -ml-6 mt-1 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:ml-auto sm:w-64 sm:text-sm"
                            >
                              {moods.map((mood) => (
                                <ListboxOption
                                  key={mood.value}
                                  value={mood}
                                  className="relative cursor-default select-none bg-white px-3 py-2 data-[focus]:bg-gray-100"
                                >
                                  <div className="flex items-center">
                                    <div
                                      className={classNames(
                                        mood.bgColor,
                                        'flex h-8 w-8 items-center justify-center rounded-full',
                                      )}
                                    >
                                      <mood.icon
                                        aria-hidden="true"
                                        className={classNames(mood.iconColor, 'h-5 w-5 flex-shrink-0')}
                                      />
                                    </div>
                                    <span className="ml-3 block truncate font-medium">{mood.name}</span>
                                  </div>
                                </ListboxOption>
                              ))}
                            </ListboxOptions>
                          </div>
                        </Listbox> */}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Comment
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>

  )
}
