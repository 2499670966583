import {
  ArrowsPointingInIcon,
  ChatBubbleBottomCenterIcon,
  ClockIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { Link, useLocation } from 'react-router-dom';

export default function Tabbarmobile({talentId}) {
  const location = useLocation();

  return (
    <div className="tabbar fixed bottom-0 left-0 z-50 w-full flex h-20 justify-around bg-white">
      <Link
        className={`flex flex-col items-center justify-center ${
          location.pathname === `/talentenhub/talentdetails/${talentId}` ? 'text-blue-600' : 'text-gray-500'
        }`}
        to={`/talentenhub/talentdetails/${talentId}`}
      >
        <div
          className={`h-10 w-10 flex items-center justify-center rounded-full ${
            location.pathname === `/talentenhub/talentdetails/${talentId}` ? 'bg-blue-100' : ''
          } transition-all duration-300`}
        >
          <UserGroupIcon
            aria-hidden="true"
            className={`h-6 w-6 ${
              location.pathname === `talentenhub/talentdetails/${talentId}` ? 'text-blue-600' : 'text-gray-400'
            } transition-colors duration-300`}
          />
        </div>
        <div className=" text-xs font-medium">Details</div>
      </Link>
      <Link
        className={`flex flex-col items-center justify-center ${
          location.pathname === `/vandaag/doelen/${talentId}` ? 'text-blue-600' : 'text-gray-500'
        }`}
        to={`/vandaag/doelen/${talentId}`}
      >
        <div
          className={`h-10 w-10 flex items-center justify-center rounded-full ${
            location.pathname === `/vandaag/doelen/${talentId}` ? 'bg-blue-100' : ''
          } transition-all duration-300`}
        >
          <ArrowsPointingInIcon
            aria-hidden="true"
            className={`h-6 w-6 ${
              location.pathname === `/vandaag/doelen/${talentId}` ? 'text-blue-600' : 'text-gray-400'
            } transition-colors duration-300`}
          />
        </div>
        <div className=" text-xs font-medium">Doelen</div>
      </Link>
      <Link
        className={`flex flex-col items-center justify-center ${
          location.pathname === `/vandaag/updates/${talentId}` ? 'text-blue-600' : 'text-gray-500'
        }`}
        to={`/vandaag/updates/${talentId}`}
      >
        <div
          className={`h-10 w-10 flex items-center justify-center rounded-full ${
            location.pathname === `/vandaag/updates/${talentId}` ? 'bg-blue-100' : ''
          } transition-all duration-300`}
        >
          <ChatBubbleBottomCenterIcon
            aria-hidden="true"
            className={`h-6 w-6 ${
              location.pathname === `/vandaag/updates/${talentId}` ? 'text-blue-600' : 'text-gray-400'
            } transition-colors duration-300`}
          />
        </div>
        <div className="text-xs font-medium">Updates</div>
      </Link>
      <Link
        className={`flex flex-col items-center justify-center ${
          location.pathname === `/vandaag/rooster/${talentId}` ? 'text-blue-600' : 'text-gray-500'
        }`}
        to={`/vandaag/rooster/${talentId}`}
      >
        <div
          className={`h-10 w-10 flex items-center justify-center rounded-full ${
            location.pathname === `/vandaag/rooster/${talentId}` ? 'bg-blue-100' : ''
          } transition-all duration-300`}
        >
          <ClockIcon
            aria-hidden="true"
            className={`h-6 w-6 ${
              location.pathname === `/vandaag/rooster/${talentId}` ? 'text-blue-600' : 'text-gray-400'
            } transition-colors duration-300`}
          />
        </div>
        <div className="text-xs font-medium">Rooster</div>
      </Link>
    </div>
  );
}