import { addDoc, collection, doc, DocumentReference, getDocs, limit, orderBy, query, QueryDocumentSnapshot, SnapshotOptions, where, WriteBatch } from "firebase/firestore"
import { db } from "../utils/firebase";
import { ActivityLogModel } from "../domain/activity-log";

const TABLE_NAME = "activity_log"

export enum Activity {
    DOCUMENT_CREATE = "DOCUMENT_CREATE",
    DOCUMENT_EDIT = "DOCUMENT_EDIT",
    DOCUMENT_DELETE = "DOCUMENT_DELETE",
    DOCUMENT_VIEW = "DOCUMENT_VIEW",
    DOCUMENT_DOWNLOAD = "DOCUMENT_DOWNLOAD",
    DOCUMENT_PRINT = "DOCUMENT_PRINT"
}

export type RemoteActivityLog = {
    activiteit: Activity,
    datum?: Date,
    document_ref: DocumentReference,
    user_ref: DocumentReference,
    data_eigenaar_ref?: DocumentReference
}

const activityLogConverter = {
    toFirestore: (activityLog: RemoteActivityLog) => {
        return {
            activiteit: activityLog.activiteit,
            datum: new Date(),
            document_ref: activityLog.document_ref,
            user_ref: activityLog.user_ref,
            data_eigenaar_ref: activityLog.data_eigenaar_ref
        }
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteActivityLog {
            const data = snapshot.data(options)!;
            return {
              activiteit: data.activiteit as Activity,
              datum: data.datum.toDate(),
              document_ref: data.document_ref,
              user_ref: data.user_ref
            }
        }
  }

export const getActivityLogDocumentsByDocumentReference = async (document: DocumentReference) => {
    const q = query(collection(db, TABLE_NAME), where("document_ref", "==", document), orderBy("datum", "desc"), limit(10)).withConverter(activityLogConverter);
    const querySnapshot = await getDocs(q);
    const list: RemoteActivityLog[] = [];

    querySnapshot.forEach((doc) => {
        list.push(doc.data());
    });

    return list;
}

export const createActivityLogDocument = (dataEigenaar: DocumentReference, activityLog: RemoteActivityLog, batch?: WriteBatch) => {
    if (batch) {
        const docSnap = doc(collection(db, TABLE_NAME).withConverter(activityLogConverter))
        return batch.set(docSnap, {...activityLog, data_eigenaar_ref: dataEigenaar})
    }
    return addDoc(collection(db, TABLE_NAME).withConverter(activityLogConverter), {...activityLog, data_eigenaar_ref: dataEigenaar})
}