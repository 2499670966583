import Layout from '../../../layout'
import { Fragment, useState } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/react'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingCartIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Bijenkorf from '../../../components/subnav/bijenkorf/bijenkorf'


const people = [
  {
    name: 'Lindsay Walton',
    title: 'Front-end Developer',
    department: 'Optimization',
    email: 'lindsay.walton@example.com',
    role: 'Member',
    image:
      'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  // More people...
]

const posts = [
  {
    id: 1,
    title: 'Iedereen werkt beter met ISS',
    href: '#',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl:
      'https://www.issworld.com/_next/image?url=https%3A%2F%2Fbrand.issworld.com%2Fm%2F102cf9d24d2e7512%2Fwebimage-ISSNL-Needs-in-FM-thema-s-in-FM-Diversiteit-Inclusie-en-Groepsgevoel-424x240-092024.png&w=1920&q=75',
    date: 'Facilitair',
    datetime: '2020-03-16',
    author: {
      name: '4 vacatures',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 1,
    title: 'De vastgoedoplossingen van morgen',
    href: '#',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl:
      'https://www.twinfm.com/assets/uploaded/images/pages/CBRE%20Hague_107.jpg',
    date: 'Facilitair',
    datetime: '2020-03-16',
    author: {
      name: '4 vacatures',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 1,
    title: 'Productiesoftware voor toekomstgerichte productiebedrijven',
    href: '#',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl:
      'https://www.computable.nl/wp-content/uploads/2021/03/7062850-700x500.jpg',
    date: 'Software',
    datetime: '2020-03-16',
    author: {
      name: '4 vacatures',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 1,
    title: 'Iedereen werkt beter met ISS',
    href: '#',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl:
      'https://www.issworld.com/_next/image?url=https%3A%2F%2Fbrand.issworld.com%2Fm%2F102cf9d24d2e7512%2Fwebimage-ISSNL-Needs-in-FM-thema-s-in-FM-Diversiteit-Inclusie-en-Groepsgevoel-424x240-092024.png&w=1920&q=75',
    date: 'Facilitair',
    datetime: '2020-03-16',
    author: {
      name: '4 vacatures',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 1,
    title: 'Iedereen werkt beter met ISS',
    href: '#',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl:
      'https://www.issworld.com/_next/image?url=https%3A%2F%2Fbrand.issworld.com%2Fm%2F102cf9d24d2e7512%2Fwebimage-ISSNL-Needs-in-FM-thema-s-in-FM-Diversiteit-Inclusie-en-Groepsgevoel-424x240-092024.png&w=1920&q=75',
    date: 'Facilitair',
    datetime: '2020-03-16',
    author: {
      name: '4 vacatures',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 1,
    title: 'Iedereen werkt beter met ISS',
    href: '#',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl:
      'https://www.issworld.com/_next/image?url=https%3A%2F%2Fbrand.issworld.com%2Fm%2F102cf9d24d2e7512%2Fwebimage-ISSNL-Needs-in-FM-thema-s-in-FM-Diversiteit-Inclusie-en-Groepsgevoel-424x240-092024.png&w=1920&q=75',
    date: 'Facilitair',
    datetime: '2020-03-16',
    author: {
      name: '4 vacatures',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 1,
    title: 'Iedereen werkt beter met ISS',
    href: '#',
    description:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl:
      'https://www.issworld.com/_next/image?url=https%3A%2F%2Fbrand.issworld.com%2Fm%2F102cf9d24d2e7512%2Fwebimage-ISSNL-Needs-in-FM-thema-s-in-FM-Diversiteit-Inclusie-en-Groepsgevoel-424x240-092024.png&w=1920&q=75',
    date: 'Facilitair',
    datetime: '2020-03-16',
    author: {
      name: '4 vacatures',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  // More posts...
]

const tabs = [
  { name: 'Hardlopers', href: '#', current: true },
  { name: 'Nieuw', href: '#', current: false },
  { name: 'Bijna klaar', href: '#', current: false },

]

//   function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
//   }



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Pool() {
  const [open, setOpen] = useState(false)
  return (
    <Layout>
      <div className="mx-auto lg:flex lg:gap-x-16 lg:px-2">
        <Bijenkorf />
      </div>

      <div className='mx-auto max-w-7xl px-6 lg:px-8'>

        <div className="bg-white py-24 sm:py-32">
          <div className="">
            <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
              <div className="mx-auto w-full max-w-xl lg:mx-0">
                <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  Bedrijven die plaats hebben voor jou
                </h2>
                <p className="mt-6 text-lg/8 text-gray-600">
                  Dankzij onderanderen deze bedrijven kunnen Talenten duurzaam uitstromen en krijgen een kans op een echte plek.
                </p>
                <div className="mt-8 flex items-center gap-x-6">
                  <a
                    href="#"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Uistroom melden
                  </a>
                  <a href="#" className="text-sm font-semibold text-gray-900">
                    Meer weten <span aria-hidden="true">&rarr;</span>
                  </a>
                </div>
              </div>
              <div className="mx-auto grid w-full max-w-xl grid-cols-2 items-center gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8">
                <img
                  alt="Tuple"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzGVuDI_9ZiMTDG3evK5KOFSRViBbA3WkGMQ&s"
                  width={105}
                  height={48}
                  className="max-h-12 w-full object-contain object-left"
                />
                <img
                  alt="Reform"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkOrLur6s4qwtf2zygP9xu1TBBNr_MU4P4fA&s"
                  width={104}
                  height={48}
                  className="max-h-12 w-full object-contain object-left"
                />
                <img
                  alt="SavvyCal"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo1mhcWCWWqIm0mjnpHp0gHNjh1EBx71ny9w&s"
                  width={140}
                  height={48}
                  className="max-h-12 w-full object-contain object-left"
                />
                <img
                  alt="Laravel"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfZGjPplUcit2cawIW649oErey4MG5ZqMqmA&s"
                  width={136}
                  height={48}
                  className="max-h-12 w-full object-contain object-left"
                />
                <img
                  alt="Transistor"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTb8D53KQPn-lnLQljpaMENVa2n056Fc3w_BA&s"
                  width={158}
                  height={48}
                  className="max-h-12 w-full object-contain object-left"
                />
                <img
                  alt="Statamic"
                  src="https://werkensterkt.nl/wp-content/uploads/2023/07/logo-werken-sterkt.png"
                  width={147}
                  height={48}
                  className="max-h-12 w-full object-contain object-left"
                />
              </div>
            </div>
          </div>
        </div>



        <div className="">
          <div className="mx-auto">

            <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {posts.map((post) => (
                <article
                  key={post.id}
                  className="relative isolate flex flex-col justify-end rounded-lg bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
                >
                  <img alt="" src={post.imageUrl} className="rounded-lg absolute inset-0 -z-10 h-full w-full object-cover blur-2xl z-1" />
                  <img alt="" src={post.imageUrl} className="rounded-lg absolute inset-0 -z-10 h-full w-full object-cover" />

                  <div className="absolute inset-0 -z-10 bg-gradient-to-t from-sky-900 via-sky-900/40 rounded-lg" />

                  <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm/6 text-gray-300">
                    <time dateTime={post.datetime} className="mr-8">
                      {post.date}
                    </time>
                    <div className="-ml-4 flex items-center gap-x-4">
                      <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                        <circle r={1} cx={1} cy={1} />
                      </svg>
                    </div>
                  </div>
                  <h3 className="mt-3 text-xl font-semibold text-white">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                </article>
              ))}
            </div>
          </div>
        </div>




      </div>
    </Layout>
  )
}