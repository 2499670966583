import dayjs from "dayjs"
import { LopendTrajectModel } from "../domain/lopend-traject-utils"
import { TrajectModel } from "../domain/traject-utils"
import { Traject } from "../datasource/interfaces/collection"

type ProgressItem = {
name: string,
status: string,
date: Date
}

export const getProgressItems = (traject?: Traject | null, lopendTraject?: LopendTrajectModel | null, getUpcoming = false) => {

    const steps: ProgressItem[] = []

    if (traject && lopendTraject?.begindatum) {
        let startDate = dayjs(lopendTraject?.begindatum)
        let endDate = dayjs(startDate).add(traject.duur, 'month')
        let differenceStartCurrentDate = dayjs().diff(startDate, 'day')
        let differenceStartEndDate = endDate.diff(startDate, 'day')
    
        steps.push({
          name: "Start traject",
          status: "complete",
          date: startDate.toDate()
        }, {
          name: "Ontplooiing",
          status: (endDate.toDate() < dayjs().toDate()) ? "complete" : "future",
          date: endDate.toDate()
        },
        )
    
        let praktijkVerklaring = traject.praktijkverklaring
        let loonwaardeMeting = traject.loonwaardemeting
        let talentPortfolio = traject.talentportfolio
    
        if (praktijkVerklaring) {
          steps.push({
            name: "Praktijkverklaring",
            status: (endDate.subtract(1, 'month').toDate() < dayjs().toDate()) ? "complete" : "future",
            date: endDate.subtract(1, 'month').toDate()
          })
        }
    
        if (loonwaardeMeting) {
          steps.push({
            name: "Loonwaardemeting",
            status: (endDate.subtract(1, 'month').toDate() < dayjs().toDate()) ? "complete" : "future",
            date: endDate.subtract(1, 'month').toDate()
          })
        }
    
        if (talentPortfolio) {
          steps.push({
            name: "Talentportfolio",
            status: (endDate.subtract(1, 'month').toDate() < dayjs().toDate()) ? "complete" : "future",
            date: endDate.subtract(1, 'month').toDate()
          })
        }
    
        let aantalRapportages = traject.aantal_rapportages
        let intervalRapportages = (aantalRapportages <= 0) ? 0 : differenceStartEndDate / (aantalRapportages + 1)
        let aantalEvaluatieMomenten = traject.evaluatie_momenten
        let intervalEvaluaties = (aantalEvaluatieMomenten <= 0) ? 0 : differenceStartEndDate / (aantalEvaluatieMomenten + 1)
        let aantalJobcoachInterventies = traject.jobcoach_interventies
        let intervalJobcoachInterventies = (aantalJobcoachInterventies <= 0) ? 0 : differenceStartEndDate / (aantalJobcoachInterventies + 1)
    
        for (let i = 1; i <= aantalRapportages; i++) {
          steps.push({
            name: i + "e rapportage",
            status: ((intervalRapportages * i) >= differenceStartCurrentDate) ? "future" : "complete",
            date: startDate.add(intervalRapportages * i, 'day').toDate()
          })
        }
    
        // komt eraan, is geweest, gedaan, niet gedaan
    
        for (let i = 1; i <= aantalEvaluatieMomenten; i++) {
          steps.push({
            name: i + "e evaluatiemoment",
            status: ((intervalEvaluaties * i) >= differenceStartCurrentDate) ? "future" : "complete",
            date: startDate.add(intervalEvaluaties * i, 'day').toDate()
          })
        }
    
        for (let i = 1; i <= aantalJobcoachInterventies; i++) {
          steps.push({
            name: i + "e jobcoach interventie",
            status: ((intervalJobcoachInterventies * i) >= differenceStartCurrentDate) ? "future" : "complete",
            date: startDate.add(intervalJobcoachInterventies * i, 'day').toDate()
          })
        }
    
        steps.sort((a, b) => dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1)
    
        for (let step of steps) {
          if (step.date >= dayjs().toDate()) {
            step.status = "current"
            break
          }
        }
    
      }

      if (getUpcoming) {
        return [steps.find((v) => v.status == "current")]
      }

      return steps

}