import React, { useEffect, useState } from "react";
import { CheckCircleIcon, ArrowLeftIcon } from "@heroicons/react/20/solid";
import Layout from "../../../layout";
import { AanmeldingModel, getAanmeldingById } from "../../../domain/aanmelding-utils";
import { useParams } from "react-router-dom";

export default function Aanmeldingdocument() {

  const { aanmeldingId } = useParams()

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [aanmelding, setAanmelding] = useState<AanmeldingModel>()

  useEffect(() => {
    if (aanmeldingId)
      getAanmeldingById(aanmeldingId).then((aanmelding) => setAanmelding(aanmelding))
  }, [])

  const candidate = {
    naam: "Sebastiaan van den Berg",
    geboortedatum: "15/12/1995",
    email: "sebastiaan@example.com",
    telefoonnummer: "+31 6 12345678",
    adres: {
      straat: "Prins Mauritslaan",
      huisnummer: "25",
      postcode: "2517 EZ",
      woonplaats: "Den Haag",
    },
    nationaliteit: "Nederlandse",
    afkomst: "Europees",
    traject: "In behandeling",
    consulent: "Amal Souif",
    trajectSoort: "Arbeidsbemiddeling",
    akkoord: {
      aanmelding: true,
      verwerking: true,
      delenGegevens: true,
    },
    situatieschets: "Het Talent heeft een sterke motivatie om weer aan het werk te gaan, maar heeft begeleiding nodig bij structuur en ritme.",
    doelstellingen: "Sebastiaan begeleiden naar een stabiele administratieve functie binnen 6 maanden.",
    bijzonderheden: "Sterk in communicatie, maar moeite met planning en structuur.",
  };

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  return (
    <Layout>
      <div className="px-6 py-8 mx-auto max-w-7xl">
        {/* Header */}
        <header className="mb-8 flex items-center justify-between">
          <div>
            <button
              onClick={() => window.history.back()}
              className="inline-flex items-center text-sm font-medium text-blue-600 hover:underline"
            >
              <ArrowLeftIcon className="h-5 w-5 mr-2" />
              Terug
            </button>
            <h1 className="mt-4 text-3xl font-semibold text-gray-900">Aanmelding</h1>
            <p className="text-gray-600">Bekijk de details van de aanmelding van {aanmelding?.volledige_naam}</p>
          </div>
          <div className="space-x-2">
            <button
              onClick={openPopup}
              className="inline-flex items-center px-4 py-2 border border-blue-500 text-blue-500 rounded-md shadow-sm text-sm font-medium bg-white hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Uitnodigen voor gesprek
            </button>
            <button
              onClick={() => (window.location.href = `/talentenhub/intakeformulier/${aanmelding?.id}`)}
              className="inline-flex items-center px-4 py-2 border border-green-500 text-green-500 rounded-md shadow-sm text-sm font-medium bg-white hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              Omzetten naar intake
            </button>
            <button
              className="inline-flex items-center px-4 py-2 border border-red-500 text-red-500 rounded-md shadow-sm text-sm font-medium bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Archiveren
            </button>
          </div>
        </header>

        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Talentgegevens */}
          <div className="lg:col-span-2 space-y-8">
            <section>
              <h2 className="text-xl font-medium text-gray-800">Talentgegevens</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-8 mt-4">
                <Detail label="Voornaam" value={aanmelding?.voornaam} />
                <Detail label="Achternaam" value={aanmelding?.achternaam} />
                <Detail label="Geboortedatum" value={aanmelding?.geboortedatum.toLocaleDateString()} />
                <Detail label="Geslacht" value={aanmelding?.geslacht} />
                <Detail label="Nationaliteit" value={aanmelding?.nationaliteit} />
                <Detail label="Afkomst" value={aanmelding?.afkomst} />
                <Detail label="Email" value={aanmelding?.email} />
                <Detail label="Telefoonnummer" value={aanmelding?.telefoon} />
                <Detail
                  label="Adres"
                  value={`${aanmelding?.straat} ${aanmelding?.huisnummer}, ${aanmelding?.postcode}, ${aanmelding?.woonplaats}`}
                />
              </div>
            </section>

            <section>
              <h2 className="text-xl font-medium text-gray-800">Verwachtingen</h2>
              <div className="mt-4 space-y-4">
                <Detail label="Korte situatieschets" value={aanmelding?.situatie_schets} />
                <Detail label="Bijzonderheden" value={aanmelding?.bijzonderheden} />
                <Detail label="Doelstellingen" value={aanmelding?.doelstellingen} />
              </div>
            </section>
          </div>

          {/* Trajectgegevens */}
          <aside className="space-y-8">
            <section>
              <h2 className="text-xl font-medium text-gray-800">Trajectgegevens</h2>
              <div className="mt-4 space-y-4">
                <Detail label="Soort traject" value={candidate.trajectSoort} />
                <Detail label="Consulent" value={candidate.consulent} />
              </div>
            </section>

            <section>
              <h2 className="text-xl font-medium text-gray-800">Akkoorden</h2>
              <ul className="mt-4 space-y-2">
                <Agreement text="Akkoord met aanmelding bij Dude Goed" agreed={true} />
                <Agreement text="Akkoord met verwerking van gegevens" agreed={true} />
                <Agreement text="Akkoord met delen van gegevens" agreed={true} />
              </ul>
            </section>
          </aside>
        </div>

        {/* Popup */}
        {isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-96">
              <h2 className="text-lg font-semibold text-gray-900">Uitnodigen voor gesprek</h2>
              <form
                className="space-y-4 mt-4"
                onSubmit={(e) => {
                  e.preventDefault();

                  // Gather form data
                  const datum = (document.getElementById("datum") as HTMLInputElement)?.value;
                  const tijd = (document.getElementById("tijd") as HTMLInputElement)?.value;
                  const bericht = (document.getElementById("bericht") as HTMLTextAreaElement)?.value;

                  // Use the email from the page data
                  const recipient = aanmelding?.email || "no-reply@example.com";
                  const subject = `Uitnodiging voor een gesprek op ${datum} om ${tijd} bij Dude Goed`;
                  const body = `
Beste ${aanmelding?.voornaam || "Talent"},

Hartelijk dank voor het maken van een afspraak met ons. We kijken ernaar uit om je te ontmoeten en samen aan de slag te gaan met je traject.

Hier zijn de details van je afspraak, ${aanmelding?.voornaam || "Talent"}:
- Datum: ${datum}
- Tijd: ${tijd}

Tijdens het gesprek nemen we rustig de tijd om je situatie te bespreken en te bekijken hoe we je het beste kunnen ondersteunen. Er is uiteraard ruimte om vragen te stellen en je doelen te bespreken.

Heeft u vooraf nog vragen of specifieke zaken die u met ons wilt delen? Aarzel dan niet om contact met ons op te nemen via 085 080 5733 of aanmelden@dudegoed.nl.

We willen ervoor zorgen dat je je op je gemak voelt en goed voorbereid bent. Mocht er iets veranderen in je beschikbaarheid, laat het ons dan even weten.

Wij kijken ernaar uit je te verwelkomen!

Met vriendelijke groet,
Dude Goed
          `;

                  // Open email client with mailto link
                  window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(
                    subject
                  )}&body=${encodeURIComponent(body)}`;

                  // Close the popup
                  closePopup();
                }}
              >
                <div>
                  <label htmlFor="datum" className="block text-sm font-medium text-gray-700">
                    Datum
                  </label>
                  <input
                    type="date"
                    id="datum"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="tijd" className="block text-sm font-medium text-gray-700">
                    Tijd
                  </label>
                  <input
                    type="time"
                    id="tijd"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    required
                  />
                </div>
                {/* <div>
                  <label htmlFor="bericht" className="block text-sm font-medium text-gray-700">
                    Bericht
                  </label>
                  <textarea
                    id="bericht"
                    rows={3}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    required
                  ></textarea>
                </div> */}
                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={closePopup}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Annuleren
                  </button>
                  <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Versturen
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

const Detail = ({ label, value }: { label?: string; value?: string }) => (
  <div>
    <h3 className="text-sm font-medium text-gray-500">{label}</h3>
    <p className="text-base text-gray-900">{value}</p>
  </div>
);

const Agreement = ({ text, agreed }: { text: string; agreed: boolean }) => (
  <li className="flex items-center">
    <CheckCircleIcon className={`h-5 w-5 ${agreed ? "text-green-500" : "text-gray-300"} mr-2`} />
    <span className="text-sm text-gray-700">{text}</span>
  </li>
);