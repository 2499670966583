
import { PlusIcon } from '@heroicons/react/20/solid'
import Layout from '../../../layout'
import Beheer from '../../../components/subnav/beheer/Beheer'
import { useEffect, useState } from 'react'
import { User } from 'firebase/auth'
import { getAllNonTalents, UserModel } from '../../../domain/user-utils'
import { useAuth } from '../../../context/AuthContext'
import DefaultImage from '../../../components/defaultimage/defaultimage'


const people = [
    {
        name: 'Leslie Alexander',
        email: 'leslie.alexander@example.com',
        role: 'Co-Founder / CEO',
        imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        lastSeen: '3h ago',
        lastSeenDateTime: '2023-01-23T13:23Z',
    },
    {
        name: 'Michael Foster',
        email: 'michael.foster@example.com',
        role: 'Co-Founder / CTO',
        imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        lastSeen: '3h ago',
        lastSeenDateTime: '2023-01-23T13:23Z',
    },
    {
        name: 'Dries Vincent',
        email: 'dries.vincent@example.com',
        role: 'Business Relations',
        imageUrl:
            'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        lastSeen: null,
    },
    {
        name: 'Lindsay Walton',
        email: 'lindsay.walton@example.com',
        role: 'Front-end Developer',
        imageUrl:
            'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        lastSeen: '3h ago',
        lastSeenDateTime: '2023-01-23T13:23Z',
    },
    {
        name: 'Courtney Henry',
        email: 'courtney.henry@example.com',
        role: 'Designer',
        imageUrl:
            'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        lastSeen: '3h ago',
        lastSeenDateTime: '2023-01-23T13:23Z',
    },
    {
        name: 'Tom Cook',
        email: 'tom.cook@example.com',
        role: 'Director of Product',
        imageUrl:
            'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        lastSeen: null,
    },
]

export default function Example() {

    const { user } = useAuth()

    const [personen, setPersonen] = useState<UserModel[]>([])

    useEffect(() => {
        getAllNonTalents(user!).then((personen) => setPersonen(personen))
    }, [])

    return (
        <Layout>

            <div className="mx-auto lg:flex lg:gap-x-16 lg:px-8">
                <div>
                    <Beheer />
                </div>

                <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
                <div className="mx-auto max-w-md sm:max-w-3xl">
                    <div>
                        <div className="text-center">
                            <svg
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                                className="mx-auto h-12 w-12 text-gray-400"
                            >
                                <path
                                    d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <h2 className="mt-2 text-base font-semibold text-gray-900">Add Casemanagers</h2>
                            <p className="mt-1 text-sm text-gray-500">You haven’t added any team members to your project yet.</p>
                        </div>
                        <form action="#" className="mt-6 sm:flex sm:items-center">
                            <label htmlFor="emails" className="sr-only">
                                Email addresses
                            </label>
                            <div className="grid grid-cols-1 sm:flex-auto">
                                <input
                                    id="emails"
                                    name="emails"
                                    type="text"
                                    placeholder="Enter an email"
                                    className="peer relative col-start-1 row-start-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6"
                                />
                                <div
                                    aria-hidden="true"
                                    className="col-start-1 col-end-3 row-start-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 peer-focus:ring-2 peer-focus:ring-indigo-600"
                                />
                                <div className="col-start-2 row-start-1 flex items-center">
                                    <span aria-hidden="true" className="h-4 w-px flex-none bg-gray-200" />
                                    <label htmlFor="role" className="sr-only">
                                        Role
                                    </label>
                                    <select
                                        id="role"
                                        name="role"
                                        className="rounded-md border-0 bg-transparent py-1.5 pl-4 pr-7 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    >
                                        {/* Gemeente */}
                                        <option>Gemeente Accounthouder</option>
                                        {/* Gemeente */}
                                        <option>Gemeente Begeleider</option>
                                        {/* Uitstroom */}
                                        <option>Uitstroom Consulent</option>
                                        {/* Jobcoach */}
                                        <option>Jobcoach</option>
                                        {/* Extern */}
                                        <option>Werkbedrijf</option>
                                        {/* Intern */}
                                        <option>Consulent Intern</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mt-3 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                                <button
                                    type="submit"
                                    className="block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Send invite
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="mt-10">
                        <h3 className="text-sm font-medium text-gray-500">Recommended team members</h3>
                        <ul role="list" className="divide-y divide-gray-100">
                            {personen.map((persoon) => (
                                <li key={persoon?.email} className="flex justify-between gap-x-6 py-5">
                                    <div className="flex min-w-0 gap-x-4">
                                        <DefaultImage profiel_foto={persoon?.profiel_foto} className={"h-12 w-12 flex-none rounded-full bg-gray-50"} />
                                        <div className="min-w-0 flex-auto">
                                            <p className="text-sm/6 font-semibold text-gray-900">{persoon?.voornaam} {persoon?.achternaam}</p>
                                            <p className="mt-1 truncate text-xs/5 text-gray-500">{persoon?.email}</p>
                                        </div>
                                    </div>
                                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                                        <p className="text-sm/6 text-gray-900">{persoon?.gebruikers_rol}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                </main>
            </div>

        </Layout>
    )
}
