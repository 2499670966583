import { addDoc, and, collection, doc, DocumentReference, getCountFromServer, getDoc, getDocs, limit, orderBy, query, QueryDocumentSnapshot, SnapshotOptions, updateDoc, where } from "firebase/firestore";
import { db } from "../utils/firebase";

export type RemoteAanmelding = {
    reference: DocumentReference,
    voornaam: string,
    achternaam: string,
    geboortedatum: Date,
    geslacht: string,
    email: string,
    telefoon: string,
    nationaliteit: string,
    afkomst: string,
    straat: string,
    huisnummer: string,
    postcode: string,
    woonplaats: string,
    traject_reference: DocumentReference,
    gemeente: string,
    consulent_reference: DocumentReference,
    situatie_schets: string,
    doelstellingen: string,
    bijzonderheden: string,
    aanmaak_datum: Date,
    aangemaakt_door_reference: DocumentReference
};

export type NewRemoteAanmelding = {
    data_eigenaar_ref: DocumentReference,
    voornaam: string,
    achternaam: string,
    geboortedatum: Date,
    geslacht: string,
    email: string,
    telefoon: string,
    nationaliteit: string,
    afkomst: string,
    straat: string,
    huisnummer: string,
    postcode: string,
    woonplaats: string,
    traject_reference?: DocumentReference,
    gemeente: string,
    consulent_reference?: DocumentReference,
    situatie_schets: string,
    doelstellingen: string,
    bijzonderheden: string,
    aanmaak_datum: Date,
    aangemaakt_door_reference?: DocumentReference
    afgemeld_op?: null
};

const aanmeldingConverter = {
    toFirestore: (aanmelding: RemoteAanmelding) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteAanmelding {
        const data = snapshot.data(options)!;
        return {
            reference: snapshot.ref,
            voornaam: data.voornaam,
            achternaam: data.achternaam,
            geboortedatum: data.geboortedatum.toDate(),
            geslacht: data.geslacht,
            email: data.email,
            telefoon: data.telefoon,
            nationaliteit: data.nationaliteit,
            afkomst: data.afkomst,
            straat: data.straat,
            huisnummer: data.huisnummer,
            postcode: data.postcode,
            woonplaats: data.woonplaats,
            traject_reference: data.traject_reference,
            gemeente: data.gemeente,
            consulent_reference: data.consulent_reference,
            situatie_schets: data.situatie_schets,
            doelstellingen: data.doelstellingen,
            bijzonderheden: data.bijzonderheden,
            aanmaak_datum: data.aanmaak_datum.toDate(),
            aangemaakt_door_reference: data.aangemaakt_door_reference
        }
    }
}

export const getAanmeldingDocuments = async (dataOwner: DocumentReference, max: number) => {
    let q = query(collection(db, "aanmelding"), and(where("data_eigenaar_ref", "==", dataOwner), where("afgemeld_op", "==", null)), orderBy("aanmaak_datum", "desc"), limit(max)).withConverter(aanmeldingConverter)

    const querySnapshot = await getDocs(q);
    const list: RemoteAanmelding[] = []
    querySnapshot.forEach((doc) => {
        return list.push(doc.data());
    });

    return list;
}

export const getAfgemeldeAanmeldingDocuments = async (dataOwner: DocumentReference, max: number) => {
    let q = query(collection(db, "aanmelding"), and(where("data_eigenaar_ref", "==", dataOwner), where("afgemeld_op", "!=", null)), orderBy("aanmaak_datum", "desc"), limit(max)).withConverter(aanmeldingConverter)

    const querySnapshot = await getDocs(q);
    const list: RemoteAanmelding[] = []
    querySnapshot.forEach((doc) => {
        return list.push(doc.data());
    });

    return list;
}

export const getAanmeldingDocumentById = async (id: string) => {
    const reference = await doc(db, "aanmelding", id).withConverter(aanmeldingConverter)
    const docSnap = await getDoc(reference);

    if (!docSnap.exists())
        return null

    return docSnap.data()
}

export const createAanmeldingDocument = (data: NewRemoteAanmelding) => {
    data.afgemeld_op = null
    return addDoc(collection(db, "aanmelding"), data)
}

export const afmeldenAanmeldingDocument = (id: string) => {
    return updateDoc(doc(db, "aanmelding", id), {afgemeld_op: new Date()})
}

export const getCountAanmeldingenByTraject = async (reference: DocumentReference, dataOwner: DocumentReference) => {
    const q = query(collection(db, "aanmelding"), and(where("data_eigenaar_ref", "==", dataOwner), where("traject_reference", "==", reference)));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count
}