'use client'

import { useState, useEffect } from 'react';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { useSpring, useTrail, animated } from '@react-spring/web';

export default function Example() {
    const [open, setOpen] = useState(true);

    // Background animation using useSpring
    const backgroundAnimation = useSpring({
        opacity: open ? 1 : 0,
        transform: open ? 'translateY(0%)' : 'translateY(-100%)',
        config: { tension: 170, friction: 26 },
    });

    // Trail animation for text
    const textItems = [
        'Welkom bij de Bloeiindex',
        'Ben je er al klaar voor? De Talenten worden ingeladen. Een ogenblik geduld aub.',
    ];
    const trail = useTrail(textItems.length, {
        opacity: open ? 1 : 0,
        x: open ? 0 : 20,
        from: { opacity: 0, x: 20 },
        config: { tension: 170, friction: 26 },
    });

    

    return (
        <Dialog open={open} onClose={() => setOpen(false)} className="items-center justify-center z-10">
            <animated.div
                style={backgroundAnimation}
                className="fixed inset-0 transition-opacity bg-white"
            >
                <DialogBackdrop className="fixed inset-0 transition-opacity bg-orange-100" />
            </animated.div>
            <div className="fixed inset-0 z-10 w-screen flex items-center justify-center">
                {/* Ensure vertical centering with flex and height */}
                <div className="flex flex-col items-center justify-center min-h-screen p-4 text-center">
                    <DialogPanel className="relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div className="mt-4">
                            <div className="mx-auto flex justify-center">
                                <animated.img
                                    src="/BI-blue@4x.png" // Replace with the correct path to your logo
                                    alt="Bloeiindex Logo"
                                    className="h-22 sm:h-12 w-auto object-contain"
                                    style={backgroundAnimation}
                                />
                            </div>
                            <div className="mt-4">
                                {trail.map(({ x, ...style }, index) => (
                                    <animated.div
                                        key={index}
                                        style={{
                                            ...style,
                                            transform: x.to((value) => `translate3d(0,${value}px,0)`),
                                        }}
                                        className={index === 0 ? 'text-base text-center font-semibold text-gray-900' : 'text-sm text-center text-gray-500'}
                                    >
                                        {textItems[index]}
                                    </animated.div>
                                ))}
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}