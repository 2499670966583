import Layout from "../layout";
import { useEffect, useState } from "react";
import { EvaluatieModel, getAllEvaluaties } from './../domain/evaluatie-utils';
import { getRapportageSignalen, SignaalModel } from "../domain/signalen-utils";
import Grafiek from "../components/charts/barchart/Grafiek";
import Aanmeldingen from "../components/lists/aanmeldingen/Aanmeldingen";
import Griddocuments from "../components/grid/documents/Griddocuments";
import { AanmeldingModel, getAanmeldingen } from "../domain/aanmelding-utils";
import TrajectTab from "../components/traject/App";
import { useAuth } from "../context/AuthContext";
import { fetchRapportages, RapportageModel } from "../domain/rapportage-utils";
import Mainloader from "../components/loader/mainloader/Mainloader";
import Drawersignalen from "../components/drawer/drawersignalen/Drawersignalen";
import Signalen from "../components/lists/signalen/Signalen";
import { Button } from "@headlessui/react";

function Home() {
  const [aanmeldingen, setAanmeldingen] = useState<AanmeldingModel[]>([]);
  const [signalen, setSignalen] = useState<SignaalModel[]>([]);
  const [evaluaties, setEvaluaties] = useState<EvaluatieModel[]>([]);
  const [rapportages, setRapportages] = useState<RapportageModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false)

  const { user } = useAuth();

  useEffect(() => {
    Promise.all([
      getAanmeldingen(user!, { aangemaaktDoor: true }),
      getAllEvaluaties(user!, 7),
      fetchRapportages(5, user!, { talent: true })
    ]).then(([aanmeldingen, evaluaties, rapportages]) => {
      setAanmeldingen(aanmeldingen);
      setEvaluaties(evaluaties);
      setRapportages(rapportages);
      setIsLoading(false);
      getRapportageSignalen(user!, 7).then((signalen) => setSignalen(signalen))
    });
  }, []);
  const showSignalen = () => {
    setOpen(true)
  }
  if (isLoading) return <Mainloader />;

  return (
    <Layout>
      <div className=" mx-auto  ">
        <h1 className="sr-only">Home</h1>

        {/* Eerste sectie */}
        <div className="grid grid-cols-1 lg:grid-cols-3 sm:gap-6  overflow-hidden ">
          {/* Linker kolom */}
          <div className="lg:col-span-2">
            <div className="bg-orange-50 rounded-lg p-6 h-full flex flex-col justify-between">
              <h3 className="md:text-4xl text-2xl text-gray-900 leading-7">
                Welkom 👋🏻 {user?.voornaam} {user?.achternaam}, dit is jouw score:
              </h3>
              <div>
                <h3 className="md:text-9xl text-5xl font-thin text-gray-900 pt-4">BI 70%</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Laatste update: 10 minuten geleden
                </p>
              </div>
              <div className="overflow-hidden hidden sm:block">
                <Grafiek />
              </div>
            </div>
          </div>

          {/* Rechter kolom */}
          <div className="bg-white rounded-lg p-6 ">
            <section aria-labelledby="section-2-title">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="text-base font-semibold text-gray-900">Signalen</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Dit ligt er op mijn bordje
                  </p>
                </div>
                <div>
                  <Drawersignalen user={user!} setOpen={setOpen} open={open} />
                  <Button
                    onClick={showSignalen}
                    className="inline-flex items-center rounded-md bg-blue-100 px-3 py-2 text-sm font-semibold text-blue-700 hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-sky-500"
                  >
                    Bekijk Signalen
                  </Button>
                </div>
              </div>

              <div className="mt-6">
                <Signalen signalen={signalen} isLoading={isLoading} />
              </div>
            </section>
          </div>
        </div>

        {/* Tweede sectie */}
        <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Linker kolom */}
          <div className="bg-white rounded-lg p-6">
            <section aria-labelledby="section-3-title">
              <h3 className="text-base font-semibold text-gray-900">
                Laatste rapportages
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Hoe goed gaat het nou echt
              </p>
              <div className="mt-6">
                <Griddocuments rapportages={rapportages} />
              </div>
            </section>
          </div>

          {/* Middelste kolom */}
          <div className="bg-white rounded-lg p-6">
            <section aria-labelledby="section-4-title">
              <h3 className="text-base font-semibold text-gray-900">
                Nieuwe aanmeldingen
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Deze toppers staan te popelen
              </p>
              <div className="mt-6">
                <Aanmeldingen aanmeldingen={aanmeldingen} />
              </div>
            </section>
          </div>

          {/* Rechter kolom */}
          <div className="bg-white rounded-lg p-6">
            <section aria-labelledby="section-5-title">
              {TrajectTab(evaluaties)}
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;