import { DocumentReference, writeBatch } from "firebase/firestore";
import _getRapportages, { getRapportageByTalentRef as _getRapportageByTalentRef, getRapportageByRef as _getRapportageByRef, getRapportageDocumentById, createRapportageDocument, updateRapportageDocumentById } from "../datasource/rapportage-utils";
import { TalentModel } from "./talent-utils";
import { getBedrijfById, getBedrijfByReference } from "./bedrijf-utils";
import { getTrajectById } from "./traject-utils";
import { _getUserById, getUserById, UserModel } from "./user-utils";
import { getTalentDocumentById } from "../datasource/talent-utils";
import rapportageUtils from "../datasource/rapportage-utils";
import { getLicentienemerDocumentById } from "../datasource/licentienemer-utils";
import { Activity, createActivityLogDocument } from "../datasource/activity-log";
import { db } from "../utils/firebase";
import { getLopendTrajectById } from "./lopend-traject-utils";

export type RapportageModel = {
    reference: DocumentReference,
    id: string,
    aanmaakdatum: Date,
    bedrijf_id?: string,
    bedrijf?: {
        naam?: string
    }
    consulent_id?: string,
    consulent?: {
        naam?: string
    }
    content: string,
    leidinggevende_id?: string,
    leidinggevende?: {
        naam?: string
    }
    traject_id?: string,
    traject?: {
        naam?: string
    },
    talent_id?: string,
    talent?: {
        id: string,
        naam: string,
        profiel_foto?: string
    }
    auteur_id?: string,
    auteur?: {
        naam?: string
    },
    lopend_traject?: {
        status?: string
    },
    licentienemer?: string,
    soort: string,
    startdatum: Date,
};

export type NewRapportageModel = {
    bedrijf_id: string,
    consulent_id: string,
    leidinggevende_id: string,
    traject_id: string,
    talent_id: string,
    auteur_id: string,
    soort: string,
    startDatum: Date,
    content: string,
    data_eigenaar: DocumentReference
}

export const fetchRapportages = async (max: number, user: UserModel, options?: {bedrijf?: boolean, consulent?: boolean, leidinggevende?: boolean, traject?: boolean, talent?: boolean, auteur?: boolean}) => {

    const list = await _getRapportages(max, user?.data_eigenaar_ref!);

    const rapportages: RapportageModel[] = []

    for (let rapportage of list) {
        let rapportageModel: RapportageModel = {
            reference: rapportage.reference,
            id: rapportage.reference.id,
            aanmaakdatum: rapportage.aanmaak_datum, 
            content: rapportage.content,
            soort: rapportage.soort,
            startdatum: rapportage.start_datum,
            talent: {
                id: rapportage.talent.reference,
                naam: `${rapportage.talent.voornaam} ${rapportage.talent.achternaam}`,
                profiel_foto: rapportage.talent.profiel_foto ?? undefined
            },
            auteur: {
                naam: `${rapportage.auteur.voornaam} ${rapportage.auteur.achternaam}`,
            }
        }

        if (options?.bedrijf) {
            let bedrijf = await getBedrijfByReference(rapportage.werkbedrijf_ref)
            rapportageModel.bedrijf = {
                naam: bedrijf?.naam
            } 
        } else {
            rapportageModel.bedrijf_id = rapportage.werkbedrijf_ref.id
        }

        if (options?.consulent) {
            let consulent = await getUserById(rapportage.consulent_ref)
            rapportageModel.consulent = {
                naam: `${consulent?.voornaam} ${consulent?.achternaam}`
            } 
        } else {
            rapportageModel.consulent_id = rapportage.consulent_ref.id
        }

        if (options?.leidinggevende) {
            let leidinggevende = await getUserById(rapportage.leidinggevende_ref)
            rapportageModel.leidinggevende = {
                naam: `${leidinggevende?.voornaam} ${leidinggevende?.achternaam}`
            } 
        } else {
            rapportageModel.leidinggevende_id = rapportage.leidinggevende_ref.id
        }

        if (options?.traject) {
            let traject = await getTrajectById(rapportage.traject_ref.id)
            rapportageModel.traject = {
                naam: traject?.naam
            } 
        } else {
            rapportageModel.traject_id = rapportage.traject_ref.id
        }

        // if (options?.talent) {
        //     let talent = await getUserById(rapportage.talent_ref)
        //     rapportageModel.talent = {
        //         id: `${talent?.ref?.id}`,
        //         naam: `${talent?.voornaam} ${talent?.achternaam}`,
        //         profiel_foto: talent?.profiel_foto
        //     } 
        // } else {
        //     rapportageModel.talent_id = rapportage.talent_ref.id
        // }

        // if (options?.auteur) {
        //     let auteur = await getUserById(rapportage.auteur_ref)
        //     rapportageModel.auteur = {
        //         naam: `${auteur?.voornaam} ${auteur?.achternaam}`
        //     } 
        // } else {
        //     rapportageModel.auteur_id = rapportage.auteur_ref.id
        // }

        rapportages.push(rapportageModel)

    }

    return rapportages
}


export const getRapportages = async (max: number, user: UserModel) => {
    const rapportages = await fetchRapportages(max, user);
    return rapportages;
}

export const getRapportageByTalentRef = async (user: UserModel, talentModel?: TalentModel, options?: {bedrijf?: boolean, consulent?: boolean, leidinggevende?: boolean, traject?: boolean, talent?: boolean, auteur?: boolean}) => {

    if (!talentModel) return []

    const list = await _getRapportageByTalentRef(talentModel.ref, user?.data_eigenaar_ref!);
    
    const modelList: RapportageModel[] = []

    for (let rapportage of list) {
        let rapportageModel: RapportageModel = {
            reference: rapportage.reference,
            id: rapportage.reference.id,
            aanmaakdatum: rapportage.aanmaak_datum, 
            content: rapportage.content,
            soort: rapportage.soort,
            startdatum: rapportage.start_datum
        }

        if (options?.bedrijf) {
            let bedrijf = await getBedrijfByReference(rapportage.werkbedrijf_ref)
            rapportageModel.bedrijf = {
                naam: bedrijf?.naam
            } 
        } else {
            rapportageModel.bedrijf_id = rapportage.werkbedrijf_ref.id
        }

        if (options?.consulent) {
            let consulent = await getUserById(rapportage.consulent_ref)
            rapportageModel.consulent = {
                naam: `${consulent?.voornaam} ${consulent?.achternaam}`
            } 
        } else {
            rapportageModel.consulent_id = rapportage.consulent_ref.id
        }

        if (options?.leidinggevende) {
            let leidinggevende = await getUserById(rapportage.leidinggevende_ref)
            rapportageModel.leidinggevende = {
                naam: `${leidinggevende?.voornaam} ${leidinggevende?.achternaam}`
            } 
        } else {
            rapportageModel.leidinggevende_id = rapportage.leidinggevende_ref.id
        }

        if (options?.traject) {
            let traject = await getTrajectById(rapportage.traject_ref.id)
            rapportageModel.traject = {
                naam: traject?.naam
            } 
        } else {
            rapportageModel.traject_id = rapportage.traject_ref.id
        }

        if (options?.talent) {
            let talent = await getUserById(rapportage.talent_ref)
            rapportageModel.talent = {
                id: talent!.ref.id,
                naam: `${talent?.voornaam} ${talent?.achternaam}`,
                profiel_foto: talent?.profiel_foto
            } 
        } else {
            rapportageModel.talent_id = rapportage.talent_ref.id
        }

        if (options?.auteur) {
            let auteur = await getUserById(rapportage.auteur_ref)
            rapportageModel.auteur = {
                naam: `${auteur?.voornaam} ${auteur?.achternaam}`
            } 
        } else {
            rapportageModel.auteur_id = rapportage.auteur_ref.id
        }

        modelList.push(rapportageModel)
    };

    return modelList
}

export const getRapportageById = async (id: string, user: UserModel, options?: {bedrijf?: boolean, consulent?: boolean, leidinggevende?: boolean, traject?: boolean, talent?: boolean, auteur?: boolean, lopend_traject?: boolean, licentieemer?: boolean}) => {
    const rapportage = await getRapportageDocumentById(id)

    if (!rapportage) return Promise.reject("Geen rapportage gevonden")

        let rapportageModel: RapportageModel = {
            reference: rapportage.reference,
            id: rapportage.reference.id,
            aanmaakdatum: rapportage.aanmaak_datum, 
            content: rapportage.content,
            soort: rapportage.soort,
            startdatum: rapportage.start_datum
        }

        if (options?.bedrijf) {
            let bedrijf = await getBedrijfByReference(rapportage.werkbedrijf_ref)
            rapportageModel.bedrijf = {
                naam: bedrijf?.naam
            } 
        } else {
            rapportageModel.bedrijf_id = rapportage.werkbedrijf_ref.id
        }

        if (options?.consulent) {
            let consulent = await getUserById(rapportage.consulent_ref)
            rapportageModel.consulent = {
                naam: `${consulent?.voornaam} ${consulent?.achternaam}`
            } 
        } else {
            rapportageModel.consulent_id = rapportage.consulent_ref.id
        }

        if (options?.leidinggevende) {
            let leidinggevende = await getUserById(rapportage.leidinggevende_ref)
            rapportageModel.leidinggevende = {
                naam: `${leidinggevende?.voornaam} ${leidinggevende?.achternaam}`
            } 
        } else {
            rapportageModel.leidinggevende_id = rapportage.leidinggevende_ref.id
        }

        if (options?.traject) {
            let traject = await getTrajectById(rapportage.traject_ref.id)
            rapportageModel.traject = {
                naam: traject?.naam
            } 
        } else {
            rapportageModel.traject_id = rapportage.traject_ref.id
        }

        if (options?.talent) {
            let talent = await getUserById(rapportage.talent_ref)
            rapportageModel.talent = {
                id: `${talent?.ref?.id}`,
                naam: `${talent?.voornaam} ${talent?.achternaam}`,
                profiel_foto: talent?.profiel_foto
            } 
        } else {
            rapportageModel.talent_id = rapportage.talent_ref.id
        }

        if (options?.auteur) {
            let auteur = await getUserById(rapportage.auteur_ref)
            rapportageModel.auteur = {
                naam: `${auteur?.voornaam} ${auteur?.achternaam}`
            } 
        } else {
            rapportageModel.auteur_id = rapportage.auteur_ref.id
        }

        if (options?.lopend_traject) {
            let talent = await getTalentDocumentById(rapportage.talent_ref)
            
            let lopendTraject = await getLopendTrajectById(talent?.talent?.lopendtraject_ref.id)
            rapportageModel.lopend_traject = {
                status: lopendTraject?.status
            } 
        }

        if (options?.licentieemer) {
            let licentienemer = await getLicentienemerDocumentById(rapportage.data_eigenaar_ref.id)
            rapportageModel.licentienemer = licentienemer?.naam
        }

        await createActivityLogDocument(rapportage.data_eigenaar_ref, {activiteit: Activity.DOCUMENT_VIEW, document_ref: rapportage.reference, user_ref: user.ref})
    
    return rapportageModel
}

export const getRapportageByRef = (rapportageModel: RapportageModel) => {
    return getRapportageDocumentById(rapportageModel.id);
}

export const createRapportage = async (data: NewRapportageModel) => {

    const bedrijf = await getBedrijfById(data.bedrijf_id)
    const consulent = await _getUserById(data.consulent_id)
    const leidinggevende = await _getUserById(data.leidinggevende_id)
    const traject = await getTrajectById(data.traject_id)
    const talent = await getTalentDocumentById(data.talent_id)
    const auteur = await _getUserById(data.auteur_id)

    if (!bedrijf || !consulent || !leidinggevende || !traject || !talent || !auteur)
        return Promise.reject("Geen complete data.")

    const batch = writeBatch(db)

    const rapportageRef = createRapportageDocument(data, bedrijf.reference, consulent.ref, leidinggevende.ref, traject.reference!, {reference: talent.reference, user: {achternaam: talent.achternaam, email: talent.email, profiel_foto: talent.profiel_foto ?? null, reference: talent.reference.id, voornaam: talent.voornaam}}, {reference: auteur.ref, user: {achternaam: auteur.achternaam, email: auteur.email, profiel_foto: auteur.profiel_foto ?? null, reference: auteur.ref.id, voornaam: auteur.voornaam}}, batch)
    createActivityLogDocument(data.data_eigenaar, {activiteit: Activity.DOCUMENT_CREATE, document_ref: rapportageRef as DocumentReference, user_ref: auteur.ref}, batch)

    await batch.commit()

    return rapportageRef as DocumentReference
}

export const updateRapportageById = (id?: string, content?: string) => {

    if (!id || !content) return Promise.reject("Fout.")

    return updateRapportageDocumentById(id, content)

}

