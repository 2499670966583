import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisHorizontalIcon, EnvelopeIcon, PencilIcon, PhoneIcon } from '@heroicons/react/20/solid'
import Layout from '../../layout'
import { Link } from 'react-router-dom'
import { DocumentCheckIcon, PlusIcon } from '@heroicons/react/24/outline'
import { getTrajecten, TrajectModel } from '../../domain/traject-utils'
import { useEffect, useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import Trajecten from '../../components/subnav/trajecten/trajecten'
import { groupBy } from 'lodash'; // Install lodash if not already installed: npm install lodash


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {

  const [trajecten, setTrajecten] = useState<TrajectModel[]>([])
  const { user } = useAuth()

  useEffect(() => {
    getTrajecten(user!, { includeCount: true }).then((trajecten) => {
      setTrajecten(trajecten)
    })
  }, [])


  // Group trajecten by gemeente
  const groupedTrajecten = groupBy(trajecten, 'gemeente') as Record<string, TrajectModel[]>;

  return (
    <Layout>
      <div className="mx-auto max-w-7xl h-auto overflow-y-scroll">
        <Trajecten />

        <div className="space-y-8 px-6 pt-6">
          {Object.entries(groupedTrajecten).map(([gemeente, items]) => (
            <div key={gemeente} className="space-y-4">
              {/* Category Heading */}
              <h2 className="text-xl font-semibold text-gray-800 pb-2">
                {gemeente || 'Onbekende Gemeente'} <span className="text-sm text-gray-500">({items.length})</span>
              </h2>

              {/* List Items */}
              <ul role="list" className="space-y-6">
                {items.map((traject) => (
                  <li
                    key={traject.reference?.id}
                    className="flex items-center gap-4 bg-slate-50 rounded-lg shadow-sm p-4 hover:bg-slate-100 transition"
                  >
                    {/* Left Image */}
                    <div className="flex-shrink-0">
                      <img
                        src={'/logo192.png'}
                        alt={traject.naam || 'Traject image'}
                        className="h-24 w-24 rounded-md object-contain"
                      />
                    </div>

                    {/* Right Details */}
                    <div className="flex-grow">
                      <Link to={`/trajecten/detail/${traject.reference?.id}`}>
                        <h3 className="text-lg font-semibold text-gray-900 hover:text-blue-600 transition">
                          {traject.naam}
                        </h3>
                      </Link>
                      <p className="text-sm text-gray-500">{traject.gemeente}</p>

                      {/* Details Section */}
                      <div className="mt-2 flex flex-wrap gap-x-4 text-sm text-gray-700">
                        <span>
                          <strong>Duur:</strong> {traject.duur} maanden
                        </span>
                        <span>
                          <strong>Consulent:</strong> {traject.consulent?.naam}
                        </span>
                        <span>
                          <strong>Talenten:</strong> {traject.talentenCount}
                        </span>
                        <span>
                          <strong>Aanmeldingen:</strong> {traject.aanmeldingenCount}
                        </span>
                      </div>

                      {/* Action Buttons */}
                      <div className="mt-4 flex gap-4">
                        <a
                          href={`/trajecten/detail/${traject.reference?.id}`}
                          className="flex items-center rounded-lg px-4 py-2 bg-blue-700 text-white text-sm font-medium hover:bg-blue-500 transition"
                        >
                          <DocumentCheckIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                          Bekijken
                        </a>
                        <Link
                          to={{ pathname: `/beheer/trajecten` }}
                          className="flex items-center rounded-lg px-4 py-2 bg-gray-100 text-gray-700 text-sm font-medium hover:bg-gray-200 transition"
                        >
                          <PencilIcon className="h-4 w-4 mr-2" aria-hidden="true" />
                          Bewerken
                        </Link>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}
