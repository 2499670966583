import { DocumentReference } from "firebase/firestore"
import getPraktijkverklaringenDocuments, { createDocument, getPraktijkverklaringDocumentById } from "../datasource/praktijkverklaring-utils"
import { getUserById, UserModel } from "./user-utils"

export type PraktijkverklaringModel = {
    id: string,
    aanmaak_datum: Date,
    content: string
    talent?: {
        naam?: string,
        profiel_foto?: string
    },
    auteur?: {
        naam?: string
    },
}

export type NewPraktijkverklaringModel = {
    content: string
    talent_reference: DocumentReference,
    auteur_reference: DocumentReference,
    data_eigenaar: DocumentReference
}

export const getPraktijkverklaringen = async (user: UserModel, max: number, options: {talent?: boolean, auteur?: boolean}) => {
    const list = await getPraktijkverklaringenDocuments(max, user.data_eigenaar_ref!)

    const praktijkverklaringen: PraktijkverklaringModel[] = []

    for (let praktijkverklaring of list) {
        let model: PraktijkverklaringModel = {
            id: praktijkverklaring.reference.id,
            aanmaak_datum: praktijkverklaring.aanmaak_datum, 
            content: praktijkverklaring.content
        }

        if (options?.talent) {
            let talent = await getUserById(praktijkverklaring.talent_ref)
            model.talent = {
                naam: `${talent?.voornaam} ${talent?.achternaam}`,
                profiel_foto: talent?.profiel_foto
            } 
        }

        if (options?.auteur) {
            let auteur = await getUserById(praktijkverklaring.auteur_ref)
            model.auteur = {
                naam: `${auteur?.voornaam} ${auteur?.achternaam}`
            } 
        }

        praktijkverklaringen.push(model)

    }

    return praktijkverklaringen
}

export const getPraktijkverklaringById = async (id: string, options: {talent?: boolean, auteur?: boolean}) => {
    const praktijkverklaring = await getPraktijkverklaringDocumentById(id)

    if (!praktijkverklaring) return Promise.reject("Geen rapportage gevonden")

        let model: PraktijkverklaringModel = {
            id: praktijkverklaring.reference.id,
            aanmaak_datum: praktijkverklaring.aanmaak_datum, 
            content: praktijkverklaring.content
        }

        if (options?.talent) {
            let talent = await getUserById(praktijkverklaring.talent_ref)
            model.talent = {
                naam: `${talent?.voornaam} ${talent?.achternaam}`,
                profiel_foto: talent?.profiel_foto
            } 
        }

        if (options?.auteur) {
            let auteur = await getUserById(praktijkverklaring.auteur_ref)
            model.auteur = {
                naam: `${auteur?.voornaam} ${auteur?.achternaam}`
            } 
        }
    
    return model
}

export const createPraktijkverklaring = async (data: NewPraktijkverklaringModel) => {
    return createDocument(data)
}