import dayjs from "dayjs";
import { afmeldenAanmeldingDocument, createAanmeldingDocument, getAanmeldingDocumentById, getAanmeldingDocuments, getAfgemeldeAanmeldingDocuments } from "../datasource/aanmelding-utils";
import { getUserByReference } from "../datasource/user-utils";
import { getTrajectById } from "./traject-utils";
import { _getUserById, getUserById, UserModel } from "./user-utils";
import { DocumentReference } from "firebase/firestore";

export type AanmeldingModel = {
    id: string,
    volledige_naam: string,
    voornaam: string,
    achternaam: string,
    geboortedatum: Date,
    geslacht: string,
    email: string,
    telefoon: string,
    nationaliteit: string,
    afkomst: string,
    straat: string,
    huisnummer: string,
    postcode: string,
    woonplaats: string,
    gemeente: string,
    situatie_schets: string,
    doelstellingen: string,
    bijzonderheden: string,
    consulent_id?: string,
    consulent?: {
        volledige_naam: string
    },
    traject_id?: string,
    traject?: {
        naam: string
    },
    aanmaakDatum: Date,
    aangemaakt_door_id?: string,
    aangemaakt_door?: {
        volledige_naam: string
    }
};

export type NewAanmeldingModel = {
    voornaam: string,
    achternaam: string,
    geboortedatum: Date,
    geslacht: string,
    email: string,
    telefoon: string,
    nationaliteit: string,
    afkomst: string,
    straat: string,
    huisnummer: string,
    postcode: string,
    woonplaats: string,
    gemeente: string,
    situatie_schets: string,
    doelstellingen: string,
    bijzonderheden: string,
    trajectId?:  string,
    consulentId?: string
};

export const getAanmeldingen = async (user: UserModel, options?: {consulent?: boolean, traject?: boolean, aangemaaktDoor?: boolean}, max = 20) => {
    const list: AanmeldingModel[] = []
    const aanmeldingen = await getAanmeldingDocuments(user?.data_eigenaar_ref!, max);

    for (let aanmelding of aanmeldingen) {

        let model: AanmeldingModel = {
            id: aanmelding.reference.id,
            volledige_naam: `${aanmelding.voornaam} ${aanmelding.achternaam}`,
            voornaam: aanmelding.voornaam,
            achternaam: aanmelding.achternaam,
            geboortedatum: aanmelding.geboortedatum,
            geslacht: aanmelding.geslacht,
            email: aanmelding.email,
            telefoon: aanmelding.telefoon,
            nationaliteit: aanmelding.nationaliteit,
            afkomst: aanmelding.afkomst,
            straat: aanmelding.straat,
            huisnummer: aanmelding.huisnummer,
            postcode: aanmelding.postcode,
            woonplaats: aanmelding.woonplaats,
            gemeente: aanmelding.gemeente,
            situatie_schets: aanmelding.situatie_schets,
            doelstellingen: aanmelding.doelstellingen,
            bijzonderheden: aanmelding.bijzonderheden,
            aanmaakDatum: aanmelding.aanmaak_datum
        }

        if (options?.consulent) {
            let consulent = await getUserById(aanmelding.consulent_reference)
            model.consulent = {
                volledige_naam: `${consulent?.voornaam} ${consulent?.achternaam}`
            } 
        } else {
            model.consulent_id = aanmelding.consulent_reference?.id
        }
    
        if (options?.traject) {
            let traject = await getTrajectById(aanmelding.traject_reference.id)
            model.traject = {
                naam: `${traject?.naam}`
            }
        } else {
            model.traject_id = aanmelding.traject_reference?.id
        }
    
        if (options?.aangemaaktDoor) {
            let aangemaaktDoor = await getUserById(aanmelding.aangemaakt_door_reference)
            model.aangemaakt_door = {
                volledige_naam: `${aangemaaktDoor?.voornaam} ${aangemaaktDoor?.achternaam}`
            } 
        } 
        else {
            model.aangemaakt_door_id = aanmelding.aangemaakt_door_reference?.id
        }

        list.push(model)

    }

    return list;
}

export const getAfgemeldeAanmeldingen = async (user: UserModel, options?: {consulent?: boolean, traject?: boolean, aangemaaktDoor?: boolean}, max = 20) => {
    const list: AanmeldingModel[] = []
    const aanmeldingen = await getAfgemeldeAanmeldingDocuments(user?.data_eigenaar_ref!, max);

    for (let aanmelding of aanmeldingen) {

        let model: AanmeldingModel = {
            id: aanmelding.reference.id,
            volledige_naam: `${aanmelding.voornaam} ${aanmelding.achternaam}`,
            voornaam: aanmelding.voornaam,
            achternaam: aanmelding.achternaam,
            geboortedatum: aanmelding.geboortedatum,
            geslacht: aanmelding.geslacht,
            email: aanmelding.email,
            telefoon: aanmelding.telefoon,
            nationaliteit: aanmelding.nationaliteit,
            afkomst: aanmelding.afkomst,
            straat: aanmelding.straat,
            huisnummer: aanmelding.huisnummer,
            postcode: aanmelding.postcode,
            woonplaats: aanmelding.woonplaats,
            gemeente: aanmelding.gemeente,
            situatie_schets: aanmelding.situatie_schets,
            doelstellingen: aanmelding.doelstellingen,
            bijzonderheden: aanmelding.bijzonderheden,
            aanmaakDatum: aanmelding.aanmaak_datum
        }

        if (options?.consulent) {
            let consulent = await getUserById(aanmelding.consulent_reference)
            model.consulent = {
                volledige_naam: `${consulent?.voornaam} ${consulent?.achternaam}`
            } 
        } 
        else {
            model.consulent_id = aanmelding.consulent_reference?.id
        }
    
        if (options?.traject) {
            let traject = await getTrajectById(aanmelding.traject_reference.id)
            model.traject = {
                naam: `${traject?.naam}`
            }
        } 
        else {
            model.traject_id = aanmelding.traject_reference?.id
        }
    
        if (options?.aangemaaktDoor) {
            let aangemaaktDoor = await getUserById(aanmelding.aangemaakt_door_reference)
            model.aangemaakt_door = {
                volledige_naam: `${aangemaaktDoor?.voornaam} ${aangemaaktDoor?.achternaam}`
            } 
        } 
        else {
            model.aangemaakt_door_id = aanmelding.aangemaakt_door_reference?.id
        }

        list.push(model)

    }

    return list;
}

export const getAanmeldingById = async (id: string, options?: {consulent?: boolean, traject?: boolean, aangemaaktDoor?: boolean}) => {

    const aanmelding = await getAanmeldingDocumentById(id)

    if (!aanmelding) return Promise.reject("Geen aanmelding gevonden.")

    const model: AanmeldingModel = {
        id: aanmelding.reference.id,
        volledige_naam: `${aanmelding.voornaam} ${aanmelding.achternaam}`,
        voornaam: aanmelding.voornaam,
        achternaam: aanmelding.achternaam,
        geboortedatum: aanmelding.geboortedatum,
        geslacht: aanmelding.geslacht,
        email: aanmelding.email,
        telefoon: aanmelding.telefoon,
        nationaliteit: aanmelding.nationaliteit,
        afkomst: aanmelding.afkomst,
        straat: aanmelding.straat,
        huisnummer: aanmelding.huisnummer,
        postcode: aanmelding.postcode,
        woonplaats: aanmelding.woonplaats,
        gemeente: aanmelding.gemeente,
        situatie_schets: aanmelding.situatie_schets,
        doelstellingen: aanmelding.doelstellingen,
        bijzonderheden: aanmelding.bijzonderheden,
        aanmaakDatum: aanmelding.aanmaak_datum
    }

    if (options?.consulent) {
        let consulent = await getUserById(aanmelding.consulent_reference)
        model.consulent = {
            volledige_naam: `${consulent?.voornaam} ${consulent?.achternaam}`
        } 
    } else {
        model.consulent_id = aanmelding.consulent_reference?.id
    }

    if (options?.traject) {
        let traject = await getTrajectById(aanmelding.traject_reference.id)
        model.traject = {
            naam: `${traject?.naam}`
        }
    } else {
        model.traject_id = aanmelding.traject_reference?.id
    }

    if (options?.aangemaaktDoor) {
        let aangemaaktDoor = await getUserById(aanmelding.aangemaakt_door_reference)
        model.aangemaakt_door = {
            volledige_naam: `${aangemaaktDoor?.voornaam} ${aangemaaktDoor?.achternaam}`
        } 
    }

    return model

}

export const createAanmelding = async (data: NewAanmeldingModel, user?: UserModel, dataEigenaar?: DocumentReference, isPublic = false) => {

    if (isPublic) {

        if (!dataEigenaar) return Promise.reject("Geen geldig bedrijf.")

        return createAanmeldingDocument({
            data_eigenaar_ref: dataEigenaar,
            voornaam: data.voornaam,
            achternaam: data.achternaam,
            geboortedatum: data.geboortedatum,
            geslacht: data.geslacht,
            email: data.email,
            telefoon: data.telefoon,
            nationaliteit: data.nationaliteit,
            afkomst: data.afkomst,
            straat: data.straat,
            huisnummer: data.huisnummer,
            postcode: data.postcode,
            woonplaats: data.woonplaats,
            gemeente: data.gemeente,
            situatie_schets: data.situatie_schets,
            doelstellingen: data.doelstellingen,
            bijzonderheden: data.bijzonderheden,
            aanmaak_datum: dayjs().toDate()
        })
    }

    if (!user) return Promise.reject("Invalide gebruiker.")

    const traject = await getTrajectById(data.trajectId)

    if (!traject) return Promise.reject("Geen traject gevonden")

    const consulent = await _getUserById(data.consulentId)

    if (!consulent) return Promise.reject("Geen consulent gevonden")

    return createAanmeldingDocument({
        data_eigenaar_ref: user.data_eigenaar_ref!,
        voornaam: data.voornaam,
        achternaam: data.achternaam,
        geboortedatum: data.geboortedatum,
        geslacht: data.geslacht,
        email: data.email,
        telefoon: data.telefoon,
        nationaliteit: data.nationaliteit,
        afkomst: data.afkomst,
        straat: data.straat,
        huisnummer: data.huisnummer,
        postcode: data.postcode,
        woonplaats: data.woonplaats,
        gemeente: data.gemeente,
        situatie_schets: data.situatie_schets,
        doelstellingen: data.doelstellingen,
        bijzonderheden: data.bijzonderheden,
        traject_reference: traject.reference,
        consulent_reference: consulent.ref,
        aanmaak_datum: dayjs().toDate(),
        aangemaakt_door_reference: user.ref
    })

}

export const afmeldenAanmelding = async (aanmeldingId: string) => {
    return afmeldenAanmeldingDocument(aanmeldingId)
}