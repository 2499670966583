'use client';

import { useState, useEffect } from 'react';
import { PlusIcon, CheckIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

// Define the type for your data entries
type ScheduleEntry = {
  id: number;
  name: string;
  planned: string;
  registered: string;
  break: string;
  hoursPlanned: string;
  hoursRegistered: string;
  difference: string;
};

export default function SchedulerPage() {
  // Explicitly set the type for your data state
  const [data, setData] = useState<ScheduleEntry[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Mock data fetching
    setTimeout(() => {
      setData([
        {
          id: 1,
          name: 'Majid Abdoli',
          planned: '06:00 - 13:00',
          registered: '06:00 - 13:00',
          break: '30 min Pause',
          hoursPlanned: '07:00',
          hoursRegistered: '06:30',
          difference: '-00:30',
        },
        {
          id: 2,
          name: 'Majid Abdoli',
          planned: '06:00 - 13:00',
          registered: '06:00 - 13:00',
          break: '30 min Pause',
          hoursPlanned: '07:00',
          hoursRegistered: '06:30',
          difference: '-00:30',
        },
        // Add more mock data as needed
      ]);
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 p-6">
      {/* Header */}
      <header className="flex justify-between items-center bg-indigo-600 text-white px-6 py-4 rounded-md shadow-md mb-6">
        <div className="flex items-center gap-4">
          <button className="bg-indigo-500 hover:bg-indigo-700 p-2 rounded-full shadow-md">
            <ChevronLeftIcon className="w-5 h-5" />
          </button>
          <span className="font-semibold text-lg">Za 04 Jan 2025</span>
          <button className="bg-indigo-500 hover:bg-indigo-700 p-2 rounded-full shadow-md">
            <ChevronRightIcon className="w-5 h-5" />
          </button>
        </div>
        <div className="flex items-center gap-4">
          <button className="px-4 py-2 bg-white text-indigo-600 font-medium rounded-md shadow hover:bg-gray-100">
            Dag
          </button>
          <button className="px-4 py-2 bg-white text-indigo-600 font-medium rounded-md shadow hover:bg-gray-100">
            Week
          </button>
          <button className="px-4 py-2 bg-white text-indigo-600 font-medium rounded-md shadow hover:bg-gray-100">
            Maand
          </button>
        </div>
      </header>

      {/* Table */}
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <table className="table-auto w-full text-left text-sm text-gray-700">
          <thead className="bg-gray-50 border-b border-gray-200">
            <tr>
              <th className="px-6 py-3 font-medium text-gray-900">Medewerker</th>
              <th className="px-6 py-3 font-medium text-gray-900">Gepland</th>
              <th className="px-6 py-3 font-medium text-gray-900">Geregistreerd</th>
              <th className="px-6 py-3 font-medium text-gray-900">Pauze</th>
              <th className="px-6 py-3 font-medium text-gray-900">Uren Gepland</th>
              <th className="px-6 py-3 font-medium text-gray-900">Uren Geregistreerd</th>
              <th className="px-6 py-3 font-medium text-gray-900">Uren Verschil</th>
              <th className="px-6 py-3 font-medium text-center text-gray-900">Acties</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={8} className="text-center py-8 text-gray-500">
                  Loading...
                </td>
              </tr>
            ) : (
              data.map((item) => (
                <tr key={item.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">{item.name}</td>
                  <td className="px-6 py-4 text-indigo-500 font-semibold">{item.planned}</td>
                  <td className="px-6 py-4 text-green-500 font-semibold">{item.registered}</td>
                  <td className="px-6 py-4">{item.break}</td>
                  <td className="px-6 py-4">{item.hoursPlanned}</td>
                  <td className="px-6 py-4">{item.hoursRegistered}</td>
                  <td
                    className={`px-6 py-4 ${
                      item.difference.startsWith('-') ? 'text-red-500' : 'text-green-500'
                    }`}
                  >
                    {item.difference}
                  </td>
                  <td className="px-6 py-4 flex justify-center items-center gap-2">
                    <button className="bg-green-500 hover:bg-green-600 p-2 rounded-full shadow">
                      <PlusIcon className="w-5 h-5 text-white" />
                    </button>
                    <button className="bg-blue-500 hover:bg-blue-600 p-2 rounded-full shadow">
                      <CheckIcon className="w-5 h-5 text-white" />
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}