'use client'
import Layout from '../../../layout'
import { useEffect, useRef, useState } from 'react'
import { Button, Switch } from '@headlessui/react'
import Beheer from '../../../components/subnav/beheer/Beheer'
import { createTraject, getAllTrajecten, TrajectModel, updateTraject } from '../../../domain/traject-utils'
import { getAllConsulenten, UserModel } from '../../../domain/user-utils'
import { ConsulentContext } from '../../../components/context/ConsulentContext'
import ConsulentSelection from '../../../components/dropdown/consulent/App'
import { useAuth } from '../../../context/AuthContext'
import RapportagePDFFile from '../../../components/pdf/RapportagePDF'
import OntwikkeldoelenPDFFile from '../../../components/pdf/OntwikkeldoelenPDF'
import AanmeldPDFFile from '../../../components/pdf/AanmeldPDF'
import AanvragenPDFFile from '../../../components/pdf/AanvragenPDF'
import { PDFDownloadLink } from '@react-pdf/renderer'

export default function Settings() {

    const { user } = useAuth()

    const [trajecten, setTrajecten] = useState<TrajectModel[]>([])
    const [consulenten, setConsulenten] = useState<UserModel[]>([])

    const consulentIdRefs = useRef<HTMLInputElement[]>([]);

    useEffect(() => {
        Promise.all([getAllTrajecten(user!), getAllConsulenten(user!)]).then((values) => {
            setTrajecten(values[0])
            setConsulenten(values[1])
        })
    }, [])

    const submitForm = (e, index) => {
        e.preventDefault()

        var trajectId = e.currentTarget.elements.trajectid.value
        var trajectNaam = e.currentTarget.elements.trajectnaam.value
        var trajectDuur = e.currentTarget.elements.trajectduur.value
        var trajectConsulent = consulentIdRefs.current[index]
        var aantalRapportages = e.currentTarget.elements.trajectaantalrapportages.value
        var evaluatiemomenten = e.currentTarget.elements.trajectevaluatiemomenten.value
        var jobcoachInterventies = e.currentTarget.elements.trajectjobcoachinterventies.value
        var praktijkVerklaring = e.currentTarget.elements.trajectpraktijkverklaring.ariaChecked
        var talentPortfolio = e.currentTarget.elements.trajecttalentportfolio.ariaChecked
        var loonwaardemeting = e.currentTarget.elements.trajectloonwaardemeting.ariaChecked

        if (!trajectNaam || !trajectDuur || !trajectConsulent || !aantalRapportages || !jobcoachInterventies || !evaluatiemomenten || !praktijkVerklaring || !talentPortfolio || !loonwaardemeting || !trajectConsulent)
            return

        if (trajectId) {
            updateTraject(trajectId, trajectConsulent.value, {
                naam: trajectNaam, aantal_rapportages: Number(aantalRapportages), duur: Number(trajectDuur), evaluatie_momenten: Number(evaluatiemomenten),
                jobcoach_interventies: Number(jobcoachInterventies), loonwaardemeting: (loonwaardemeting == "false") ? false : true, praktijkverklaring: (praktijkVerklaring == "false") ? false : true,
                talentportfolio: (talentPortfolio == "false") ? false : true
            })?.then((_) => {
                console.log("Saved")
            })
        } else {
            createTraject({}).then((response) => {
                console.log("Saved")
            })
        }
    }

    interface Props {
        aanmelding: null;
        bedrijf: null;
        intake: null;
    }


    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOptionO, setSelectedOptionO] = useState(''); 


    const handleSelectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(event.target.value);
    };

    const handleSelectionChangeO = (event) => {
        setSelectedOptionO(event.target.value);
    };

    return (
        <Layout>
            <>
                <div className="mx-auto lg:flex lg:gap-x-16 lg:px-8 h-[calc(100vh-65px)] overflow-auto ">
                    <h1 className="sr-only">General Settings</h1>

                    <Beheer />

                    <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
                        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Trajecten</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-500">
                                    This information will be displayed publicly so be careful what you share.
                                </p>
                                {trajecten.map((traject, i) => {

                                    return (
                                        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-sky-600 text-sm leading-6 bg-white shadow-lg sm:rounded-lg py-1 sm:px-12 pb-6 mb-12 ">
                                            <form onSubmit={(e) => submitForm(e, i)}>
                                                <div className="pt-6 sm:flex">
                                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Traject naam</dt>
                                                    <dd className="mt-1 w- flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <div >
                                                            <input
                                                                defaultValue={traject?.naam}
                                                                id="trajectnaam"
                                                                type="text"
                                                                placeholder="WOT (WerkontwikkelTraject)"
                                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                                            />

                                                        </div >
                                                        <dd className="flex flex-auto items-center justify-end">
                                                            <ConsulentSelection consulenten={consulenten} consulent={consulenten.find((consulent) => consulent.ref.id == traject.traject_consulent_reference?.id)} ref={(element) => consulentIdRefs.current[i] = element!} />

                                                        </dd>
                                                    </dd>
                                                </div>
                                                <div className="pt-6 sm:flex">
                                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Traject duur</dt>
                                                    <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6">Contractuele duur van een traject, minimaal 1 maand maximaal 12 maanden (hierna volgt een eventuele verlenging)</dt>
                                                    <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <div className="mt-2 flex rounded-md shadow-sm">

                                                            <input
                                                                defaultValue={traject?.duur}
                                                                id="trajectduur"
                                                                type="number"
                                                                placeholder="6"
                                                                className="block w-full min-w-0 flex-1 rounded-none rounded-l-md border-r-0 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                                            />
                                                            <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                                                maanden
                                                            </span>
                                                        </div>
                                                    </dd>
                                                </div>
                                                <div className="pt-6 sm:flex">
                                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Traject Consulent</dt>
                                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <ConsulentSelection consulenten={consulenten} consulent={consulenten.find((consulent) => consulent.ref.id == traject.traject_consulent_reference?.id)} ref={(element) => consulentIdRefs.current[i] = element!} />
                                                    </dd>
                                                </div>
                                                <div className="pt-6 sm:flex">
                                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Aantal Rapportages</dt>
                                                    <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6">Het aantal momenten dat er een document gegenereerd moet worden binnen de duur van een traject</dt>
                                                    <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <div className="mt-2 flex rounded-md shadow-sm">

                                                            <input
                                                                defaultValue={traject?.aantal_rapportages}
                                                                id="trajectaantalrapportages"
                                                                type="number"
                                                                placeholder="4"
                                                                className="block w-full min-w-0 flex-1 rounded-none rounded-l-md border-r-0 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                                            />
                                                            <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                                                stuks
                                                            </span>
                                                        </div>
                                                    </dd>
                                                </div>
                                                <div className="pt-6 sm:flex">
                                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                                        Rapportage Document
                                                    </dt>
                                                    <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <div className=" min-w-0 flex-1 ">
                                                            <select
                                                                className="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                                                onChange={handleSelectionChange}
                                                                value={selectedOption}
                                                            >
                                                                <option value="">Select a document</option>
                                                                <option value="default">Rapportage Default</option>
                                                                <option value="denHaag">Rapportage Den Haag</option>
                                                            </select>
                                                        </div>

                                                        {selectedOption === 'denHaag' && (
                                                            <PDFDownloadLink document={<RapportagePDFFile content={null} onderwerp={undefined} datum={undefined}  />} fileName={"Rapportage"}>
                                                                <button type="button" className="ml-4 px-1 py-2 bg-blue-600 text-white rounded-md sm:w-16 w-12 md:w-32 w-24 lg:w-48 w-28">
                                                                    Preview Rapportage Den Haag
                                                                </button>
                                                            </PDFDownloadLink>
                                                        )}
                                                        {selectedOption === 'default' && (
                                                            <button type="button" className="ml-4 px-4 py-2 bg-gray-600 text-white rounded-md sm:w-16 w-12 md:w-32 w-24 lg:w-48 w-28">
                                                                Default niet beschikbaar
                                                            </button>
                                                        )}
                                                    </dd>
                                                </div>


                                                <div className="pt-6 sm:flex">
                                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Evaluatie momenten</dt>
                                                    <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6">Momenten om gesprekken te plannen gedurende het traject</dt>
                                                    <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <div className="mt-2 flex rounded-md shadow-sm">

                                                            <input
                                                                defaultValue={traject?.evaluatie_momenten ?? undefined}
                                                                id="trajectevaluatiemomenten"
                                                                type="number"
                                                                placeholder="2"
                                                                className="block w-full min-w-0 flex-1 rounded-none rounded-l-md border-r-0 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                                            />
                                                            <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                                                                stuks
                                                            </span>
                                                        </div>
                                                    </dd>
                                                </div>
                                                <div className="pt-6 sm:flex">
                                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Ontwikkeldoelen Document</dt>
                                                    <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <div className=" min-w-0 flex-1 ">
                                                            <select
                                                                className="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                                                onChange={handleSelectionChangeO}
                                                                value={selectedOptionO}
                                                            >
                                                                <option value="">Select a document</option>
                                                                <option value="default">Ontwikkeldoelen Default</option>
                                                                <option value="denHaagO">Ontwikkeldoelen Den Haag</option>
                                                            </select>
                                                        </div>

                                                        {selectedOptionO === 'denHaagO' && (
                                                            <PDFDownloadLink document={<OntwikkeldoelenPDFFile content={null} traject={null} />} fileName={"Ontwikkeldoelen"}>
                                                                <button type="button" className="ml-4 px-1 py-2 bg-blue-600 text-white rounded-md sm:w-16 w-12 md:w-32 w-24 lg:w-48 w-28">
                                                                    Preview Ontwikkeldoelen Den Haag
                                                                </button>
                                                            </PDFDownloadLink>
                                                        )}
                                                        {selectedOptionO === 'default' && (
                                                            <button type="button" className="ml-4 px-4 py-2 bg-gray-600 text-white rounded-md sm:w-16 w-12 md:w-32 w-24 lg:w-48 w-28">
                                                                Default niet beschikbaar
                                                            </button>
                                                        )}
                                                    </dd>
                                                </div>
                                                <div className="pt-6 sm:flex">
                                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Praktijkverklaring</dt>
                                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6">De Praktijkverklaring wordt in het laatste van het termijn gegenereerd</dt>
                                                        <dd className="flex flex-auto items-center justify-end">
                                                            <Switch
                                                                defaultChecked={traject?.praktijkverklaring}
                                                                id='trajectpraktijkverklaring'
                                                                className="group flex w-8 cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 data-[checked]:bg-indigo-600"
                                                            >
                                                                <span
                                                                    aria-hidden="true"
                                                                    className="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out group-data-[checked]:translate-x-3.5"
                                                                />
                                                            </Switch>
                                                        </dd>
                                                    </dd>
                                                </div>
                                                <div className="pt-6 sm:flex">
                                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Talentportfolio</dt>
                                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6">De Praktijkverklaring wordt in het laatste van het termijn gegenereerd</dt>
                                                        <dd className="flex flex-auto items-center justify-end">
                                                            <Switch
                                                                defaultChecked={traject?.talentportfolio}
                                                                id='trajecttalentportfolio'
                                                                className="group flex w-8 cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 data-[checked]:bg-indigo-600"
                                                            >
                                                                <span
                                                                    aria-hidden="true"
                                                                    className="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out group-data-[checked]:translate-x-3.5"
                                                                />
                                                            </Switch>
                                                        </dd>
                                                    </dd>
                                                </div>
                                                <div className="pt-6 sm:flex">
                                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Loonwaardemeting</dt>
                                                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6">De Praktijkverklaring wordt in het laatste van het termijn gegenereerd</dt>
                                                        <dd className="flex flex-auto items-center justify-end">
                                                            <Switch
                                                                defaultChecked={traject?.loonwaardemeting}
                                                                id='trajectloonwaardemeting'
                                                                className="group flex w-8 cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 data-[checked]:bg-indigo-600"
                                                            >
                                                                <span
                                                                    aria-hidden="true"
                                                                    className="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out group-data-[checked]:translate-x-3.5"
                                                                />
                                                            </Switch>
                                                        </dd>
                                                    </dd>
                                                </div>
                                                <input
                                                    value={traject?.reference?.id}
                                                    id="trajectid"
                                                    type="hidden"
                                                    placeholder="6"
                                                    className="block w-full min-w-0 flex-1 rounded-none rounded-l-md border-r-0 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                                />
                                                <div className="pt-6 sm:flex">
                                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Aanvragen Verlenging Document</dt>
                                                    <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6"></dt>
                                                    <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <div className="mt-2 flex rounded-md shadow-sm">
                                                            <PDFDownloadLink document={<AanvragenPDFFile content={null} traject={null} />} fileName={"Aanvragen"}>
                                                                <button type="button" className="px-4 py-2 bg-blue-600 text-white rounded-md">
                                                                    Preview Aanvragen Verlenging
                                                                </button>
                                                            </PDFDownloadLink>
                                                        </div>
                                                    </dd>
                                                </div>
                                                <div className="pt-6 sm:flex">
                                                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Aanmeld Document</dt>
                                                    <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6"></dt>
                                                    <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                                                        <div className="mt-2 flex rounded-md shadow-sm">
                                                            <PDFDownloadLink document={<AanmeldPDFFile content={null} aanmelding={null} />} fileName={"Aanmeld"}>
                                                                <button type="button" className="px-4 py-2 bg-blue-600 text-white rounded-md">
                                                                    Preview Aameld
                                                                </button>
                                                            </PDFDownloadLink>
                                                        </div>
                                                    </dd>
                                                </div>
                                                {/* <Button type='submit'>Opslaan</Button> */}
                                                <div className="mt-12 flex items-center justify-end gap-x-6">
                                                    <button type="button" className="text-sm/6 font-semibold text-gray-900">
                                                        Annuleren
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        Opslaan
                                                    </button>
                                                </div>
                                            </form>
                                        </dl>)
                                })}



                                <div className="bg-white shadow sm:rounded-lg border border-sky-600 mt-12 mb-12">
                                    <div className="px-4 py-5 sm:p-6">
                                        <div className="sm:flex sm:items-start sm:justify-between">
                                            <div>
                                                <h3 className="text-base font-semibold text-gray-900">Nieuw traject</h3>
                                                <div className="mt-2 max-w-xl text-sm text-gray-500">
                                                    <p>
                                                        Klik hier om een nieuw traject toe te voegen.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Toevoegen
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </main>
                </div>
            </>
        </Layout>
    )
}
