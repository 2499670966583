import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import Bloeiindex from '../../images/Bloeiindex_Purple@4.png';
import BackgroundImage from '../../images/the-hands-of-two-people-help-each-other-are-planti-2022-09-14-06-39-07_LR-utc.jpg';
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { useAuth } from "../../context/AuthContext";
import { SubmitHandler, useForm } from "react-hook-form";
import { FirebaseError } from "firebase/app";
import { MultiFactorError, TotpSecret } from "firebase/auth";
import { displayMutliFactor } from "../../utils/auth";
import MFAAuthRegister from "../../components/modal/auth-register/Auth-register";
import MFAAuthValidate from "../../components/modal/auth-validate/Auth-validate";

export default function Login() {
  const [mfaError, setMFAError] = useState<MultiFactorError>();
  const [code, setCode] = useState("");
  const [secretKey, setSecretKey] = useState<TotpSecret>();

  const { signIn, logOut } = useAuth();
  const navigate = useNavigate(); // Voor navigatie naar de homepage

  const {user} = useAuth()

  type Inputs = {
    email: string;
    password: string;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    signIn(data.email, data.password)
      .then(async (user) => {
        if (!user.user.emailVerified) {
          throw new FirebaseError("auth/unverified-email", "Email must be verified to perform MFA.");
        }

        displayMutliFactor().then((response) => {
          setSecretKey(response!.secret_key);
          setCode(response!.qr_display);
        });
      })
      .catch((e) => {
        if (e.code === "auth/multi-factor-auth-required") {
          setMFAError(e);
        } else {
          logOut();
        }
      });
  };

  useEffect(() => {
    if (user != null) {
      if (user.gebruikers_rol == "werkvloerbegeleider")
        navigate("/vandaag")
      else navigate("/")
    }
  }, [user])

  return (
    <>
      <div className="flex flex-1 h-screen">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
          <div className="mx-auto w-full max-w-sm lg:w-96 pb-24">
            <div>
              <img alt="Bloeiindex" src={Bloeiindex} className="h-8 w-auto" />
              <h2 className="mt-24 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Inloggen
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Nog geen Bloeiindex?{" "}
                <a href="https://www.bloeiindex.nl" className="font-semibold text-blue-700 hover:text-sky-600">
                  Start vandaag nog!
                </a>
              </p>
            </div>

            <div className="mt-10">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      {...register("email", { required: { value: true, message: "Email is verplicht" } })}
                      autoComplete="email"
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["email"] && <span>{errors["email"]?.message}</span>}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Wachtwoord
                  </label>
                  <div className="mt-2">
                    <input
                      type="password"
                      {...register("password", { required: { value: true, message: "Wachtwoord is verplicht" } })}
                      autoComplete="current-password"
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors["password"] && <span>{errors["password"]?.message}</span>}
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                      Onthoud mijn gegevens
                    </label>
                  </div>

                  <div className="text-sm leading-6">
                    <a href="#" className="font-semibold text-blue-700 hover:text-sky-600">
                      Wachtwoord vergeten?
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-blue-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Aan de slag!
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img alt="" src={BackgroundImage} className="absolute inset-0 h-full w-full object-cover" />
        </div>
      </div>
      {code && <MFAAuthRegister code={code} totpKey={secretKey} />}
      {mfaError && <MFAAuthValidate error={mfaError} handleMFASuccess={undefined} />}
    </>
  );
}