import { debug } from 'webpack';
import { getBedrijfByReference, BedrijfModel } from './bedrijf-utils';
import { DocumentReference } from 'firebase/firestore';
import { getLopendeTrajectenDocuments, getLopendTrajectDocumentById, getLopendTrajectDocumentByReference, RemoteLopendTraject } from '../datasource/lopend-traject-utils';
import { getUserById, UserModel } from './user-utils';
import { User, Werkbedrijf } from '../datasource/interfaces/collection';

export type LopendTrajectModel = {
    id?: string;
    begindatum?: Date,
    consulent_id?: string,
    consulent?: User | null,
    consulent_ref?: DocumentReference,
    status?: string,
    talent_id?: string,
    talent_ref?: DocumentReference,
    traject_ref?: DocumentReference,
    verwachte_einddatum?: Date,
    werkbedrijf_id?: string,
    werkbedrijf?: Werkbedrijf | null,
    werkdagen?: string,
    werkvloer_begeleider_ref?: DocumentReference
};

function convertRemoteToDomain(ltRemote: RemoteLopendTraject): LopendTrajectModel {
    return {
        id: ltRemote.reference.id,
        begindatum: ltRemote.begindatum,
        consulent_ref: ltRemote.consulent_ref,
        status: ltRemote.status,
        talent_ref: ltRemote.talent_ref,
        traject_ref: ltRemote.traject_ref,
        verwachte_einddatum: ltRemote.verwachte_einddatum,
        werkdagen: ltRemote.werkdagen,
        werkvloer_begeleider_ref: ltRemote.werkvloer_begeleider_ref,
        consulent: ltRemote.consulent,
        werkbedrijf: ltRemote.werkbedrijf
    }
}

export const getLopendeTrajecten = async (max: number, user: UserModel) => {
    const list: LopendTrajectModel[] = []

    var LopendTrajecten = await getLopendeTrajectenDocuments(max, user?.data_eigenaar_ref!)
    LopendTrajecten.forEach((lopendTraject) => {
        list.push(convertRemoteToDomain(lopendTraject))
    })

    return list;
}

export const getLopendTrajectById = async (id?: string) => {

    if (!id) return null

    const lopendTraject = await getLopendTrajectDocumentById(id)

    if (!lopendTraject) return null

    return convertRemoteToDomain(lopendTraject)
 
};

export const getWerkbedrijfLocatie = async (lopendTraject: LopendTrajectModel) => {
    // if (lopendTraject.werkbedrijf_ref) {
    //     const bedrijf = await getBedrijfByReference(lopendTraject.werkbedrijf_ref);

    //     if (bedrijf) {
    //         return {
    //             naam: bedrijf.naam,
    //             postcode: bedrijf.postcode,
    //             straat: bedrijf.straat,
    //             plaats: bedrijf.plaats,
    //             locatie: `${bedrijf.naam}, ${bedrijf.straat} ${bedrijf.huisnummer}, ${bedrijf.postcode} ${bedrijf.plaats}`,
    //         };
    //     }
    // }
    // return null; 
};