import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { TrajectModel } from '../../domain/traject-utils';
import Logo from '../../logoGemeente.png';

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 10,
        fontFamily: 'Helvetica',
        backgroundColor: '#f7f8fa',
    },
    container: {
        backgroundColor: '#ffffff',
        padding: 20,
        borderRadius: 5,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '2px solid #888',
        paddingBottom: 10,
        marginBottom: 20,
    },
    headerLeft: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    headerRight: {
        textAlign: 'right',
    },

    headerImage: {
        width: 70,
        height: 60,
        marginLeft: 5,
    },

    title: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 5,
    },
    reference: {
        fontSize: 10,
        color: '#555',
        fontStyle: 'italic',
        textAlign: 'right',
    },

    sectionTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#333',
    },
    fieldGroup: {
        marginBottom: 20,
    },
    fieldRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    fieldLabel: {
        flex: 1,
        fontWeight: 'bold',
        color: '#555',
        marginRight: 10,
    },
    fieldValue: {
        flex: 3,
        backgroundColor: 'white',
        padding: 4,
        borderRadius: 3,
        border: '1px solid #ddd',
    },
    infoBox: {
        padding: 15,
        backgroundColor: '#e9ecef',
        borderRadius: 5,
        marginBottom: 20,
    },
    infoText: {
        fontSize: 10,
        color: '#555',
        marginBottom: 5,
    },
    table: {
        width: '100%',
        borderSpacing: '0 10px',
        marginBottom: 20,
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    tableCell: {
        flex: 3,
        backgroundColor: '#f9f9f9',
        padding: 8,
        borderRadius: 5,
        border: '1px solid #ddd',
    },
    tableHeaderCell: {
        fontWeight: 'bold',
        backgroundColor: '#e9ecef',
    },
    tableCellBig: {
        flex: 1,
        padding: 32,
        backgroundColor: '#f9f9f9',
        border: '1px solid #ddd',
        borderRadius: 5,
        textItems: 'center',
    },
});

// Data inladen:
//Ontwikkeldoel: ???
//Waarom is er meer tijd nodig: ???
//Aanpak tijdens verlenging: ??
//Verlengde trajectduur: Traject?

interface AanvragenProps {
    content?: string | null;
    traject?: TrajectModel | null
}

const AanvragenPDFFile = ({ content, traject }: AanvragenProps) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <Text style={styles.headerLeft}>Gemeente Den Haag</Text>
                        <View style={styles.headerRight}>
                            <Image
                                style={styles.headerImage}
                                src={Logo}
                            />
                            <Text>Dienst SZW</Text>
                        </View>
                    </View>


                    <Text style={styles.reference}>Referentie:</Text>


                    <View style={styles.fieldGroup}>
                        <Text style={styles.sectionTitle}>Aanvragen Verlenging Formulier</Text>
                        <Text>
                            Dit formulier is bedoeld om een verlenging aan te vragen op het
                            lopende traject. Een verlenging dient tijdig voor afloop van het
                            lopende traject aangevraagd te worden bij de contactpersoon.
                        </Text>
                    </View>


                    <View style={styles.fieldGroup}>
                        <Text style={styles.sectionTitle}>Overgebleven doelen en aanpak</Text>
                        <Text style={{ marginBottom: 10 }}>
                            Geef kort aan waar nog ruimte is voor ontwikkeling van de kandidaat binnen de voorziening.
                            Welke ontwikkeldoelen nog niet zijn behaald of welke nieuwe ontwikkeldoelen zijn ontstaan.
                        </Text>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <Text style={styles.fieldLabel}>Ontwikkeldoel</Text>
                                {/* Dropdown */}
                                <Text style={styles.tableCell}> <Text style={styles.fieldValue}>[Drop-down obv TBX POP, indien aanwezig]</Text></Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.fieldLabel}>Waarom is er meer tijd nodig?</Text>
                                {/* Wordt niet opgehaald van de database */}
                                <Text style={styles.tableCell}>[Genereren uit POP, indien aanwezig]</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.fieldLabel}>Aanpak tijdens de verlenging</Text>
                                {/* Wordt niet opgehaald van de database */}
                                <Text style={styles.tableCell}>Vul hier de aanpak in</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <Text style={styles.fieldLabel}>Verlengde trajectduur</Text>
                                <Text style={styles.tableCell}>{traject?.duur || "Nog geen traject gestart."}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.fieldGroup}>
                        <Text style={styles.sectionTitle}>Verwacht resultaat</Text>
                        <Text style={{ marginBottom: 10 }}>Beschrijf wat het verwachte resultaat is na doorlopen van de verlenging.</Text>
                        <Text style={styles.tableCellBig}>Vul hier het verwachte resultaat in</Text>
                    </View>

                    <View style={styles.fieldGroup}>
                        <Text style={styles.sectionTitle}>Voorwaarden</Text>
                        <Text style={{ marginBottom: 10 }}>
                            Wat zijn de voorwaarden om de verlenging tot een succes te laten
                            verlopen. Wordt hierin ook iets van de consulent van Gemeente Den Haag verwacht?
                        </Text>
                        <Text style={styles.tableCellBig}>Vul hier de voorwaarden in</Text>
                    </View>


                </View>
            </Page>
        </Document>
    );
};

export default AanvragenPDFFile;
