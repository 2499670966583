import React, { useState } from 'react';
import { createEvaluatie, EvaluatieModel, NewEvaluatieModel } from '../../domain/evaluatie-utils';
import { UserModel } from '../../domain/user-utils';



const Updateschrijven: React.FC<{ user: UserModel, talentId?: string }> = ({ user, talentId }) => {
    const [evaluaties, setEvaluaties] = useState<EvaluatieModel[]>([]);
    const [newUpdate, setNewUpdate] = useState<string>('');
    const [rating, setRating] = useState<number>(10);
    const [moodLevel, setMoodLevel] = useState<number>(5);

    const moodEmojiMap = [
        '😡', // 1
        '😟', // 2
        '😔', // 3
        '😞', // 4
        '😐', // 5
        '🙂', // 6
        '😊', // 7
        '😃', // 8
        '😁', // 9
        '😍', // 10
    ];

    const handleUpdateSubmit = () => {
        if (!newUpdate.trim()) return;

        const newEntry: NewEvaluatieModel = {
          content: newUpdate,
          auteurId: user.ref.id,
          talentId: talentId!,
          cijfer: rating,
          gevoel: moodLevel
        };

        createEvaluatie(user, newEntry).then((res) => {
            // window.location.reload()
        })
    };

    return (
        <div className="">
            {/* <h2 className="text-lg font-semibold text-slate-700">Nieuwe Update</h2> */}
            <textarea
                value={newUpdate}
                onChange={(e) => setNewUpdate(e.target.value)}
                rows={8}
                placeholder="Schrijf een update over het Talent, beschrijf hoe het gaat en wat er gedaan is vandaag"
                className="block w-full rounded-md border-slate-200 mt-2 text-slate-700 sm:text-sm placeholder-black/50"
            />
            <div className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2">
                {/* Slider voor cijfer */}
                <div>
                    <label htmlFor="rating" className="block text-sm font-medium text-slate-700">
                        Cijfer voor vandaag
                    </label>
                    <div className="flex items-center space-x-4 mt-2">
                        <input
                            id="rating"
                            type="range"
                            min="1"
                            max="10"
                            value={rating}
                            onChange={(e) => setRating(Number(e.target.value))}
                            className="w-full accent-blue-700"
                        />
                        <span className="w-8 text-center text-2xl font-semibold text-slate-700">
                            {rating}
                        </span>
                    </div>
                </div>

                {/* Slider voor gevoel */}
                <div>
                    <label htmlFor="mood" className="block text-sm font-medium text-slate-700">
                        Hoe voelt het Talent zich?
                    </label>
                    <div className="flex items-center space-x-4 mt-2">
                        <input
                            id="mood"
                            type="range"
                            min="1"
                            max="10"
                            value={moodLevel}
                            onChange={(e) => setMoodLevel(Number(e.target.value))}
                            className="w-full accent-blue-700"
                        />
                        <span className="w-8 text-center text-4xl font-semibold text-white">
                            {moodEmojiMap[moodLevel - 1]}
                        </span>
                    </div>
                </div>
            </div>
            <div className="mt-4 bottom">
                <button
                    onClick={handleUpdateSubmit}
                    className="w-full inline-flex justify-center items-center rounded-md border border-blue-700 px-4 py-2 text-sm font-medium text-blue-700 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Opslaan
                </button>
            </div>
        </div>
    );
};

export default Updateschrijven;