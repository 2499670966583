const PLACEHOLDER = "https://storage.googleapis.com/glide-prod.appspot.com/uploads-v2/hOclsK0v6r1MxJZCAkio/pub/5SrfjlJfSmCcc7l8fljJ.png"

export default function DefaultImage({ profiel_foto, className }) {

  const handleImageError = () => PLACEHOLDER;

  return (
    <img
      alt="Profile"
      className={className}
      src={profiel_foto ?? PLACEHOLDER}
      onError={handleImageError}
    />
  );

  //Function "IF" User/Talent geen profiel foto heeft dan set "DefaultImage".
  //Else if User/Talent wel een profiel foto heeft, maar deze laad niet dan set "DefaultImage".
  //Else User/Talent wel een profiel foto heeft en deze laad gewoon dan laat die foto zien.
}

