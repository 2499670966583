import { useState, useEffect } from 'react';
import Layout from '../../../layout';
import { useAuth } from '../../../context/AuthContext';
import { getBillableUsers, UserBillableModel } from '../../../domain/finance-utils';

export default function MonthlyFinancialOverview() {
  const { user } = useAuth();
  const [billableUsers, setBillableUsers] = useState<UserBillableModel[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Current month
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [totalStats, setTotalStats] = useState({
    users: 0,
    hours: 0,
    revenue: 0,
  });

  useEffect(() => {
    async function fetchData() {
      const response = await getBillableUsers(user!, selectedMonth, selectedYear);
      setBillableUsers(response.users);
      setTotalStats(response.stats);
    }
    fetchData();
  }, [user, selectedMonth, selectedYear]);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <Layout>
      <div className="px-4 py-6 space-y-8">
        {/* Header */}
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold text-gray-800">Monthly Financial Overview</h1>
          <div className="flex space-x-4">
            <select
              className="rounded-md border border-gray-300 bg-white text-sm shadow-sm focus:ring focus:ring-indigo-500"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
            >
              {months.map((month, index) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
            <select
              className="rounded-md border border-gray-300 bg-white text-sm shadow-sm focus:ring focus:ring-indigo-500"
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}
            >
              {[...Array(5)].map((_, i) => (
                <option key={i} value={new Date().getFullYear() - i}>
                  {new Date().getFullYear() - i}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Stats */}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
          <div className="rounded-lg border bg-white p-4 shadow-sm">
            <p className="text-sm text-gray-500">Total Users</p>
            <p className="mt-2 text-3xl font-bold text-indigo-600">{totalStats.users}</p>
          </div>
          <div className="rounded-lg border bg-white p-4 shadow-sm">
            <p className="text-sm text-gray-500">Total Hours</p>
            <p className="mt-2 text-3xl font-bold text-indigo-600">{totalStats.hours}</p>
          </div>
          <div className="rounded-lg border bg-white p-4 shadow-sm">
            <p className="text-sm text-gray-500">Total Revenue</p>
            <p className="mt-2 text-3xl font-bold text-indigo-600">${totalStats.revenue.toFixed(2)}</p>
          </div>
        </div>

        {/* Table */}
        <div className="">
          <table className="min-w-full ">
            <thead className="">
              <tr>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">User Info</th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Company Info</th>
                <th className="px-4 py-2 text-right text-sm font-semibold text-gray-700">Details</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 space-y-4 ">
              {billableUsers.map((user) => (
                <tr key={user.id} className="shadow-xl rounded-lg ">
                  <td className="px-4 py-2 flex items-center space-x-4 pt-12">
                    <img
                      src={user.name || 'https://cdn.prod.website-files.com/6706cb25bd13f4bda163d510/6706d034fa779286779767b3_Asset%201%404x.png'}
                      alt="[username]"
                      className="h-10 w-10 rounded-full object-cover"
                    />
                    <div>
                      <p className="text-sm font-medium text-gray-700">[user.name]</p>
                      <p className="text-sm text-gray-500">[user.gemeente]</p>
                    </div>
                  </td>
                  <td className="px-4 py-2">
                    <p className="text-sm font-medium text-gray-700">[user.Trajectnaam]</p>
                    <p className="text-sm text-gray-500">[user.TrajectGemeente]</p>
                  </td>
                  <td className="px-4 py-2 text-right">
                    <p className="text-sm text-gray-700">
                      Start: <span className="font-medium">startdatum</span>
                    </p>
                    <p className="text-sm text-gray-700">
                      End: <span className="font-medium">einddatum</span>
                    </p>
                    <p className="mt-1 text-sm font-bold text-indigo-600">$price</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}