import { and, collection, doc, DocumentReference, getDoc, getDocs, limit,or,orderBy, query, QueryConstraint, QueryDocumentSnapshot, setDoc, SnapshotOptions, startAfter, startAt, updateDoc, where, writeBatch, WriteBatch } from "firebase/firestore";
import { db } from "../utils/firebase";
import { NewUserModel } from "../domain/user-utils";

export type RemoteUser = {
    ref: DocumentReference,
    achternaam: string,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    telefoon_nummer: string,
    voornaam: string,
    geboortedatum: string,
    heeftAccount: boolean,
    data_eigenaar_ref?: DocumentReference
}

export type RemoteModeTalent = {
    aanmaakdatum: Date,
    score: string,
    talent_ref: DocumentReference, 
}

const userConverter = {
    toFirestore: (user: RemoteUser) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteUser {
            const data = snapshot.data(options)!;
            return {
                ref: snapshot.ref,
                achternaam: data.achternaam,
                email: data.email,
                gebruikers_rol: data.gebruikers_rol,
                geslacht: data.geslacht,
                profiel_foto: data.profiel_foto,
                telefoon_nummer: data.telefoon_nummer,
                voornaam: data.voornaam,
                geboortedatum: data.geboortedatum,
                heeftAccount: data.heeft_account ?? false,
                data_eigenaar_ref: data.data_eigenaar_ref
            }
        }
  }

export const getAllUsersByCursor = async (maxPerPage: number, qCollection: QueryConstraint[] = [], dataOwner: DocumentReference, docRef?: DocumentReference) => {
    if (docRef) {
        const docSnap = await getDoc(doc(docRef, "user"));
        qCollection.push(startAfter(docSnap))
    }
    qCollection.unshift(where("data_eigenaar_ref", "==", dataOwner))
    qCollection.push(limit(maxPerPage))
    var q = query(collection(db, "user"), ...qCollection).withConverter(userConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteUser[] = []
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list;
}

export default getAllUsersByCursor

// data must include only the changes
export const updateUserByRef = async (userRef: DocumentReference, data: any) => {
    return updateDoc(userRef, data)
}

export const getVerjaardagen = async () => {
    const currentDate = new Date();
    const verjaardag = query(collection(db, 'user'), where('geboortedatum', '>', currentDate)).withConverter(userConverter);

    const querySnapshot = await getDocs(verjaardag);
    const list: RemoteUser[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 
}

export const searchUserByName = async (dataOwner: DocumentReference, term: string) => {
    return await getAllUsersByCursor(5, [orderBy("voornaam"), where("voornaam", ">=", term.charAt(0).toUpperCase() + term.slice(1)), where("gebruikers_rol", "==", "talent")], dataOwner)
}

export const getUserDocumentById = async (id: string) => {
    const reference = await doc(db, "user", id).withConverter(userConverter)
    const docSnap = await getDoc(reference);
    
    if (!docSnap.exists()) {
        return null; 
    }

    return docSnap.data()
}

export const getUserByReference = async (reference: DocumentReference) => {
    const docSnap = await getDoc(reference);
    
    if (!docSnap.exists()) {
        return null; 
    }

    return docSnap.data();
}

export const getAllConsulentenDocuments = async (dataOwner: DocumentReference) => {
    const consulenten = query(collection(db, 'user'), and(where("data_eigenaar_ref", "==", dataOwner), or(where('gebruikers_rol', '==', "consulent"), where('gebruikers_rol', '==', "gemeente")))).withConverter(userConverter);

    const querySnapshot = await getDocs(consulenten);
    const list: RemoteUser[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list

}

export const getAllNonTalentDocuments = async (dataOwner: DocumentReference) => {
    const consulenten = query(collection(db, 'user'), and(where("data_eigenaar_ref", "==", dataOwner), where('gebruikers_rol', 'not-in', ["talent", "gemeente", "superadmin"]))).withConverter(userConverter);

    const querySnapshot = await getDocs(consulenten);
    const list: RemoteUser[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list

}

export const addUserDocument = (user: NewUserModel, batch: WriteBatch) => {

        const reference = doc(collection(db, 'user'))

        batch.set(reference, user)

        return reference
}