import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
} from '@heroicons/react/20/solid'
import Layout from '../../layout'
import Rooster from '../../components/rooster/App'
import Authvalidate from '../../components/modal/auth-validate/Auth-validate'
import { useAuth } from '../../context/AuthContext'

const meetings = [
  {
    id: 1,
    date: 'January 10th, 2022',
    time: '5:00 PM',
    datetime: '2022-01-10T17:00',
    name: 'Leslie Alexander',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    location: 'Starbucks',
  },
  // More meetings...
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {

  const { user } = useAuth()

  return (
    <Layout>
      <div className='overflow-auto h-full'>
        <Rooster  />
      </div>
    </Layout>
  )
}
