'use client'

import { useState, useEffect } from 'react'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react'
import {
  CalendarDaysIcon,
  EllipsisVerticalIcon,
  MapPinIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import Layout from '../../../layout'
import { getIntakeById, IntakeModel } from '../../../domain/intake-utils'
import { useParams } from 'react-router-dom'
import DefaultImage from '../../../components/defaultimage/defaultimage'
import { TaakManger, Taak} from '../../loonwaardemeting/taakmanager/App'
import { TaakDetailsForm } from '../../loonwaardemeting/taakdetails/App'

var taken: Taak[] = []

export default function Rapportage() {
  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  // const [selected, setSelected] = useState(moods[5])
  const [intake, setIntake] = useState<IntakeModel>()
  const [selectedLocatie, setSelectedLocatie] = useState('');
  const [selectedContact, setSelectedContact] = useState('');
  const [update, setUpdate] = useState(false)


  const { id } = useParams();



  useEffect(() => {
    getIntakeById(id!.toString(), { consulent: true, traject: true }).then((response) => {
      setIntake(response)
    })
  }, [])

  const handleInputChange = (e) => {
    setIntake({
      ...intake,
      ingeburgerd: e.target.value,
    });
  };

  useEffect(() => {
    setUpdate(false)
  }, [update])

  return (
    <>
      <Layout>
        <div className="overflow-y-auto max-h-screen px-4">
          <main>


            {/* Header */}
            <header className="relative isolate pt-16">
              <div aria-hidden="true" className="absolute inset-0 -z-10 overflow-hidden">
                <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
                  <div
                    style={{
                      clipPath:
                        'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                    }}
                    className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
                  />
                </div>
                <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
              </div>

              <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
                <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                  <div className="flex items-center gap-x-6 ">
                    <DefaultImage profiel_foto={undefined} className={"h-16 w-16 flex-none rounded-full ring-1 ring-gray-900/10 object-cover"}/>
                    <h1>
                      <div className="text-sm leading-6 text-gray-500">
                        Document: <span className="text-gray-700">Intake</span>
                      </div>
                      <div className="mt-1 text-base font-semibold leading-6 text-gray-900">{intake?.voornaam} {intake?.achternaam}</div>
                    </h1>
                  </div>
                  <div className="flex items-center gap-x-4 sm:gap-x-6">

                    <a href="#" className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block">
                      Bewerken
                    </a>
                    <a
                      href="#"
                      className="rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Versturen
                    </a>

                    <Menu as="div" className="relative sm:hidden">
                      <MenuButton className="-m-3 block p-3">
                        <span className="sr-only">More</span>
                        <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
                      </MenuButton>

                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <MenuItem>
                          <button
                            type="button"
                            className="block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                          >
                            Copy URL
                          </button>
                        </MenuItem>
                        <MenuItem>
                          <a href="#" className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50">
                            Bewerken
                          </a>
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                </div>
              </div>
            </header>



            {/* Data */}
            <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
              <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">


                {/* Document summary */}
                <div className="lg:col-start-3 lg:row-end-1">
                  <h2 className="sr-only">Overzicht</h2>
                  <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                    <dl className="flex flex-wrap">
                      <div className="flex-auto pl-6 pt-6">
                        <dt className="text-sm font-semibold leading-6 text-gray-900">{intake?.gemeente}</dt>
                        <dd className="mt-1 text-base font-semibold leading-6 text-gray-900"></dd>
                      </div>
                      <div className="flex-none self-end px-6 pt-4">
                        <dt className="sr-only">Status</dt>
                        <dd className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                          [traject status]
                        </dd>
                      </div>
                      <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6 ">
                        <dt className="flex-none">
                          <span className="sr-only">Client </span>
                          <UserCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                        </dt>
                        <dd className="text-sm font-medium leading-6 text-gray-900">Door [Author name]</dd>
                      </div>
                      <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                        <dt className="flex-none">
                          <span className="sr-only">Due date </span>
                          <CalendarDaysIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                        </dt>
                        <dd className="text-sm leading-6 text-gray-500">
                          op {intake?.datumUitgevoerd?.toLocaleDateString()}
                        </dd>
                      </div>
                      <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                        <dt className="flex-none">
                          <span className="sr-only">Status</span>
                          <MapPinIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                        </dt>
                        <dd className="text-sm leading-6 text-gray-500">In [Werkbedrijf]</dd>
                      </div>
                    </dl>
                    <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                      <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                        Document afdrukken <span aria-hidden="true">&rarr;</span>
                      </a>
                    </div>
                  </div>
                </div>



                {/* Document */}
                <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
                  <div className='p-6'>
                    <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                      <div className="sm:pr-4">
                        <dt className="inline text-gray-500">Naam: </dt>{' '}
                        <dd className="inline text-gray-700">
                          <span className='font-medium text-xl'>{intake?.voornaam} {intake?.achternaam}</span>
                        </dd>
                      </div>
                      <div className="sm:pr-4">
                        <dt className="inline text-gray-500">Gemaakt op</dt>{' '}
                        <dd className="inline text-gray-700 ">
                          <span className='font-medium'>{intake?.datumUitgevoerd?.toLocaleDateString()}</span>

                        </dd>
                      </div>
                      <div className="sm:pr-4">
                        <dt className="inline text-gray-500">Adres</dt>{' '}
                        <dd className="inline text-gray-700 text-base">
                          <time dateTime="2023-23-01 ">{intake?.straat} {intake?.huisnummer} {intake?.postcode}</time>
                        </dd>
                      </div>
                      <div className="sm:pr-4">
                        <dt className="inline text-gray-500">Startdatum</dt>{' '}
                        <dd className="inline text-gray-700">
                          <time dateTime="2023-31-01">{intake?.startDatum?.toLocaleDateString()}</time>
                        </dd>
                      </div>


                      <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                        <dt className="font-semibold text-gray-900">[licientienemer]</dt>
                        <dd className="mt-2 text-gray-500">
                          <span className="font-medium text-gray-900">Auteur {intake?.consulent?.naam}</span>
                          <br />
                          Werkbedrijf [werkbedrijf]
                          <br />
                          Leidinggevende [leidinggevende]
                        </dd>
                      </div>
                      <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                        <dt className="font-semibold text-gray-900">{intake?.gemeente}</dt>
                        <dd className="mt-2 text-gray-500">
                          <span className="font-medium text-gray-900">Consulent {intake?.consulent?.naam}</span>
                          <br />
                          Traject {intake?.traject?.naam}
                          <br />
                        </dd>
                      </div>
                    </dl>

                    <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                      <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                        <dt className="font-semibold text-gray-900">Eerste Indruk</dt>
                        <dd className="mt-2 text-gray-500">
                          {intake?.eersteIndrukTalent}
                        </dd>
                      </div>
                      <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                        <dt className="font-semibold text-gray-900">Afpraken nakomen</dt>
                        <dd className="mt-2 text-gray-500">
                          {intake?.eersteIndrukTalent}
                        </dd>
                      </div>
                    </dl>

                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Allergien</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.allergien}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Communiceren</dt>
                      <dd className="mt-2 text-gray-500">
                        {/* Communiceren staat er niet meer tussen */}
                        { }
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Competenties</dt>
                      <dd className="mt-2 text-gray-500">
                        {/* Competenties staat er niet meer tussen */}
                        { }
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Diploma's</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.diplomas}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Fysieke belasting</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.fysiekeBelasting}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Gezongheid</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.gezondheid}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Indicatie</dt>
                      <dd className="mt-2 text-gray-500">
                        {/* Indicatie staat er niet meer tussen */}
                        { }
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Ingeburgerd</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.ingeburgerd}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Instap</dt>
                      <dd className="mt-2 text-gray-500">
                        {/* Instap staat er niet meer tussen */}
                        { }
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Kinderopvang</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.kinderopvang}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Leervermogen</dt>
                      <dd className="mt-2 text-gray-500">
                        {/* Leervermogen staat er niet meer tussen */}
                        { }
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Medcijn</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.medicijnen}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Opleiding</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.opleiding}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Opstap</dt>
                      <dd className="mt-2 text-gray-500">
                        {/* Opstap staat er niet meer tussen */}
                        { }
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Rijbewijs</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.rijbewijs}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Ritme</dt>
                      <dd className="mt-2 text-gray-500">
                        {/* Ritme staat er niet meer tussen */}
                        { }
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Schooldagen</dt>
                      <dd className="mt-2 text-gray-500">
                        {/* Schooldagen staat er niet meer tussen */}
                        { }
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Situatie schets</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.situatieSchets}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Taalniveau</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.taalniveau}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Uitkering</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.soortUitkering}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Uitstap</dt>
                      <dd className="mt-2 text-gray-500">
                        {/* Uitstap staat er niet meer tussen */}
                        { }
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Vervoer</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.vervoer}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Voorstel</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.voorstel}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Wensen</dt>
                      <dd className="mt-2 text-gray-500">
                        {/* Wensen staat er niet meer tussen */}
                        { }
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Werkervaring</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.werkervaring}
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Werktempo</dt>
                      <dd className="mt-2 text-gray-500">
                        {/* Werktempo staat er niet meer tussen */}
                        { }
                      </dd>
                    </div>
                    <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                      <dt className="font-semibold text-gray-900">Zelfstandig</dt>
                      <dd className="mt-2 text-gray-500">
                        {intake?.eersteIndrukTalent}
                      </dd>
                    </div>
                  </div>
                </div>





                {/* <Trajectfeed/> */}
              </div>
            </div>
          </main>
        </div>
      </Layout>
    </>

  )
}

