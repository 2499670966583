import Layout from '../../layout';
import { useEffect, useState } from 'react';
import { getTalents, TalentModel } from '../../domain/talent-utils';
import { useAuth } from '../../context/AuthContext';
import { PlusIcon, ChartBarIcon, ClipboardIcon, UserGroupIcon } from '@heroicons/react/20/solid';
import Signalen from '../../components/lists/signalen/Signalen';
import { getRapportageSignalen, SignaalModel } from '../../domain/signalen-utils';
import { EvaluatieModel, getAllEvaluaties, getAllEvaluatiesByUserId } from '../../domain/evaluatie-utils';
import Updates from '../../components/updates/Updates';

export default function Dashboard() {
  const [talenten, setTalenten] = useState<TalentModel[]>([]);
  const [newTalentsToday, setNewTalentsToday] = useState<number>(0);
  const { user } = useAuth();
  const [signalen, setSignalen] = useState<SignaalModel[]>([]);
  const [evaluaties, setEvaluaties] = useState<EvaluatieModel[]>([]);

  const today = new Date().toISOString().split('T')[0];

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!user) return;

    Promise.all([
      getTalents(200, user!),
      getAllEvaluatiesByUserId(user!),
      getRapportageSignalen(user!, 20),
    ]).then(([talenten, evaluaties, signalen]) => {
      setTalenten(talenten);
      setEvaluaties(evaluaties);

      const today = new Date().toISOString().split('T')[0];
      const newTalents = talenten.filter(
        (talent) =>
          talent.lopend_traject?.begindatum &&
          new Date(talent.lopend_traject.begindatum).toISOString().startsWith(today)
      );
      setNewTalentsToday(newTalents.length);

      setSignalen(signalen);
      setIsLoading(false)
    });
  }, [user]);

  return (
    <>
      <Layout>
        <main className="flex-grow">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
            {/* Header */}
            <header>
              <h1 className="text-2xl font-bold text-gray-900">Overzicht van Vandaag</h1>
              <p className="text-sm text-gray-500">Bekijk de hoogtepunten en acties van vandaag.</p>
            </header>

            {/* Summary Metrics */}
            <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 pt-6">
              <div className="bg-indigo-50 rounded-lg p-4 flex items-center gap-4">
                <ChartBarIcon className="h-10 w-10 text-indigo-600" aria-hidden="true" />
                <div>
                  <p className="text-lg font-semibold text-gray-800">{newTalentsToday}</p>
                  <p className="text-sm text-gray-500">Nieuwe Talenten Vandaag</p>
                </div>
              </div>
              <div className="bg-green-50 rounded-lg p-4 flex items-center gap-4">
                <ClipboardIcon className="h-10 w-10 text-green-600" aria-hidden="true" />
                <div>
                  <p className="text-lg font-semibold text-gray-800">{talenten.length}</p>
                  <p className="text-sm text-gray-500">Totaal Talenten</p>
                </div>
              </div>
              <div className="bg-yellow-50 rounded-lg p-4 flex items-center gap-4">
                <UserGroupIcon className="h-10 w-10 text-yellow-600" aria-hidden="true" />
                <div>
                  <p className="text-lg font-semibold text-gray-800">12</p>
                  <p className="text-sm text-gray-500">Actieve Groepen</p>
                </div>
              </div>
              <div className="bg-red-50 rounded-lg p-4 flex items-center gap-4">
                <PlusIcon className="h-10 w-10 text-red-600" aria-hidden="true" />
                <div>
                  <p className="text-lg font-semibold text-gray-800">5</p>
                  <p className="text-sm text-gray-500">Nieuwe Aanmeldingen</p>
                </div>
              </div>
            </section>
          </div>
        </main>

        <div className="flex flex-col xl:flex-row">

          <div className="w-full">
            <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
              <Updates title={'Mijn updates'} subtitle={'Alles wat ik geschreven heb over de Talenten'} user={user!} evaluaties={evaluaties} talenten={talenten} setEvaluatie={setEvaluaties} isLoading={isLoading} />
            </div>
          </div>

          <aside className="min-w-96 xl:w-96 xl:ml-auto xl:mr-0 px-4 sm:px-6 lg:px-8  bg-white ">
            <Signalen signalen={signalen} isLoading={isLoading} />
          </aside>

        </div>
      </Layout>
    </>
  );
}