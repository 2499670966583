import React from "react";
import { Taak } from "../taakmanager/App";

interface TaakDetailsFormProps {
    taken: Taak[];
}

export const TaakDetailsForm: React.FC<TaakDetailsFormProps> = ({ taken }) => {
    if (taken.length === 0) {
        return (
            <div className="bg-blue-500 w-full sm:w-1/2 p-2 border border-gray-300 rounded-md mb-2">
                "Er zijn nog geen taken. Maak eerst taken aan."
            </div>
        );
    }

    return (
        <div>
            {taken.map((taak, index) => (
                <div key={index} className="mb-4 p-4 border border-gray-300 rounded-md shadow">
                    <h3 className="font-bold mb-2">{taak.naam}</h3>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Is de taak uitgevoerd door een reguliere werknemer?
                    </label>
                    <select className="w-full border border-gray-300 p-2 rounded mb-2">
                        <option value="ja">Ja</option>
                        <option value="nee">Nee</option>
                    </select>

                    <label className="block text-sm font-medium text-gray-700 mb-1">Opmerkingen</label>
                    <textarea
                        placeholder={`Voer opmerkingen in over ${taak.naam}`}
                        className="w-full border border-gray-300 p-2 rounded"
                    />
                </div>
            ))}
        </div>
    );
};
